import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { referencedInProHomeCannotUnpublish } from '@newStore/validation/validationRules/referencedInProHomeCannotUnpublish';
import { validateWebTemplateConfig } from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { ValidationRule, AsyncValidationRule } from '@newStore/validation/validationTypes';

export const proThemeDefaultValidationRules: Array<ValidationRule | AsyncValidationRule> = [
  validateWebTemplateConfig,
  referencedInProHomeCannotUnpublish,
  selectCurriculumThemesValidation,
];
export const proTileDefaultValidionRules = [validateWebTemplateConfig];
