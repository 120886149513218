import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';

const VISION_TEXT: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  information: {
    definiteArticle: false,
    single: 'Visietekst',
    plural: 'Visieteksten',
  },

  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    {
      ...imageComponentBase,
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: false,
};

export default VISION_TEXT;
