import React, { useState } from 'react';

import { AttachmentMetaInfo } from '@generalTypes/apiTypes';
import { EditAsideImage, ImageType } from '@nodeTypeConfig/configTypes';
import {
  addAttachment,
  addAttachments,
  patchNodeAttachmentAction,
  removeAttachments,
} from '@store/actions/documentActions';
import { getBase64, getResourceKey } from '@store/helpers/documentHelpers';
import ImageComponent from '@UI/image/ImageComponent';
import { scaleImage } from '@UI/image/imageUtils';
import { any } from 'prop-types';
import { useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { addNotificationAction } from '@store/actions/notificationActions';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { selectAttachmentsOfType } from './asideImageSelectors';

const AsideImage: React.FC<{
  config: EditAsideImage;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const { crop } = config.options;
  const attachments = useTypedSelector((state) => selectAttachmentsOfType(state, config));
  const img = attachments.find((z) => !('resized' in z)) || null;
  const [alt, setAlt] = useState(img?.alt || '');
  const [description, setDescription] = useState(img?.description || '');

  const readOnly = useReadOnly();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const patch = {
      type: config.options.type,
    };
    patch[name.toLowerCase()] = value;
    if (name === 'description') {
      setDescription(value);
    }
    if (name === 'alt') {
      setAlt(value);
    }
    dispatch(patchNodeAttachmentAction(getResourceKey(editHref), img?.key, patch));
  };

  const handleImageRemoved = () => {
    dispatch(
      removeAttachments(
        getResourceKey(editHref),
        attachments.map((a: AttachmentMetaInfo) => a.key)
      )
    );
    return true;
  };

  const handleImageCropped = async (data: {
    file: File;
    base64: string;
    type: ImageType;
    width: number;
    height: number;
  }): Promise<void> => {
    let resized: {
      file: File;
      base64: string;
      type: ImageType;
      width: number;
      height: number;
    } | null = null;
    if (crop?.resize) {
      const { width, height, quality } = crop.resize;
      const scaledImg = await scaleImage(data.file, width, height, quality);

      resized = { ...scaledImg, type: config.options.type, width, height };
    }
    dispatch(
      addAttachments(getResourceKey(editHref), { ...data, type: config.options.type }, resized)
    );
  };

  const handleImageChanged = async ({
    file,
    invalid,
  }: {
    file: File;
    invalid?: boolean;
  }): Promise<void> => {
    if (invalid) {
      dispatch(
        addNotificationAction({
          type: 'ERROR',
          message:
            'Het bestand dat je probeert te uploaden is geen afbeelding. Gelieve enkel png en jpg afbeeldingen te uploaden.',
        })
      );
      return;
    }

    const base64 = await getBase64(file);
    const newAttachment = {
      key: img?.key || uuidv4(),
      newKey: uuidv4(),
      type: config.options.type,
      name: file.name,
      size: file.size,
      $$base64: base64,
      isNew: true,
      ...(alt && { alt }),
      ...(description && { description }),
    };

    dispatch(addAttachment(getResourceKey(editHref), newAttachment, file));
  };

  return (
    <>
      <div className={`panel panel-default ${readOnly ? 'aside-component-disabled' : ''}`}>
        <AsideChangeIndicator config={config} />
        {config.label && <label className="panel-heading control-label">{config.label}</label>}
        <div className="panel-body">
          <AsideValidation property={config.property} component={config.component}>
            <ImageComponent
              image={img}
              label={config.label}
              crop={crop}
              disabled={readOnly}
              onImageChanged={handleImageChanged}
              onImageRemoved={handleImageRemoved}
              onImageCropped={handleImageCropped}
            ></ImageComponent>
            {attachments?.length > 0 && config.options.fields?.includes('description') && (
              <div className="col-md-6" style={{ marginBottom: '1rem' }}>
                <label className="control-label">Bijschrift</label>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  defaultValue={description}
                  onBlur={handleChange}
                  disabled={readOnly}
                />
              </div>
            )}
            {attachments?.length > 0 && config.options.fields?.includes('alt') && (
              <div className="col-md-6" style={{ marginBottom: '1rem' }}>
                <label className="control-label">Alt tag</label>
                <input
                  type="text"
                  name="alt"
                  className="form-control"
                  defaultValue={alt}
                  onBlur={handleChange}
                  disabled={readOnly}
                />
              </div>
            )}
          </AsideValidation>
        </div>
      </div>
    </>
  );
};

export default AsideImage;

AsideImage.displayName = 'AsideImage';

AsideImage.propTypes = {
  config: any,
};
