import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';

import { selectOdetKeyCompetenceNode } from '@newStore/documentUI/transformContent/odetContentNodeSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const CURRICULUM_ODET_KEY_COMPETENCE: BuildingBlockNodeConfig = {
  nodeSelector: selectOdetKeyCompetenceNode,
  information: {
    single: 'Sleutelcompetentie',
    plural: 'Sleutelcompetenties',
  },

  buildingBlocks: [
    { type: NodeType.CURRICULUM_ODET_SUBSECTION },
    { type: NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL },
    { type: NodeType.CURRICULUM_ODET_ENDTERM },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: true,
  isCollapsedByDefault: true,
  documentViewIdentifier: 'i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_KEY_COMPETENCE;
