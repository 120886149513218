import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import constants from '@store/constants/constants';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const WEBPAGE: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  information: {
    definiteArticle: false,
    single: 'Zill-Web Pagina',
    plural: "Zill-Web Pagina's",
    icon: require('../../../../img/icons/webpage.svg'),
  },
  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.WORD_IMPORT },
  ],
  tocTypes: [NodeType.SECTION],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
      },
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
};

export default WEBPAGE;
