import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { selectValidateChildrenAreRequired } from '@newStore/validation/validationRules/childrenAreRequired';
import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';

const IMAGE_GROUP: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  validationRules: [selectValidateChildrenAreRequired],
  information: {
    single: 'Afbeeldingsgroep',
    plural: 'Afbeeldingsgroepen',
    ribonIcon: require('../../../../img/buildingBlocks/IMAGE_GROUP.svg'),
  },
  buildingBlocks: [{ type: NodeType.IMAGE }],
  edit: [],
  showPlaceholder: true,
  isCollapsible: true,
  readOnlyNode: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default IMAGE_GROUP;
