import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { FilterableEditComponent, RequiredType, NodeType } from '@nodeTypeConfig/configTypes';
import { authorsComponent } from './authorsComponent';
import { identifierComponent } from './identifierComponent';
import { importanceComponent } from './importanceComponent';
import { proContactsComponent } from './proContactsComponent';
import { proThumbnailComponent } from './proThumbnailComponent';
import { titleComponent } from './titleComponent';

export const proTileStandardComponents: Array<FilterableEditComponent> = [
  {
    ...identifierComponent, // can be removed in the future, not used (confirmed by Isabelle)
    width: '2',
  },
  {
    ...titleComponent,
    width: '10',
  },
  {
    component: 'description',
    reactComponent: 'AsideDescription',
    property: 'shortdescription',
    width: '12',
    label: 'Tegeltekst',
    maxLength: 80,
    required: RequiredType.WARNING,
    showInAncestorTypes: [NodeType.PRO_THEME_HOME_FULL, NodeType.PRO_THEME_HOME_TEXT],
    asideChangeMessageSelector: selectChangeMessageForHtmlString,
  },
  {
    component: 'description',
    reactComponent: 'AsideDescription',
    property: 'description',
    width: '12',
    label: 'Inleiding',
    fullLabel: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    required: RequiredType.WARNING,
    asideChangeMessageSelector: selectChangeMessageForHtmlString,
  },
  {
    ...proThumbnailComponent,
    label: 'Tegelfoto',
    width: '6',
    showInAncestorTypes: [NodeType.PRO_THEME_HOME_FULL, NodeType.PRO_THEME_HOME_PICTURE],
    hideInAncestorTypes: [NodeType.PRO_THEME_HOME_TEXT],
  },
  {
    ...importanceComponent, // can be removed: this was only for the menu not for pro themes (and even there it is no longer used in pro)
    width: '6',
  },
  {
    ...authorsComponent,
    width: '6',
  },
  {
    ...proContactsComponent,
    width: '12',
  },
];
