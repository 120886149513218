import { RootState } from '@generalTypes/rootStateTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { EditAsideThemes } from '@nodeTypeConfig/configTypes';
import { patchThemes } from '@store/actions/documentActions';
import { addNotificationAction } from '@store/actions/notificationActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { selectOptionsForAsideReferenceFrames } from '@store/selectors/asideSelectors';
import { escapeRegExp } from 'lodash';
import { any } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { selectReferenceFramesSelectedOptions } from './AsideThemesSelectors';
import { selectHighlightedThemesOptionsSet } from './selectHighlightedThemesOptionsSet';

export const AsideThemes: React.FC<{
  config: EditAsideThemes;
}> = ({ config }) => {
  const readOnly = useReadOnly();

  const href = useEditHref();
  const key = getResourceKey(href);
  const dispatch = useDispatch();

  const typesToFilter = config.options.types;
  const { referenceFrameHref } = config.options;

  useEffect(() => {
    if (referenceFrameHref) {
      dispatch(loadReferenceFrame({ referenceFrame: referenceFrameHref }));
    }
  }, [dispatch, referenceFrameHref]);

  const options = useTypedSelector((state: RootState) =>
    selectOptionsForAsideReferenceFrames(state, referenceFrameHref, typesToFilter)
  );

  const higlightedOptionsSet = useTypedSelector((state: RootState) =>
    selectHighlightedThemesOptionsSet(state, referenceFrameHref)
  );

  const allSelectedThemeHrefs = useInitialValue(config.property) || [];
  const selectedOptions = useTypedSelector((state: RootState) =>
    selectReferenceFramesSelectedOptions(state, href, config)
  );

  const selectedThemeHrefsInOtherComponents =
    allSelectedThemeHrefs.filter(
      (themeHref) => !selectedOptions.some((o) => o.$$meta.permalink === themeHref)
    ) || [];

  const change = (values) => {
    dispatch(
      patchThemes(
        key,
        values.map((v) => v.$$meta.permalink).concat(selectedThemeHrefsInOtherComponents)
      )
    );
  };

  const filterFn = ({ data }, str) => {
    if (!str || str.trim().length === 0) {
      return true;
    }
    const regExp = new RegExp(escapeRegExp(str), 'gi');
    return regExp.test(data.title);
  };

  const pastedText = (event) => {
    const regExp = /(\/\w+)+\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
    const paste = event.clipboardData.getData('text');
    const permalinks = paste.match(regExp);
    if (!permalinks) {
      return;
    }
    event.preventDefault();

    const foundOptions = options.filter((option) => permalinks.includes(option.$$meta.permalink));
    if (foundOptions.length > 0) {
      change([...selectedOptions, ...foundOptions]);
    }

    const notFoundPermalinks = permalinks.filter(
      (permalink) => !foundOptions.some((o) => o.$$meta.permalink === permalink)
    );
    if (notFoundPermalinks.length > 0) {
      dispatch(
        addNotificationAction({
          type: 'WARNING',
          removeAfter: 0,
          message: `De volgende labels werden niet gevonden:<br/>${notFoundPermalinks.join(
            '<br/>'
          )}`,
        })
      );
    }
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <label className="control-label control-label-inline">{config.label}</label>
      <div onPaste={pastedText}>
        <KovResourcePicker
          localData={options}
          value={selectedOptions}
          onChange={change}
          disabled={readOnly}
          clearable={true}
          multiple={true}
          style={{ option: (provided) => ({ ...provided, cursor: 'pointer' }) }}
          optionTemplate={(option) => (
            <span
              style={{
                ...(higlightedOptionsSet.has(option.$$meta.permalink) && { color: '#990099' }),
              }}
            >
              {option.title}
            </span>
          )}
          filter={filterFn}
        />
      </div>
    </AsideValidation>
  );
};

AsideThemes.displayName = 'AsideThemes';

export default AsideThemes;

AsideThemes.propTypes = {
  config: any,
};
