import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proContentTypeForDatabasePicker } from '@nodeTypeConfig/helpers/editComponents/proContentTypeForDatabasePicker';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proAliasesComponent } from '@nodeTypeConfig/helpers/editComponents/proAliasesComponent';
import { proDocumentStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proDocumentStandardComponents';
import { proTileStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proTileStandardComponents';
import { proThemeTocTypes } from '@nodeTypeConfig/helpers/buildingBlocks/proThemeTocTypes';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';
import { proTileDefaultValidionRules } from '../proGenericConfigProps';
import { proThemeDefaultValidationRules } from '../proGenericConfigProps';

const proDatabase = {
  nodeSelector: selectTitleWithDescriptionNode,
  information: {
    description:
      'Pro Database (webTemplate code MINI_DATABASE) can be a SECTION (a tile) in a home or can be the root (single page pro theme)',
    single: 'PRO.-tegel: Databank',
    plural: 'Databanken',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig MINI_DATABASE becomes a PRO_DATABASE_TILE
export const PRO_DATABASE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proDatabase,
  validationRules: proTileDefaultValidionRules,
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents,
    proAliasesComponent,
    proContentTypeForDatabasePicker,
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding MINI_DATABASE WebConfig to a WEBPAGE2 it becomes a PRO_DATABASE_STANDALONE
export const PRO_DATABASE_STANDALONE: ExtendedRootNodeConfig = {
  ...proDatabase,
  validationRules: proThemeDefaultValidationRules,
  information: {
    ...proDatabase.information,
    definiteArticle: true,
    single: 'PRO.-thema: Database',
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [
    ...proDocumentStandardComponents,
    proAliasesComponent,
    proContentTypeForDatabasePicker,
    ...proLinksAndMenuReferences,
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
