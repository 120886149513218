import { Content, ContentHref, WebPage, WebTemplate } from '@generalTypes/apiTypes';
import { mapDeletedNodesToUndefined } from '@newStore/documentApi/documentApiHelpers';
import {
  selectPathToRoot,
  selectPathToRootWebConfigurations,
  selectWebPage,
} from '@newStore/documentApi/documentApiSelectors';
import { selectTypeNameSingle } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { pathMap } from '@newStore/externalData/externalDataTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import { getNodeTypeLabel, VALIDATION_PENDING } from '../validationHelpers';
import { createError } from '../createError';
import { ValidationResult, ValidationRule } from '../validationTypes';

const selectWebConfigurationRequiredRule: ValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectWebPage(state, href),
    (state, href: ContentHref) => selectTypeNameSingle(state, href),
    (state, href: ContentHref) => state.documentApi.includedWebPagesFetched[href],
  ],
  (webPage, nodeTypeName, includedFetchedStatus): ValidationResult => {
    if (webPage && !webPage.$$meta.deleted) {
      return true;
    }

    // in case of teaser, we need to fetch the teaser configs separately.
    // wait for them to be loaded.
    if (includedFetchedStatus === false) {
      return VALIDATION_PENDING;
    }

    return createError(
      'webConfigRequired',
      'selectWebConfigurationRequiredRule',
      `Een ${getNodeTypeLabel(nodeTypeName)} moet <strong>webconfiguratie</strong> hebben.`
    );
  }
);
export const validateWebConfigurationRequired = selectWebConfigurationRequiredRule;

/*
 * read webTemplate.allowedContent to validate if Content node matches the definition WebTemplate allows
 * */
const isContentValidForWebTemplate = (
  nodeWebPage: WebPage | undefined,
  node: Content,
  nodeTypeName: string,
  webTemplateMap: Record<string, WebTemplate>
): ValidationResult => {
  if (!nodeWebPage) {
    return true;
  }
  const nodeWebTemplate = webTemplateMap[nodeWebPage.template.href];
  if (
    nodeWebTemplate.allowedContent &&
    !nodeWebTemplate.allowedContent.some(
      (ac) => ac.type === node.type && (!ac.tag || (node.tags && node.tags.includes(ac.tag)))
    )
  ) {
    return createError(
      'webConfigInvalidForNodeType',
      'isContentValidForWebTemplate',
      `${nodeWebTemplate.name} is geen geldige webconfiguratie voor een ${nodeTypeName}.`
    );
  }
  return true;
};

/**
 * read webTemplate.allowedTemplatesForChildNode of the parent to see if webPage of the node is valid
 * -> if template says there are allowedTemplatesForChildNode it should have a webConfiguration (only valid in proTheme)
 * -> and the webConfiguration needs to be one of the specified allowedTemplatesForChildNode
 */
const isWebConfigValidForParentWebConfig = (
  nodeWebPage: WebPage | undefined,
  parentWebPage: WebPage | undefined,
  webTemplateMap: Record<string, WebTemplate>
): ValidationResult => {
  if (!parentWebPage) {
    return true;
  }
  const parentWebTemplate = webTemplateMap[parentWebPage.template.href];
  if (!nodeWebPage && parentWebTemplate.allowedTemplatesForChildNode?.length) {
    return createError(
      'webConfigRequiredForParentWebConfig',
      'isWebConfigValidForParentWebConfig',
      `Een titel onder ${getNodeTypeLabel(
        parentWebTemplate.name
      )} moet <strong>webconfiguratie</strong> hebben`,
      { component: 'webPage' }
    );
  }
  if (
    !nodeWebPage ||
    parentWebTemplate.allowedTemplatesForChildNode?.includes(nodeWebPage.template.href)
  ) {
    return true;
  }
  // the webPage is invalid within the parent webConfig.
  // the following code is to have 3 different messages depending on if there is 0, 1, or n options allowed by the parent
  const nodeWebTemplate = nodeWebPage && webTemplateMap[nodeWebPage.template.href];
  let message = '';
  if (!parentWebTemplate.allowedTemplatesForChildNode?.length) {
    message = `Titels onder een ${getNodeTypeLabel(
      parentWebTemplate.name
    )} mogen <strong>geen webconfiguratie</strong> hebben`;
  } else if (parentWebTemplate.allowedTemplatesForChildNode.length === 1) {
    message = `"${
      nodeWebTemplate.name
    }" is geen geldig <strong>type webconfiguratie</strong>. Een titel onder een ${getNodeTypeLabel(
      parentWebTemplate.name.split('(')[0].trim()
    )} moet webconfiguratie van type <strong>${
      webTemplateMap[parentWebTemplate.allowedTemplatesForChildNode[0]].name
    }</strong> hebben.`;
  } else {
    const possibleTypes = parentWebTemplate.allowedTemplatesForChildNode
      .map((tHref) => webTemplateMap[tHref].name)
      .join(', ')
      .replace(/,(?=[^,]+$)/, ' of ');
    message = `"${
      nodeWebTemplate.name
    }" is geen geldig <strong>type webconfiguratie</strong>. Mogelijke types webconfiguratie voor een ${getNodeTypeLabel(
      parentWebTemplate.name.split('(')[0].trim()
    )} zijn: <em>${possibleTypes}</em>`;
  }
  return createError(
    'webConfigMismatchForParentWebConfig',
    'isWebConfigValidForParentWebConfig',
    message,
    { component: 'webPage.template' }
  );
};

/**
 * Validate if the this webConfiguration is valid using the rules defined by the data of /web/templates
 * This validation rule only applies for proThemes
 * This rule can not be used for the proHomePage: there are intermediary sections in between the root/sections that have webconfiguration so you can not use the parent rule which is too strict
 */
const selectInvalidWebTemplateConfigRule: ValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectPathToRoot(state, href),
    (state, href: ContentHref) => selectPathToRootWebConfigurations(state, href),
    (state, href: ContentHref) => selectTypeNameSingle(state, href),
    (state) => state.externalData.data[pathMap.webTemplates].items,
  ],
  (pathToRoot, webPagePathToRoot, nodeTypeName, webTemplateMap): ValidationResult => {
    if (!Object.keys(webTemplateMap).length) {
      // when initialising web templates might not be loaded yet => validity is unkonwn
      return VALIDATION_PENDING;
    }

    const notDeletedWebPagePathToRoot = mapDeletedNodesToUndefined(webPagePathToRoot);

    const node = pathToRoot[0];
    const nodeWebPage = notDeletedWebPagePathToRoot[0];
    const isContentValid = isContentValidForWebTemplate(
      nodeWebPage,
      node,
      nodeTypeName,
      webTemplateMap
    );
    if (isContentValid !== true) {
      return isContentValid;
    }

    const parent = pathToRoot[1];
    if (!parent) {
      return true;
    }

    return isWebConfigValidForParentWebConfig(
      nodeWebPage,
      notDeletedWebPagePathToRoot[1],
      webTemplateMap
    );
  }
);
export const validateWebTemplateConfig = selectInvalidWebTemplateConfigRule;
