import { FilterableEditComponent } from '@nodeTypeConfig/configTypes';
import { proWebFacetAncestorTypes } from './proWebFacetAncestorTypes';

export const proWebFacetComponent: FilterableEditComponent = {
  component: 'webFacetsPlaceHolder',
  reactComponent: 'AsideWebFacetPlaceHolder',
  label: 'Facetten',
  width: '12',
  showInAncestorTypes: proWebFacetAncestorTypes,
};
