import { ContentHref, WebPage, WebTemplateKey } from '@generalTypes/apiTypes';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SharedItemFacetsState = Record<ContentHref, Array<WebPage>>;
export type LoadSharedItemFacetsPayload = PayloadAction<{
  matchingParams: {
    themesMatchingOnRoot: { referenceFrameKey: string };
    themesOverlaps?: { referenceFrameKey: string };
    'webPage.template': WebTemplateKey; // MINI_DATABASE OR DOWNLOAD_PAGE
    $$includeWebPages: true;
  };
}>;

const initialState: SharedItemFacetsState = {};

const sharedItemFacetsState = createSlice({
  name: 'sharedItemFacets',
  initialState,
  reducers: {
    loadSharedItemFacets: (_state, _action: LoadSharedItemFacetsPayload) => {},
    setSharedItemFacets: (
      state,
      action: PayloadAction<{ href: ContentHref; webPages: Array<WebPage> }>
    ) => {
      const { href, webPages } = action.payload;
      state[href] = webPages;
    },
  },
});

export const { loadSharedItemFacets, setSharedItemFacets } = sharedItemFacetsState.actions;

export default sharedItemFacetsState;
