import { EditAsideImage } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForImage } from '@UI/aside/asideImage/asideImageSelectors';

export const imageComponentBase = {
  reactComponent: 'AsideImage',
  component: 'image',
  property: 'attachments',
  label: 'Afbeelding',
  asideChangeMessageSelector: selectChangeMessageForImage,
} as const;

export const imageComponent: EditAsideImage = {
  ...imageComponentBase,
  options: {
    type: 'ILLUSTRATION',
    fields: [],
  },
};
