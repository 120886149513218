import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';

import { selectOdetDevelopmentGoalorEindtermNode } from '@newStore/documentUI/transformContent/odetContentNodeSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const CURRICULUM_ODET_ENDTERM: BuildingBlockNodeConfig = {
  nodeSelector: selectOdetDevelopmentGoalorEindtermNode,
  information: {
    single: 'Eindterm',
    plural: 'Eindtermen',
  },

  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving / Afbakening',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: false,
  documentViewIdentifier: 'o(p*)(.)i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_ENDTERM;
