export const getEmbedVideoLinkFrom = (url) => {
  if (url !== undefined && url !== '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return `https://youtube.com/embed/${match[2]}`;
    }
    if (url.indexOf('vimeo') !== -1) {
      const id = url.split('/')[url.split('/').length - 1];
      return `https://player.vimeo.com/video/${id}`;
    }
    if (url.indexOf('microsoft') !== -1) {
      const id = url.split('/')[url.split('/').length - 1];
      return `https://web.microsoftstream.com/embed/video/${id}?autoplay=false&amp;showinfo=true`;
    }
  }
  return undefined;
};

/**
 * @from https://stackoverflow.com/questions/28735459/how-to-validate-youtube-url-in-client-side-in-text-box
 */
export const isValidYouTubeUrl = (url) => {
  if (url !== undefined && url !== '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return true;
    }
  }
  return false;
};

export const isValidVimeoUrl = (url) => {
  if (url !== undefined && url !== '') {
    return /(https:\/\/)?vimeo.com\/\d{4,20}(?=\b|\/)/.test(url);
  }
  return false;
};

export const isValidMicrosoftstreamUrl = (url) => {
  if (url !== undefined && url !== '') {
    return /(https:\/\/)?web.microsoftstream.com\/video\/[-a-zA-Z0-9]{36}(?=\b|\/)/.test(url);
  }
  return false;
};

export const isValidVideoUrl = (url: string | undefined) => {
  return isValidYouTubeUrl(url) || isValidVimeoUrl(url) || isValidMicrosoftstreamUrl(url);
};
