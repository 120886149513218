import { DropFile } from '@nodeTypeConfig/configTypes';

export const areDraggingFilesImages = (dropItem: DropFile) => {
  // the dropfile has items and files.
  // when you start dragging, the items are filled in, but the files are not.
  // after some time, the files are filled in and the items are empty.
  // handle both cases + transition.

  const { items, files } = dropItem;

  if (items.length) {
    return Array.from(items).every(
      (item) => item.kind === 'file' && ['image/jpeg', 'image/jpg', 'image/png'].includes(item.type)
    );
  }

  if (files.length) {
    return Array.from(files).every((file) =>
      ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
    );
  }

  return null;
};
