import { Content, ContentType } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRootItem,
} from '@newStore/documentApi/documentApiSelectors';
import { expandLlinkidGoalRelations } from '@newStore/llinkid/llinkidActions';
import { selectPreviousVersionHrefs } from '@newStore/llinkid/llinkidSelectors';
import { EditAsidePreviousVersionItems } from '@nodeTypeConfig/configTypes';
import { openGoalRelationsModal } from '@store/helpers/documentAsideHelpers';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideLlinkidGoal from '../asideLlinkidGoal/asideLlinkidGoal';
import AsideLlinkidItem from '../asideLlinkidItem/asideLlinkidItem';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { useDeleteRelationHook } from '../asideRelationsPanel/useDeleteRelationHook';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsidePreviousVersionItems: React.FC<{
  config: EditAsidePreviousVersionItems;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const currentEditingHref = useEditHref();

  const relations = useSelector(
    (state: RootState) =>
      selectApiWithPendingChangesRelationsToAndFromMap(state).from[currentEditingHref]
  );

  const rootItem = useSelector(selectRootItem) as Content;

  const [previousCurriculumHref] = useSelector((state: RootState) =>
    selectPreviousVersionHrefs(state, rootItem.$$meta.permalink)
  );

  const readOnly = useReadOnly(!previousCurriculumHref || rootItem.version?.major === 1);

  const replacesRelations = relations.filter((relation) =>
    config.options.relationTypes.some((z) => z === relation.relationtype)
  );

  useEffect(() => {
    dispatch(
      expandLlinkidGoalRelations({
        key: getResourceKey(currentEditingHref),
        relationTypes: config.options.relationTypes,
        originPart: 'from',
      })
    );
  }, [dispatch, currentEditingHref, config.options.relationTypes]);

  const onDelete = useDeleteRelationHook();

  const onCreate = async () => {
    // @ts-expect-error item is not mandatory
    const response = await openGoalRelationsModal({
      asideOptions: config.options,
      rootKey: rootItem.key,
      defaultCurriculumKey: getResourceKey(previousCurriculumHref) || null,
      relations: replacesRelations,
    });

    if (response) {
      let newRelation;
      if (config.options.modal === 'llinkidPreviousVersionModal') {
        newRelation = {
          relationtype: config.options.relationTypes[0],
          from: {
            href: currentEditingHref,
          },
          to: {
            href: response.previousVersion.$$meta.permalink,
          },
        };
      } else if (config.options.modal === 'llinkidGoalRelationsModal') {
        newRelation = {
          relationtype: config.options.relationTypes[0],
          from: {
            href: currentEditingHref,
          },
          to: {
            href: response.goal.$$meta.permalink,
          },
        };
      }

      if (newRelation) {
        const relationsParams = {
          key: getResourceKey(currentEditingHref),
          relationTypes: config.options.relationTypes,
        };

        // @ts-expect-error addRelationAction is not typed
        dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation, true, true, relationsParams));
      }
    }
  };

  // here we show the replaces relations for things like pedagogical tips, initial situation, ...
  return (
    <AsideValidation property={config.property} component={config.component}>
      {/* TODO */} <AsideChangeIndicator config={config} /> {/* TODO */}
      <AsideRelationsPanel
        title={config.label}
        items={replacesRelations}
        readOnly={readOnly}
        onCreate={onCreate}
        onDelete={onDelete}
      >
        {config.options.type !== ContentType.LLINKID_GOAL
          ? (item) => <AsideLlinkidItem href={item.to.href} />
          : (item) => <AsideLlinkidGoal item={item} options={config.options} href={item.to.href} />}
      </AsideRelationsPanel>
    </AsideValidation>
  );
};

AsidePreviousVersionItems.displayName = 'AsidePreviousVersionItems';

AsidePreviousVersionItems.propTypes = {
  config: any,
};

export default AsidePreviousVersionItems;
