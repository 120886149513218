import { createTypedSelector } from '@newStore/genericHelpers';
import { escape, get, orderBy } from 'lodash';

import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { selectNewsletterSendersArray } from '@newStore/externalData/externalDataSelectors';
import {
  AsideChangeMessageSelector,
  EditAsideNewsletterSender,
  EditComponent,
} from '@nodeTypeConfig/configTypes';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';

export const selectIsNewsletterSenderComponentLoading = (state: RootState) =>
  selectNewsletterSendersArray(state).length === 0;

export const selectNewsletterSenderOptions = createTypedSelector(
  [(state) => selectNewsletterSendersArray(state)],
  (newsletterSenders) => {
    return orderBy(newsletterSenders, 'department').map((sender) => ({
      key: sender.key,
      href: sender.$$meta.permalink,
      display: `${sender.department} - ${sender.name} <${sender.email}>`,
    }));
  }
);

export const selectSelectedNewsletterSender = createTypedSelector(
  [
    (state) => selectCurrentEditingNode(state),
    selectNewsletterSenderOptions,
    (_state, config: EditAsideNewsletterSender) => config,
  ],
  (currentNode, senderOptions, config) =>
    senderOptions.find((o) => o.href === get(currentNode, config.property))
);

export const selectChangeMessageForNewsletterSenders: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state) => selectNewsletterSenderOptions(state),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, options, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newHref = get(content, config.property);
      const oldHref = get(originalContent, config.property);

      const newValue = escape(options.find((o) => o.href === newHref)?.display ?? '');
      const oldValue = escape(options.find((o) => o.href === oldHref)?.display ?? '');

      const diffText = stringDiffArrowFunction(newValue, oldValue);

      return diffText;
    }
  );
