import { selectDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const LLINKID_GUIDING_PRINCIPLE: BuildingBlockNodeConfig = {
  nodeSelector: selectDescriptionNode,
  information: {
    single: 'Krachtlijn',
    plural: 'Krachtlijn',
  },

  buildingBlocks: [],
  edit: [
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
      options: {
        showMarkerButton: true,
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_GUIDING_PRINCIPLE;
