import { Content } from '@generalTypes/apiTypes';
import { EditAsideSimpleDropDown, RequiredType } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForSimpleDropdown } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForSimpleDropdown';

export const importance = {
  VERY_LOW: 'heel laag',
  LOW: 'laag',
  MEDIUM: 'normaal',
  HIGH: 'hoog',
  VERY_HIGH: 'heel hoog',
};

export const importanceComponent: EditAsideSimpleDropDown = {
  required: RequiredType.ERROR,
  component: 'importance',
  property: 'importance',
  label: 'Belangrijkheid',
  reactComponent: 'AsideSimpleDropDown',
  valueToString: (c: Content) => importance[c.importance] || '',
  options: {
    clearable: false,
    options: Object.entries(importance)
      .reverse()
      .map(([key, label]) => ({
        key,
        label,
      })),
  },
  asideChangeMessageSelector: selectChangeMessageForSimpleDropdown,
};
