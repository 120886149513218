import { BuildingBlock, NodeType } from '@nodeTypeConfig/configTypes';

export const proDefaultBuildingBlocks: Array<BuildingBlock> = [
  { type: NodeType.SECTION },
  { type: NodeType.PARAGRAPH },
  { type: NodeType.IMAGE },
  { type: NodeType.ATTACHMENTS_GROUP },
  { type: NodeType.IMAGE_GROUP },
  { type: NodeType.VIDEO },
  { type: NodeType.QUOTE },
  { type: NodeType.EXAMPLE },
  { type: NodeType.SUMMARY },
  { type: NodeType.WORD_IMPORT },
];
