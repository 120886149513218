import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  getContentNodeId,
  keySelectorHrefAndParent,
} from '@newStore/documentApi/documentApiHelpers';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
  selectLoadedContentHrefsSet,
} from '@newStore/documentApi/documentApiSelectors';
import { getAllDescendantParentChildHrefs } from '@newStore/documentUI/documentUIHelpers';
import {
  selectAllHiddenContenHrefs,
  selectIsDocumentReadyToShow,
  selectRootHref,
  selectTableOfContentChildren,
} from '@newStore/documentUI/documentUISelectors';
import {
  selectAppliedNodeConfig,
  selectEditConfigForNode,
  selectNodeType,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { selectProposedContentHrefsToDelete } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { EditComponentProperty, FilterableEditComponent } from '@nodeTypeConfig/configTypes';
import nodeTypeSelectorsMap from '@nodeTypeConfig/nodeTypeSelectorsMap';
import { logPerformance } from '@store/helpers/generalUtils';
import { isEqual, pick } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { shallowEqual } from 'react-redux';
import {
  createChildError,
  createChildWarning,
  getHighestValidationStatus,
  getParentChildIndex,
  getValidationStatus,
  isContentEmpty,
  isContentTooLong,
} from './validationHelpers';
import { createError } from './createError';
import { selectAudienceValidationErrors } from './validationRules/audienceValidationRules';
import { selectIsNodeReferenceValid } from './validationRules/externalLinkValidationRules';
import {
  selectIsBuildingBlockValidWithinParent,
  selectIsReadOrderUniqueWithinParent,
} from './validationRules/parentRelationValidationRules';
import { selectWebPageErrors } from './validationRules/webPageValidationRules';
import {
  ValidationError,
  ValidationErrorWithNode,
  ValidationInfo,
  ValidationMapIndex,
  ValidationResult,
  ValidationStatus,
  isAsyncValidationRule,
  isValidationErrorOrWarning,
} from './validationTypes';

const emptyArray = [];

const selectPropertyValidations = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref | undefined
) => {
  const editConfigList: FilterableEditComponent[] = selectEditConfigForNode(state, href);
  const content = selectContentItem(state, href);

  const errors: ValidationError[] = [];
  editConfigList.forEach((edit) => {
    const prop = edit.property || edit.component;
    if (edit.required && isContentEmpty(content, prop as EditComponentProperty, edit)) {
      const message =
        edit.customRequiredMessage ||
        `Vul ${
          edit.definiteArticle ? 'het' : 'de'
        } <strong>${edit.label?.toLowerCase()}</strong> in.`;
      errors.push(
        createError(
          `${prop}-required-${edit.label}`,
          'selectPropertyValidations-required',
          message,
          pick(edit, ['component', 'property']),
          edit.required
        )
      );
    }
    if ('maxLength' in edit && isContentTooLong(content, prop, edit.maxLength)) {
      const message = `${
        edit.definiteArticle ? 'Het' : 'De'
      } <strong>${edit.label?.toLowerCase()}</strong> mag niet langer dan ${
        edit.maxLength
      } tekens zijn`;
      errors.push(
        createError(
          `${prop}-maxlength`,
          'selectPropertyValidations-maxlength',
          message,
          pick(edit, ['component', 'property']),
          edit.required
        )
      );
    }
    if ('validations' in edit) {
      const validations = edit.validations.reduce((allValidationResults, validationRule) => {
        if (isAsyncValidationRule(validationRule)) {
          throw new Error('Async validation rules are not supported in property validations (yet)');
        } else {
          const result = validationRule(state, href, parentHref, edit);
          allValidationResults.push(result);
        }
        return allValidationResults;
      }, [] as Array<ValidationResult>);

      errors.push(...validations.filter(isValidationErrorOrWarning));
    }
  });

  if (!errors.length) {
    return emptyArray; // to keep the reference the same
  }

  return errors;
};

const selectNodeSpecificValidationErrors = createTypedSelector(
  [(state) => state, (state, href) => href, (state, href, parentHref) => parentHref],
  (state, href: ContentHref, parentHref: ContentHref): Array<ValidationResult> => {
    const nodeType = selectNodeType(state, href);
    if (!nodeType) {
      return emptyArray;
    }
    const config = selectAppliedNodeConfig(state, href);
    const validationRules = [
      ...(nodeTypeSelectorsMap[nodeType].validationRules || []),
      ...(('validationRules' in config && config.validationRules) || []),
    ];
    if (!validationRules || !validationRules.length) {
      return emptyArray;
    }
    return validationRules.reduce((allValidationResults, validationRule) => {
      if (isAsyncValidationRule(validationRule)) {
        const result = validationRule.selectValidation(state, href);
        allValidationResults.push(result);
      } else {
        const result = validationRule(state, href, parentHref);
        allValidationResults.push(result);
      }
      return allValidationResults;
    }, [] as Array<ValidationResult>);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

const selectNodeValidationResultForContent = createCachedSelector(
  [
    // 1. Generic Validation which applies to ALL nodes, regardless of NodeType
    (state, href: ContentHref) => selectAudienceValidationErrors(state, href),
    selectWebPageErrors,
    selectIsReadOrderUniqueWithinParent,
    (state, href: ContentHref, parentHref: ContentHref | undefined) =>
      selectIsBuildingBlockValidWithinParent(state, href, parentHref),
    selectIsNodeReferenceValid,
    // 2. Simple property validation: require, maxLength, minLength, pattern in NodeTypeConfig.edit
    (state, href: ContentHref, parentHref: ContentHref | undefined) =>
      selectPropertyValidations(state, href, parentHref),
    // 3. NodeType specific validation rules with selectors from nodeTypeSelectorsMap
    selectNodeSpecificValidationErrors,
    (state, href: ContentHref) => href,
    (state, href, parentHref: ContentHref | undefined) => parentHref,
  ],
  (
    audienceErrors,
    webPageErrors,
    readOrderError,
    invalidBuidlingBlockError,
    invalidReferenceError,
    propertyValidationErrors,
    nodeSpecificValidationErrors,
    href,
    parentHref
  ) => {
    const validationResultsForNode: Array<ValidationResult> = [
      ...audienceErrors,
      ...webPageErrors,
      readOrderError,
      invalidBuidlingBlockError,
      invalidReferenceError,
      ...propertyValidationErrors,
      ...nodeSpecificValidationErrors,
    ].filter((res) => res !== true);

    return {
      status: getValidationStatus(validationResultsForNode, false),
      validationErrors: validationResultsForNode
        .filter(isValidationErrorOrWarning)
        .map((z: ValidationError) => {
          const validationWitNode: ValidationErrorWithNode = {
            ...z,
            node: {
              href,
              parentHref,
              nodeId: getContentNodeId({ parentHref, childHref: href }),
            },
          };
          return validationWitNode;
        }),
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
)(keySelectorHrefAndParent);

const loading: ValidationInfo = {
  status: 'UNKNOWN',
  validationErrors: [],
};

const selectNodeValidationResults = (
  state,
  href: ContentHref,
  parentHref: ContentHref | undefined
): ValidationInfo => {
  if (!selectIsDocumentReadyToShow(state)) {
    return loading; // keep same reference.
  }

  const validationErrors = selectNodeValidationResultForContent(state, href, parentHref);

  return validationErrors;
};

const selectHrefsToNotValidate = createTypedSelector(
  [
    (state) => selectAllHiddenContenHrefs(state),
    (state) => selectProposedContentHrefsToDelete(state),
  ],
  (hiddenHrefs, deletedHrefs) => new Set([...hiddenHrefs, ...deletedHrefs])
);

const selectHrefsToNotValidateInToc = createTypedSelector(
  [
    selectHrefsToNotValidate,
    (state, href: ContentHref) => selectTableOfContentChildren(state, href),
  ],
  (hiddenHrefs, tocChildHrefs) => new Set([...tocChildHrefs, ...hiddenHrefs])
);

const selectAllDescendantHrefsToValidate = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state).to,
    (state) => selectLoadedContentHrefsSet(state),
    selectHrefsToNotValidate,
    (state, href: ContentHref) => href,
  ],
  (toRelationsMap, loadedContent, excludedHrefs, href: ContentHref) => {
    return getAllDescendantParentChildHrefs(toRelationsMap, href, excludedHrefs).filter((z) =>
      loadedContent.has(z.childHref)
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

const selectValidationResultsMap: (state: RootState) => Record<ValidationMapIndex, ValidationInfo> =
  logPerformance(
    'selectValidationResultsMap',
    createTypedSelector(
      [(state) => state],
      (state: RootState) => {
        const documentHref = selectRootHref(state);

        if (!documentHref) {
          return {};
        }

        const validationResultMap = {} as Record<ValidationMapIndex, ValidationInfo>;
        const validationResultsForNode = selectNodeValidationResults(
          state,
          documentHref,
          undefined
        );
        validationResultMap[
          getParentChildIndex({ parentHref: undefined, childHref: documentHref })
        ] = validationResultsForNode;

        const descendantHrefs = selectAllDescendantHrefsToValidate(state, documentHref);

        descendantHrefs.forEach(({ parentHref, childHref }) => {
          validationResultMap[getParentChildIndex({ parentHref, childHref })] =
            selectNodeValidationResults(state, childHref, parentHref);
        });
        return validationResultMap;
      },
      {
        memoizeOptions: {
          resultEqualityCheck: isEqual,
        },
      }
    )
  );

const selectDocumentValidationStatus = createTypedSelector(
  [(state) => selectIsDocumentReadyToShow(state) && selectValidationResultsMap(state)],
  (validationResultsMap) => {
    if (!validationResultsMap) {
      return 'UNKNOWN';
    }

    const validationResults = Object.values(validationResultsMap) as ValidationInfo[];
    const status = getHighestValidationStatus(validationResults);
    console.log('[validationSelectors] document validation status:', status);
    return status;
  }
);

export const selectIsDocumentValid = (state) => {
  const status = selectDocumentValidationStatus(state);
  return status !== 'INVALID' && status !== 'UNKNOWN';
};

export const selectValidationInfoForTocRow = createTypedSelector(
  [
    selectValidationResultsMap,
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state).to,
    (state, href: ContentHref) => selectHrefsToNotValidateInToc(state, href),
    (state, href: ContentHref) => href,
    (state, href, parentHref: ContentHref | undefined) => parentHref,
  ],
  (
    validationResultsMap,
    toRelationsMap,
    excludedHrefs,
    href: ContentHref,
    parentHref
  ): ValidationInfo => {
    const validationResultsForNode =
      validationResultsMap[getParentChildIndex({ parentHref, childHref: href })];

    if (!validationResultsForNode) {
      return {
        status: 'UNKNOWN' as ValidationStatus,
        validationErrors: [],
      };
    }

    const descendants = getAllDescendantParentChildHrefs(toRelationsMap, href, excludedHrefs);

    const firstChildWithError = descendants.find((item) => {
      const valResultsForDescendants = validationResultsMap[getParentChildIndex(item)];
      return valResultsForDescendants?.status === 'INVALID';
    });

    const firstChildWithWarning = descendants.find((item) => {
      const valResultsForDescendants = validationResultsMap[getParentChildIndex(item)];
      return valResultsForDescendants?.status === 'VALID_WITH_WARNINGS';
    });

    if (!firstChildWithError && !firstChildWithWarning) {
      return sortValidationErrorsFirst(validationResultsForNode);
    }

    const newValidationErrors = [...validationResultsForNode.validationErrors];
    if (firstChildWithError) {
      newValidationErrors.push(
        createChildError(firstChildWithError.parentHref, firstChildWithError.childHref)
      );
    }
    if (firstChildWithWarning) {
      newValidationErrors.push(
        createChildWarning(firstChildWithWarning.parentHref, firstChildWithWarning.childHref)
      );
    }

    return sortValidationErrorsFirst({
      status: getValidationStatus(newValidationErrors, true),
      validationErrors: newValidationErrors,
    });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

const sortValidationErrorsFirst = (validationInfo: ValidationInfo) => {
  if (!validationInfo) {
    return validationInfo;
  }

  const validationErrors = validationInfo.validationErrors.sort(
    (a: ValidationError, b: ValidationError) =>
      Number(b.type === 'ERROR') - Number(a.type === 'ERROR')
  );

  return { ...validationInfo, validationErrors };
};

export const selectValidationInfoForNode = createTypedSelector(
  [
    (state, href: ContentHref) => state.documentUI.collapsedNodes[href],
    selectValidationResultsMap,
    selectAllDescendantHrefsToValidate,
    (state, href: ContentHref) => href,
    (state, href, parentHref: ContentHref | undefined) => parentHref,
  ],
  (
    isCollapsed,
    validationResultsMap,
    descendants,
    href: ContentHref,
    parentHref
  ): ValidationInfo => {
    const validationResultsForNode =
      validationResultsMap[getParentChildIndex({ parentHref, childHref: href })];
    if (!isCollapsed) {
      return sortValidationErrorsFirst(validationResultsForNode);
    }
    const firstChildWithError = descendants.find((item) => {
      const valResultsForDescendants = validationResultsMap[getParentChildIndex(item)];
      return valResultsForDescendants.status === 'INVALID';
    });

    const firstChildWithWarning = descendants.find((item) => {
      const valResultsForDescendants = validationResultsMap[getParentChildIndex(item)];
      return valResultsForDescendants.status === 'VALID_WITH_WARNINGS';
    });

    if (!firstChildWithError && !firstChildWithWarning) {
      return sortValidationErrorsFirst(validationResultsForNode);
    }

    const newValidationErrors = [...validationResultsForNode.validationErrors];
    if (firstChildWithError) {
      newValidationErrors.push(
        createChildError(firstChildWithError.parentHref, firstChildWithError.childHref)
      );
    }
    if (firstChildWithWarning) {
      newValidationErrors.push(
        createChildWarning(firstChildWithWarning.parentHref, firstChildWithWarning.childHref)
      );
    }

    return sortValidationErrorsFirst({
      status: getValidationStatus(newValidationErrors, true),
      validationErrors: newValidationErrors,
    });
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);

export const selectValidationInfoForNodeAside = createTypedSelector(
  [
    (state) => state.documentUI.currentEditingNode,
    (state) => selectValidationResultsMap(state),
    (state, component: string) => component,
    (state, component: string, property: EditComponentProperty | undefined) => property,
  ],
  (editingNode, validationResultsMap, component, property): ValidationInfo => {
    if (!editingNode) {
      return {
        status: 'UNKNOWN',
        validationErrors: [],
      };
    }

    const validationKey = Object.keys(validationResultsMap).find((key) => {
      if (key.endsWith(editingNode)) {
        return true;
      }
      return false;
    });

    if (!validationKey) {
      return {
        status: 'UNKNOWN',
        validationErrors: [],
      };
    }

    const validationErrorsForProperty = validationResultsMap[validationKey].validationErrors.filter(
      (error) => error.property === property && error.component === component
    );

    return {
      status: getValidationStatus(validationErrorsForProperty, true),
      validationErrors: validationErrorsForProperty,
    };
  }
);
