import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';
import { get } from 'lodash';
import { getDiffForArraysOfStrings } from '../transformProposal/asideDiffText';

export const selectChangeMessageForStringArray: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newValue: string[] = (get(content, config.property) as string[]) ?? [];
      const oldValue: string[] = (get(originalContent, config.property) as string[]) ?? [];

      const diff = getDiffForArraysOfStrings(oldValue, newValue);

      return diff;
    }
  );
