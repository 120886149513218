import { NodeType } from '@nodeTypeConfig/configTypes';

export const proWebFacetAncestorTypes: Array<NodeType> = [
  // only these three types can have facets configured.
  NodeType.PRO_DATABASE_STANDALONE,
  NodeType.PRO_DATABASE_TILE,
  NodeType.PRO_DOWNLOAD_PAGE_STANDALONE,
  NodeType.PRO_DOWNLOAD_PAGE_TILE,
  NodeType.PRO_BLOG_STANDALONE,
  NodeType.PRO_BLOG_TILE,
];
