import { selectZillGenericGoalNode } from '@newStore/documentUI/transformContent/zillContentNodeSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForConcordanties } from '@UI/aside/asideConcordanties/asideConcordantiesSelectors';

const CURRICULUM_ZILL_GENERIC_GOAL: BuildingBlockNodeConfig = {
  nodeSelector: selectZillGenericGoalNode,
  information: {
    single: 'Doel',
    plural: 'Doelen',
  },
  buildingBlocks: [
    {
      type: NodeType.CURRICULUM_ZILL_CONTENT_GROUP,
      max: 1,
    },
    {
      type: NodeType.CURRICULUM_ZILL_LEERLIJN,
      max: 1,
    },
    {
      type: NodeType.CURRICULUM_ZILL_LEERLIJN_AN,
      max: 1,
    },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'concordanties',
      reactComponent: 'AsideConcordanties',
      asideChangeMessageSelector: selectChangeMessageForConcordanties,
      label: 'Concordanties',
    },
  ],
  documentViewIdentifier: 'pi',
  isCollapsible: true,
  isCollapsedByDefault: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_GENERIC_GOAL;
