import React from 'react';
import './ImageCropSelector.scss';
import { useReadOnly } from '../../hooks/UseReadonly';

interface ImageSelectorProps {
  onImageSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({ onImageSelected }) => {
  const readOnly = useReadOnly();
  return (
    <div>
      <div
        className="drop-zone"
        style={{ marginRight: 0 }}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            onImageSelected({
              target: { files: e.dataTransfer.files },
            } as React.ChangeEvent<HTMLInputElement>);
            e.dataTransfer.clearData();
          }
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        Sleep je afbeelding hier
      </div>

      <span className="input-group">
        <span className="input-group-btn">
          <span className="btn btn-default btn-file">
            Blader...
            <input type="file" readOnly={readOnly} onChange={onImageSelected} />
          </span>
        </span>
        <input type="text" className="form-control" readOnly />
      </span>
    </div>
  );
};

export default ImageSelector;
