import { ContentHref, NamedSet, NamedSetHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { selectIsNodeReadOnly } from '@newStore/documentUI/documentUISelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { selectNamedSetsForTag } from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideNamedSet,
  EditComponent,
  EditComponentProperty,
} from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import {
  selectCurrentEditingNode,
  selectCurrentOrInheritedPropertyValue,
  selectInheritedPropertyValue,
} from '@store/selectors/asideSelectors';
import { get, isEqual, sortBy } from 'lodash';

const tooltipContent = {
  Middenkader: 'ict-coördinator, zorgcoördinator, graadcoördinator, technisch adviseur,…',
  'Ondersteunend personeel': 'administratief en opvoedend personeel',
};

export const selectNamedSetOptionsForTag = createTypedSelector(
  [
    (state, href: ContentHref, config: EditComponent) =>
      'tag' in config ? selectNamedSetsForTag(state, config.tag) : null,
    (state, href: ContentHref, config: EditComponent) =>
      selectCurrentOrInheritedPropertyValue(state, href, config),
  ],
  (namedSets: NamedSet[] | null, currentValue: NamedSetHref[]) => {
    return sortBy(
      namedSets?.map((option) => ({
        name: option.name,
        key: option.key,
        href: option.$$meta.permalink,
        isChecked: Boolean(
          currentValue.some((selected) => getResourceKey(selected) === option.key)
        ),
        tooltip: tooltipContent[option.name],
      })) || [],
      ['name']
    );
  }
);

/**
 * in a Teaser the positions are readonly when there are themes selected
 */
export const selectIsReadOnly = createTypedSelector(
  [
    (state) =>
      Boolean(
        !state.documentUI.currentEditingNode ||
          selectIsNodeReadOnly(state, state.documentUI.currentEditingNode)
      ),
    (state) => selectCurrentEditingNode(state),
    (state, property: EditComponentProperty) => property,
  ],
  (readOnly, currentValue, property) => {
    const isReadOnly =
      readOnly ||
      (property === 'positions' &&
        currentValue.type === 'TEASER' &&
        (currentValue.themes ?? [])?.length > 0);
    return isReadOnly;
  }
);

export const selectChangeMessageForNamedSets: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) =>
        selectInheritedPropertyValue(state, href, config),
      (state, href: ContentHref, config: EditComponent) =>
        selectNamedSetsForTag(state, (config as EditAsideNamedSet).tag),
      (state, href: ContentHref, config: EditComponent) => config as EditAsideNamedSet,
    ],
    (
      content,
      originalContent,
      inheritedValues,
      options: NamedSet[] | null,
      config
    ): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      if (!options) {
        return null;
      }

      const optionsMap = new Map(options.map((option) => [option.$$meta.permalink, option.name]));

      const sortedOptions = sortBy(options, ['name']).map((z) => z.name);

      const newValue = (get(content, config.property) ?? inheritedValues) as NamedSetHref[];
      const oldValue = (get(originalContent, config.property) ?? inheritedValues) as NamedSetHref[];

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      const diffHtml = getDiffForArraysOfStrings(
        oldValue.map((z) => optionsMap.get(z) || ''),
        newValue.map((z) => optionsMap.get(z) || ''),
        sortedOptions
      );

      return diffHtml;
    }
  );
