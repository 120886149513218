import { RootState } from '@generalTypes/rootStateTypes';
import { loadPreviousVersion } from '@newStore/llinkid/llinkidActions';
import { selectPreviousVersionContent } from '@newStore/llinkid/llinkidSelectors';
import { EditAsidePreviousVersion } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTitle } from '../../../../services/types';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import SimpleInput from '../asideSimpleInput/SimpleInput';
import AsideValidation from '../asideValidationErrors/AsideValidation';

const AsidePreviousVersion: React.FC<{ config: EditAsidePreviousVersion }> = ({ config }) => {
  const dispatch = useDispatch();

  const currentEditingHref = useEditHref();

  const previousCurrs = useSelector((state: RootState) =>
    selectPreviousVersionContent(state, currentEditingHref)
  );

  const readOnly = useReadOnly(config.readonly);

  useEffect(() => {
    if (currentEditingHref) {
      dispatch(loadPreviousVersion({ href: currentEditingHref }));
    }
  }, [dispatch, currentEditingHref]);

  const defaultValue = previousCurrs[0] ? getTitle(previousCurrs[0], { addIdentifier: true }) : '';

  return (
    <AsideValidation property={config.property} component={config.component}>
      {/* This read only component cannot have a change indicator */}
      <SimpleInput
        defaultValue={defaultValue}
        label={config.label}
        readOnly={readOnly}
        onChange={(_value) => {}}
      />
    </AsideValidation>
  );
};

AsidePreviousVersion.displayName = 'AsidePreviousVersion';

export default AsidePreviousVersion;

AsidePreviousVersion.propTypes = {
  config: any,
};
