import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { NodeType, RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { referencedInProHomeCannotUnpublish } from '@newStore/validation/validationRules/referencedInProHomeCannotUnpublish';
import {
  validateWebConfigurationRequired,
  validateWebTemplateConfig,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectChangeMessageForLinks } from '@UI/aside/asideLinks/asideLinksSelectors';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { selectChangeMessageForPersonPicker } from '@UI/aside/asidePersonPicker/asidePersonPickerSelectors';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import constants from '../../../reduxLoop/constants/constants';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import { settings } from '../../settings';
import { proDefaultBuildingBlocks } from '../helpers/buildingBlocks/proDefaultBuildingBlocks';
// Generic Pro Theme in the list screen or onLoading before it has the full document context and becomes something more specific
// Also WEBPAGE2 with invalid WebConfig will not match a specific Pro Theme and will be a WEBPAGE2
export const WEBPAGE2: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: [
    validateWebConfigurationRequired,
    validateWebTemplateConfig,
    referencedInProHomeCannotUnpublish,
  ],
  information: {
    definiteArticle: true,
    single: 'PRO.-thema',
    plural: "PRO.-thema's",
    icon: require('../../../../img/icons/webpage.svg'),
    category: 'PRO',
  },
  onLoadActions: [
    loadWebConfigurations(),
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
  ],
  createDefaults: {
    accessRights: accessRights.publiek.namedsets,
    descendantsAccessRights: accessRights.publiek.namedsets,
  },
  buildingBlocks: proDefaultBuildingBlocks,
  tocTypes: [NodeType.PRO_THEME_SECTION, NodeType.FAQ_GROUP],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      label: 'Titel',
      required: RequiredType.ERROR,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      ...imageComponentBase,
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        crop: {
          aspectRatio: 4 / 3,
          resize: {
            width: 710,
            height: 533,
            quality: 0.6,
          },
        },
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      label: 'Themaverantwoordelijke',
    },
    publishComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
    },
    {
      component: 'contacts',
      property: 'contacts',
      label: 'Contactpersonen',
      reactComponent: 'AsidePersonPicker',
      asideChangeMessageSelector: selectChangeMessageForPersonPicker,
      width: '12',
      options: {
        queryParams: constants.defaultContactsParameters,
      },
    },
    {
      component: 'menuItemReference',
      label: 'Navigeert naar',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [NodeType.WEBPAGE2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    {
      component: 'linkGroup',
      reactComponent: 'AsideLinks',
      asideChangeMessageSelector: selectChangeMessageForLinks,
      width: '12',
      label: 'Links',
      options: {
        type: 'group',
      },
    },
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  isCloneable: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  allowSuggestions: true,
  globalAttachmentsGroupContainer: true,
};
