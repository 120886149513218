import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const CURRICULUM_CLUSTER: BuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Leerplancluster',
    plural: 'Leerplanclusters',
  },

  buildingBlocks: [{ type: NodeType.CURRICULUM_THEME }],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      label: 'Titel',
      required: RequiredType.ERROR,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  themesDisplaySuffix: '(cluster)',
  isCollapsible: false,
};

export default CURRICULUM_CLUSTER;
