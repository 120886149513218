import { Mode } from '@newStore/documentUI/documentUIState';
import { ProposalMetaInfo } from '@newStore/documentUI/documentUITypes';

export const getUserColor = (proposalMetaInfo: ProposalMetaInfo) => {
  if (proposalMetaInfo.authors.length > 1) {
    return '#0E5690';
  }
  return '#030303'; // gray
};

export const getBorderLeftColor = (proposalMetaInfo: ProposalMetaInfo, mode: Mode | null) => {
  if (mode === 'REVIEW') {
    return proposalMetaInfo.type === 'CREATE' ? 'lightgreen' : 'yellow';
  }
  if (mode === 'EDIT') {
    return '#32CD32';
  }
  return proposalMetaInfo.userIsAuthor ? 'orange' : 'gray';
};
