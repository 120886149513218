import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { validateWebTemplateConfig } from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { WWW } from './WWW';
import { wwwDefaultBuildingBlocks } from './wwwGenericConfigProps';



// Extends WWW => a WWW with WebConfig WWW_HARD_CODED_PAGE becomes a WWW_STATIC_PAGE
export const WWW_STATIC_PAGE: ExtendedRootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: [validateWebTemplateConfig],
  information: {
    description:
      'WWW Static Page (webTemplate code WWW_HARD_CODED_PAGE) can only be a root root (single page pro theme)',
    single: 'WWW: Statische pagina',
    plural: "Statische pagina's",
    icon: require('../../../../img/icons/webpage.svg'),
  },
  extends: NodeType.WWW,

  buildingBlocks: wwwDefaultBuildingBlocks,
  tocTypes: [],
  edit: WWW.edit,
};
