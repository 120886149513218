import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proDocumentStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proDocumentStandardComponents';
import { proTileStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proTileStandardComponents';
import { proThemeTocTypes } from '@nodeTypeConfig/helpers/buildingBlocks/proThemeTocTypes';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';

import { proTileDefaultValidionRules } from '../proGenericConfigProps';
import { proThemeDefaultValidationRules } from '../proGenericConfigProps';

const proTempPage = {
  nodeSelector: selectTitleWithDescriptionNode,
  information: {
    description:
      'Pro Temporary Page (webTemplate code TEMPORARY_PAGE) is most of the time a root web page (in 19 cases on prod a SECTION in a HOME). These are pages the business did not convert yet to a real structured document and the content for this page is mainly in a PDF',
    single: 'PRO.-tegel: TEMP-pagina',
    plural: "TEMP-pagina's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig TEMPORARY_PAGE becomes a PRO_TEMPORARY_PAGE_TILE
export const PRO_TEMPORARY_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proTempPage,
  validationRules: proTileDefaultValidionRules,
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents.filter((comp) => comp.component !== 'subject'),
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding TEMPORARY_PAGE WebConfig to a WEBPAGE2 it becomes a PRO_TEMPORARY_PAGE_STANDALONE
export const PRO_TEMPORARY_PAGE_STANDALONE: ExtendedRootNodeConfig = {
  ...proTempPage,
  validationRules: proThemeDefaultValidationRules,
  information: {
    ...proTempPage.information,
    definiteArticle: true,
    single: 'PRO.-thema: TEMP-pagina',
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [...proDocumentStandardComponents, ...proLinksAndMenuReferences],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
