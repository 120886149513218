import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { EditAsideTeaserLinkedContent } from '@nodeTypeConfig/configTypes';

import { teaserLinkOptions } from '@store/constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEditLinkReferenceNodeAction,
  setLinkedContentCallToActionAction,
  setLinkedContentTypeAction,
} from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { selectLinkedContent } from '@newStore/documentUI/transformContent/teaserSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  AsyncKovResourcePicker,
  useLoadSriOptions,
} from '@kathondvla/react-shared-components/src/components';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import AsideValidation from '../asideValidationErrors/AsideValidation';

const DisplayName = ({ item }) => {
  if (item.title) {
    return item.title;
  }
  if (item?.$$expanded?.$$pathToRoot?.length > 1) {
    return (
      <span>
        {item.$$expanded.title} -{' '}
        <span
          className="pathToRoot"
          dangerouslySetInnerHTML={{ __html: item.$$expanded.$$pathToRoot.slice(1).join(' > ') }}
        ></span>
      </span>
    );
  }

  return item?.$$expanded?.title ?? '';
};

const AsideTeaserLinkedContent: React.FC<{
  config: EditAsideTeaserLinkedContent;
}> = ({ config }) => {
  const readOnly = useReadOnly(false);
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const editKey = getResourceKey(editHref);
  const linkedContent = useSelector((state: RootState) => selectLinkedContent(state, editHref));

  /* When the user clicks the radio button to change, linkedContent.type === NOT_LINKED until the user selects a resource from the dropdown,
  so we need this local state to keep track of the selected type */
  const [linkedType, setLinkedType] = useState();
  useEffect(() => {
    if (!linkedType && linkedContent?.type) {
      setLinkedType(linkedContent?.type);
    }
  }, [linkedType, linkedContent?.type]);

  const sriLoadOptionsByType = {};

  for (const [key, option] of Object.entries(teaserLinkOptions)) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    sriLoadOptionsByType[key] = useLoadSriOptions({
      sriClient: option.sriClient,
      href: option.resource,
      parameters: (key === 'WEBPAGE2' && linkedContent?.filter) || option.resourceFilter,
    });
  }

  const sriLoadOptionsRoot = useLoadSriOptions({
    sriClient: teaserLinkOptions.WEBPAGE2.sriClient,
    href: teaserLinkOptions.WEBPAGE2.resource,
    parameters: teaserLinkOptions.WEBPAGE2.resourceFilter,
  });

  const changeLinkedContent = (type) => {
    setLinkedType(type);
    if (linkedContent) {
      dispatch(setLinkedContentTypeAction(editKey, linkedContent?.referenceKey, type));
    }
  };

  const callAddEditReference = (referencedResourceHref) => {
    dispatch(
      addEditLinkReferenceNodeAction(
        editKey,
        linkedContent?.referenceKey,
        linkedContent?.callToAction,
        referencedResourceHref
      )
    );
  };

  const changeCallToAction = (callToAction) => {
    dispatch(
      setLinkedContentCallToActionAction(
        editKey,
        linkedContent?.referenceKey,
        callToAction,
        linkedContent?.referencedResourceHref
      )
    );
  };

  return (
    <div className={`form-group ${readOnly ? 'aside-component-disabled' : ''}`}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        {config.label && (
          <label id="linkedContent" className="control-label">
            {config.label}
          </label>
        )}
        {Object.entries(teaserLinkOptions).map(([key, option]) => (
          <div key={key}>
            <input
              id={key}
              type="radio"
              disabled={readOnly}
              name="cta"
              onChange={() => changeLinkedContent(key)}
              value={key}
              checked={linkedType === key}
              className="form-control"
            />
            <label
              htmlFor={key}
              className={`radiolabel ${readOnly ? 'aside-component-disabled' : ''}`}
            >
              {option.label}
            </label>

            {linkedType === key && key === 'WEBPAGE2' && (
              <div className="form-group">
                <AsyncKovResourcePicker
                  value={
                    linkedContent?.referencedResourceRoot ?? {
                      key: null,
                      title: 'Selecteer een item',
                    }
                  }
                  loadOptions={sriLoadOptionsRoot}
                  clearable={false}
                  disabled={readOnly}
                  onChange={(o) => callAddEditReference(o.href)}
                  placeholder="Selecteer een item"
                  multiple={false}
                  menuPlacement="bottom"
                  optionValue={(o) => o.key}
                  optionTemplate={(o) => <DisplayName item={o} />}
                />
              </div>
            )}

            {linkedType === key &&
              ((key !== 'WEBPAGE2' && option.resource) ||
                (key === 'WEBPAGE2' && linkedContent?.referencedResourceRoot)) && (
                <div className="form-group">
                  <AsyncKovResourcePicker
                    value={
                      (linkedContent?.type === key && linkedContent?.referencedResource) || {
                        key: null,
                        title: 'Selecteer een item',
                      }
                    }
                    loadOptions={sriLoadOptionsByType[key]}
                    clearable={false}
                    disabled={readOnly}
                    onChange={(o) => {
                      callAddEditReference(o.href);
                    }}
                    placeholder="Selecteer een item"
                    multiple={false}
                    menuPlacement="bottom"
                    optionValue={(o) => o.key}
                    optionTemplate={(o) => <DisplayName item={o} />}
                  />
                </div>
              )}

            {linkedType === key && key === 'EXTERNAL_URL' && (
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={linkedContent?.referencedResourceHref || ''}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                    callAddEditReference(e.target?.value)
                  }
                />
              </div>
            )}
          </div>
        ))}

        <label className="control-label">Call-to-action</label>
        <input
          type="text"
          className="form-control"
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            changeCallToAction(e.target?.value);
          }}
          disabled={!linkedContent?.referenceKey}
          defaultValue={linkedContent?.callToAction ?? ''}
        />
      </AsideValidation>
    </div>
  );
};

AsideTeaserLinkedContent.displayName = 'AsideTeaserLinkedContent';

export default AsideTeaserLinkedContent;

AsideTeaserLinkedContent.propTypes = {
  config: any,
};
