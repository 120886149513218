import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
  selectPathToRootHrefs,
  selectWebPagePerContentMap,
} from '@newStore/documentApi/documentApiSelectors';
import { selectNodeType } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import {
  selectExternalContent,
  selectExternalContentRelationsMap,
} from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { EditAsideReferenceFramePicker } from '@nodeTypeConfig/configTypes';
import { getGoalPathText } from '@store/helpers/documentHelpers';
import { selectOptionsForAsideReferenceFrames } from '@store/selectors/asideSelectors';
import { uniqBy } from 'lodash';

const emptyArray = [];

const containerType = new Map([
  ['MINI_DATABASE', ['SECTION', 'SHARED_MINI_DATABASE_ITEM']],
  ['DOWNLOAD_PAGE', ['ATTACHMENTS_GROUP', 'SHARED_ATTACHMENTS_GROUP']],
  ['BLOG', ['SECTION']],
  ['HOME_PAGE', ['SECTION']],
  ['FALLBACK_MENU_LEVEL_2', ['SECTION']],
  ['FALLBACK_MENU_LEVEL_3', ['SECTION']],
]);

const nodeTypesWithSharedItemFacets = ['SHARED_MINI_DATABASE_ITEM', 'SHARED_ATTACHMENTS_GROUP'];

export const selectWebFacetsConfig = createTypedSelector(
  [
    (state, href) => selectWebPagePerContentMap(state)[selectPathToRootHrefs(state, href)[1]], // select parent webconfig
    (state, href) => selectContentItem(state, href),
    (state, href) => selectNodeType(state, href),
    (state, href) => state.sharedItemFacets[href],
  ],
  (parentWebFacetsConfig, currentNode, nodeType, sharedItemFacets) => {
    const isSharedItem = nodeTypesWithSharedItemFacets.includes(nodeType);
    // If the facet need to be found for shared items, we need to pick the
    // facets from a 'loadSharedItemFacets' action previously dispatched, instead of the parent webpage
    if (isSharedItem) {
      const facets = sharedItemFacets?.flatMap((node) => node.options?.facets || []) || [];
      return uniqBy(facets, (f) => f.source?.href || f.component);
    }

    if (!parentWebFacetsConfig) {
      return emptyArray;
    }

    if (
      !parentWebFacetsConfig?.type ||
      !containerType.get(parentWebFacetsConfig?.type)?.includes(currentNode.type)
    ) {
      return emptyArray;
    }
    return parentWebFacetsConfig.options?.facets || emptyArray;
  }
);

/**
 * returns the relations between the reference frame of the facet and the current content node.
 */
export const selectReferenceFramesFacetRelations = createTypedSelector(
  [
    (state, _href, referenceFrameHref: ContentHref) =>
      state.referenceFrames[referenceFrameHref]?.content,
    (state, href: ContentHref) => selectApiWithPendingChangesRelationsToAndFromMap(state).to[href],
  ],
  (referenceFrameContent, relationsTo) => {
    if (!referenceFrameContent) {
      return [];
    }
    return relationsTo.filter(
      (relation) =>
        !relation.$$meta.deleted &&
        relation.relationtype === 'REQUIRES' &&
        referenceFrameContent[relation.from.href]
    );
  }
);

export const selectReferenceFramesFacetSelectedOptions = createTypedSelector(
  [
    (state, _href, config: EditAsideReferenceFramePicker) =>
      selectOptionsForAsideReferenceFrames(
        state,
        config.options.referenceFrameHref,
        config.options.types
      ),
    (state, href, config: EditAsideReferenceFramePicker) =>
      selectReferenceFramesFacetRelations(state, href, config.options.referenceFrameHref),
  ],
  (options, relevantRelations) => {
    return options.filter((option) =>
      relevantRelations.some((rel) => rel.from.href === option.$$meta.permalink)
    );
  }
);

export const selectZillGoalsRelations = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectExternalContent(state),
    (state) => selectExternalContentRelationsMap(state),
    (state, href) => href,
  ],
  (relationsMap, externalContent, externalContentRelationsMap, href) => {
    const zillTypes = ['CURRICULUM_ZILL_GENERIC_GOAL', 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT'];
    return relationsMap.from[href].flatMap((rel) => {
      const curriculum = externalContent[rel.to.href];
      if (rel.relationtype === 'REFERENCES' && zillTypes.includes(curriculum?.type)) {
        const pathToRoot = getPathToRoot(
          externalContentRelationsMap,
          externalContent,
          rel.to.href as ContentHref
        );
        const rootHref = pathToRoot.at(-1)?.$$meta.permalink;
        return {
          relationKey: rel.key,
          contentHref: curriculum.$$meta.permalink,
          rootHref,
          title: curriculum.title,
          rootTitle: rootHref ? externalContent[rootHref].title : '',
          rootPath: getGoalPathText(pathToRoot.reverse()),
          isDeletedByProposal: rel.$$meta.deleted,
        };
      }
      return [];
    });
  }
);
