import { selectChangeMessageForStringArray } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForStringArray';
import { RootEditComponent } from '@nodeTypeConfig/configTypes';

export const proAliasesComponent: RootEditComponent = {
  component: 'subjects',
  property: 'subjects',
  reactComponent: 'AsideSubjects',
  label: 'Aliassen',
  asideChangeMessageSelector: selectChangeMessageForStringArray,
  valueToString: (c) => (c.subjects ? c.subjects.join(', ') : ''),
};
