import { selectZillDevelopmentThemeNode } from '@newStore/documentUI/transformContent/zillContentNodeSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';

const CURRICULUM_ZILL_DEVELOPMENT_THEME: BuildingBlockNodeConfig = {
  nodeSelector: selectZillDevelopmentThemeNode,
  information: {
    single: 'Thema',
    plural: "Thema's",
  },
  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ZILL_GENERIC_GOAL }],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
      view: {
        hideIfEmpty: true, // no longer has effect since refactor, so we can remove it or do we want to do something similar?
      },
    },
    {
      component: 'attachedContent',
      property: '$$html',
      label: 'Lees meer',
      reactComponent: 'AsideAttachedContent',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      view: {
        hideIfEmpty: true,
      },
    },
    {
      ...imageComponentBase,
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
    {
      ...imageComponentBase,
      label: 'Miniatuur',
      options: {
        type: 'THUMBNAIL',
      },
    },
  ],
  documentViewIdentifier: 'pi',
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_DEVELOPMENT_THEME;
