import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { RelationsToAndFromMap } from '@newStore/documentApi/documentApiTypes';
import { selectRootHref } from '@newStore/documentUI/documentUISelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { createTypedSelector, mapRelationsToRelationsToAndFrom } from '@newStore/genericHelpers';
import { getGoalIdentifier } from '@newStore/llinkid/llinkidHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

const getConcordantiesRelationsFromContentHref = (
  relationsMap: RelationsToAndFromMap,
  href: ContentHref
) => {
  return relationsMap.from[href]?.filter((relation) => relation.relationtype === 'IS_VERSION_OF');
};

const getOdetHref = (rootHref: ContentHref | null, relationsMap: RelationsToAndFromMap) => {
  if (!rootHref) {
    return null;
  }
  const rootConcordantieRelation = getConcordantiesRelationsFromContentHref(
    relationsMap,
    rootHref
  )?.[0];

  return rootConcordantieRelation?.to.href;
};

export const selectRootOdetHref = createTypedSelector(
  [
    (state) => selectRootHref(state),
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
  ],
  (rootHref, relationsMap) => getOdetHref(rootHref, relationsMap)
);

export const selectRootOdet = (state: RootState) => {
  const rootOdetHref = selectRootOdetHref(state);
  if (!rootOdetHref) {
    return undefined;
  }
  return state.referenceFrames?.[rootOdetHref];
};

export const selectAsideConcordanties = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectRootOdet(state),
    (state, href) => href,
  ],
  (relationsMap, rootOdet, href) => {
    const concordantieHrefList = getConcordantiesRelationsFromContentHref(relationsMap, href);
    return concordantieHrefList.flatMap((relation) => {
      const content = rootOdet?.content[relation.to.href];
      if (content && rootOdet) {
        const goalIdentifier = getGoalIdentifier(
          getPathToRoot(
            mapRelationsToRelationsToAndFrom(rootOdet.relations),
            rootOdet.content,
            content.$$meta.permalink
          )
        );
        return {
          key: relation.key,
          title: content.title || content.description,
          contentKey: content.key,
          goalIdentifier,
        };
      }
      return {
        key: relation.key,
      };
    });
  }
);

const hrefToOdetGoalString = (href: ContentHref, rootOdet, rawRootOdet) => {
  let content = rootOdet.content[href];
  if (!content) {
    content = rawRootOdet.content[href];
  }
  const goalIdentifier = getGoalIdentifier(
    getPathToRoot(mapRelationsToRelationsToAndFrom(rootOdet.relations), rootOdet.content, href)
  );
  return `${goalIdentifier} - ${content.title || content.description}`;
};


export const selectChangeMessageForConcordanties: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectRootHref(state),
      (state) => state.referenceFrames,
      (state, href: ContentHref) => href,
    ],
    (relationsMap, rawRelationsMap, rootHref, referenceFrames, href): string | null => {
      const rootOdetHref = getOdetHref(rootHref, relationsMap);
      const rootOdet = rootOdetHref && referenceFrames[rootOdetHref];
      const rawRootOdetHref = getOdetHref(rootHref, rawRelationsMap);
      const rawRootOdet = rawRootOdetHref && referenceFrames[rawRootOdetHref];

      if (!rootOdet?.isLoaded || !rawRootOdet?.isLoaded) {
        return null;
      }
      const currentConcordanties = getConcordantiesRelationsFromContentHref(relationsMap, href);
      const originalConcordanties = getConcordantiesRelationsFromContentHref(rawRelationsMap, href);

      const before = originalConcordanties.map((relation) =>
        hrefToOdetGoalString(relation.to.href, rootOdet, rawRootOdet)
      );
      const after = currentConcordanties.map((relation) =>
        hrefToOdetGoalString(relation.to.href, rootOdet, rawRootOdet)
      );

      const diffText = getDiffForArraysOfStrings(before, after);
      return diffText;
    }
  );
