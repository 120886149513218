import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import {
  selectTitleNode,
  selectTitleWithDescriptionNode,
} from '@newStore/documentUI/transformContent/genericSelectors';
import { validateDownloadListAndFaqPageShouldNotHaveDescription } from '@newStore/validation/validationRules/downloadPageShouldNotHaveDescription';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proDocumentStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proDocumentStandardComponents';
import { proTileStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proTileStandardComponents';
import { proThemeTocTypes } from '@nodeTypeConfig/helpers/buildingBlocks/proThemeTocTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';

import {
  proTileDefaultValidionRules,
  proThemeDefaultValidationRules,
} from '../proGenericConfigProps';

const proDownloadPage = {
  information: {
    definiteArticle: true,
    description:
      'Pro Downloadlijst (webTemplate code DOWNLOAD_PAGE) can be a SECTION (a tile) in a home or can be the root (single page pro theme, although only two standalone download pages in total...)',
    single: 'PRO.-tegel: Downloadlijst',
    plural: 'Downloadlijsten',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig DOWNLOAD_PAGE becomes a PRO_DOWNLOAD_PAGE_TILE
export const PRO_DOWNLOAD_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proDownloadPage,
  nodeSelector: selectTitleNode,
  validationRules: [
    ...proTileDefaultValidionRules,
    validateDownloadListAndFaqPageShouldNotHaveDescription,
  ],
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents.filter(
      (comp) => comp.component !== 'subject' && comp.property !== 'description'
    ),
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding DOWNLOAD_PAGE WebConfig to a WEBPAGE2 it becomes a PRO_DOWNLOAD_PAGE_STANDALONE
export const PRO_DOWNLOAD_PAGE_STANDALONE: ExtendedRootNodeConfig = {
  ...proDownloadPage,
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: proThemeDefaultValidationRules,
  information: {
    ...proDownloadPage.information,
    single: 'PRO.-thema: Downloadlijst',
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [...proDocumentStandardComponents, ...proLinksAndMenuReferences],
  addAccessRightsToAudienceTab: true,
};
