import { Content, ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectRootItem } from '@newStore/documentApi/documentApiSelectors';
import { selectAppliedDocumentRootConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import {
  loadReferenceFrame,
  ReferenceFrameState,
} from '@newStore/referenceFrames/referenceFramesState';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { contentApi } from '@store/api/apiConfig';
import { PATCH_NODE, PATCH_THEMES } from '@store/constants/actionTypes';
import { waitFor } from '@store/helpers/sagaUtils';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  loadSharedItemFacets,
  LoadSharedItemFacetsPayload,
  setSharedItemFacets,
} from './sharedItemFacets';

/**
 * For facets, we look at the web config of the parent, and use the facets configured in the parent node.
 * for shared items, however, there is no parent. But they will end up under different nodes within pro themes.
 * The logic below is to find the 'parents' in those PRO themes where the shared item will end up in, and get their webconfigs, so that we know which facets to show.
 * @param action
 * @returns
 */
function* handleLoadSaga(action: LoadSharedItemFacetsPayload) {
  const { matchingParams } = action.payload;
  // Get mini-databases / download pages
  yield call(waitFor, selectRootItem);
  const rootNode: Content = yield select(selectRootItem);
  if (!rootNode.themes?.length) {
    yield put(setSharedItemFacets({ href: rootNode.$$meta.permalink, webPages: [] }));
    return;
  }

  let isThemeFieldEmpty = false;

  const referenceFrameHrefs = Object.values(matchingParams)
    .filter((v) => typeof v === 'object' && 'referenceFrameKey' in v)
    .map((v) => `/content/${v.referenceFrameKey}` as ContentHref);

  for (const referenceFrameHref of referenceFrameHrefs) {
    yield put(loadReferenceFrame({ referenceFrame: referenceFrameHref }));
  }

  yield call(waitFor, (state: RootState) =>
    referenceFrameHrefs.every((z) => state.referenceFrames[z]?.isLoaded)
  );

  const referenceFrames: ReferenceFrameState = yield select(
    (state: RootState) => state.referenceFrames
  );

  const params = Object.fromEntries(
    Object.entries(matchingParams).map(([k, v]) => {
      if (typeof v !== 'object' || !('referenceFrameKey' in v)) {
        return [k, v];
      }

      const referenceFrame =
        referenceFrames[`/content/${v.referenceFrameKey}` as ContentHref].content;
      // get the themes from the themes array that are from this referenceframe
      const filteredThemes = rootNode.themes?.filter((href) => referenceFrame[href]) || [];

      if (!filteredThemes.length) {
        isThemeFieldEmpty = true;
      }

      return [k, filteredThemes];
    })
  );

  // If one of the theme fields is empty, there are no matches
  // Sending eg themesMatchingOnRoot=/content/...&themesOverlaps=<empty> would result in incorrect matching
  if (isThemeFieldEmpty) {
    yield put(setSharedItemFacets({ href: rootNode.$$meta.permalink, webPages: [] }));
    return;
  }

  // get the minidatabases (and their facets) with the same tagging of relevant themes
  const miniDatabases = yield contentApi.getAll('/content', params);
  const webPages = miniDatabases
    .map((mdb) => mdb.$$webPages)
    .flat()
    .map((wp) => wp.$$expanded);

  yield put(setSharedItemFacets({ href: rootNode.$$meta.permalink, webPages }));
}

/**
 * when the themes are updated a shared document, we need to refresh the shared item facets.
 * @returns
 */
function* checkForWebfacetsUpdateSaga() {
  yield take(PATCH_NODE); // wait for patch_node to be executed, so the themes are updated in the state.

  const rootNode: RootNodeConfig = yield select(selectAppliedDocumentRootConfig);
  if (!rootNode) {
    return;
  }
  const onLoadFacetAction = rootNode.onLoadActions?.find(
    (z) => z.type === loadSharedItemFacets.toString()
  );
  if (onLoadFacetAction) {
    yield put(onLoadFacetAction);
  }
}

// Watcher saga
export function* watchSharedFacets() {
  yield takeLatest(loadSharedItemFacets.match, handleLoadSaga);
  yield takeLatest(PATCH_THEMES, checkForWebfacetsUpdateSaga);
}
