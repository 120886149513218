import { any } from 'prop-types';
import { useDispatch } from 'react-redux';

import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';

import { EditAsideNewsletterSender } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import {
  selectIsNewsletterSenderComponentLoading,
  selectNewsletterSenderOptions,
  selectSelectedNewsletterSender,
} from './asideNewsletterSenderSelectors';

const AsideNewsletterSender: React.FC<{
  config: EditAsideNewsletterSender;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const isReadonly = useReadOnly();

  const isLoading = useTypedSelector(selectIsNewsletterSenderComponentLoading);
  const senderOptions = useTypedSelector(selectNewsletterSenderOptions);
  const selectedSender = useTypedSelector((state) => selectSelectedNewsletterSender(state, config));
  const editingHref = useEditHref();

  const onChange = (selection) => {
    dispatch(
      patchNodeAction(getResourceKey(editingHref), {
        newsletterSender: { href: selection.href },
      })
    );
  };

  const addCursorStyle = (provided) => ({ ...provided, cursor: 'pointer' });

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <label className="control-label">{config.label}</label>
      {isLoading ? (
        <div className="form-group shimmer narrowLine"></div>
      ) : (
        <KovResourcePicker
          value={selectedSender}
          localData={senderOptions}
          display="display"
          clearable={false}
          disabled={isReadonly}
          disableSearch={true}
          style={{ option: addCursorStyle, valueContainer: addCursorStyle }}
          onChange={onChange}
        />
      )}
    </AsideValidation>
  );
};

AsideNewsletterSender.displayName = 'AsideNewsletterSender';

AsideNewsletterSender.propTypes = { config: any };

export default AsideNewsletterSender;
