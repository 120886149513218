import { ContentHref } from '@generalTypes/apiTypes';
import { StudyProgramme, StudyProgrammeGroup } from '@generalTypes/samTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import {
  selectStudyProgrammeGroups,
  selectStudyProgrammes,
} from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { createError } from '@newStore/validation/createError';
import { AsideValidationRule } from '@newStore/validation/validationTypes';
import {
  AsideChangeMessageSelector,
  EditAsideStudyProgrammes,
  EditComponent,
  isEditAsideStudyProgrammes,
  RequiredType,
} from '@nodeTypeConfig/configTypes';
import { grade } from '@store/constants/studyProgrammeGroupTypes';
import { get, pick } from 'lodash';

export const selectGrades = createTypedSelector([selectStudyProgrammeGroups], (groups) => {
  return Object.values(groups).filter((group) => group.type.href === grade.href);
});

export const selectStudyProgrammeOptions = createTypedSelector(
  [selectStudyProgrammes, selectGrades],
  (studyProgrammes, grades) => {
    const options: Array<StudyProgramme & { $$grade: StudyProgrammeGroup }> = Object.values(
      studyProgrammes
    ).flatMap((studyProgramme) => {
      const g = grades.find((z) =>
        studyProgramme.studyProgrammeGroups?.some(
          (y) => y.studyProgrammeGroup.href === z.$$meta.permalink
        )
      );

      if (!g) {
        return [];
      }

      return {
        ...studyProgramme,
        title: `${studyProgramme.title} (graad ${g?.title || ''})`,
        $$grade: g,
      };
    });
    return options;
  }
);

export const selectStudyProgrammesSelectedOptions = createTypedSelector(
  [
    (state, _href, _config: EditAsideStudyProgrammes) => selectStudyProgrammeOptions(state),
    (state, href: ContentHref, config: EditAsideStudyProgrammes) =>
      get(selectContentItem(state, href), config.property),
  ],
  (options, values) => {
    const selected = new Set(values?.map((value) => value.href));
    return options.filter((option) => selected.has(option.$$meta.permalink));
  }
);

export const selectChangeMessageForStudyProgrammes: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, _href: ContentHref, _config: EditComponent) => selectStudyProgrammeOptions(state),
      (state, _href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, options: StudyProgramme[], config): string | null => {
      if (!options) {
        return null;
      }

      if (!isEditAsideStudyProgrammes(config)) {
        console.error('config is not for StudyProgrammes', config);
        throw Error('config is not for StudyProgrammes');
      }

      const selectedSPs = get(content, config.property);
      const rawSelectedSPs = get(originalContent, config.property);

      const optionsMap = new Map(options.map((option) => [option.$$meta.permalink, option.title]));

      const sortedOptions = options.map((z) => z.title).toSorted();

      const diff = getDiffForArraysOfStrings(
        rawSelectedSPs?.map((z) => optionsMap.get(z.href) || ''),
        selectedSPs?.map((z) => optionsMap.get(z.href) || ''),
        sortedOptions
      );

      return diff;
    }
  );

export const selectStudyProgrammesInSameGradeValidation: AsideValidationRule = createTypedSelector(
  [
    (state, href: ContentHref, _parentHref: ContentHref | undefined, config: EditComponent) =>
      selectStudyProgrammesSelectedOptions(state, href, config as EditAsideStudyProgrammes),
    (state, _href: ContentHref, _parentHref: ContentHref | undefined, config: EditComponent) =>
      config,
  ],
  (selectedOptions, config) => {
    const grades = new Set(selectedOptions.map((option) => option.$$grade.title));
    if (grades.size > 1) {
      return createError(
        `studyProgrammesMultiGrade`,
        'selectStudyProgrammesInSameGradeValidation',
        'Alle studierichtingen moeten tot dezelfde graad behoren.',
        pick(config, ['component', 'property']),
        RequiredType.ERROR
      );
    }
    return true;
  }
);
