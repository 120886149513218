import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { EditAsideWebFacets } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React from 'react';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

const AsideWebFacetPlaceHolder: React.FC<{
  config: EditAsideWebFacets;
}> = ({ config }) => {
  const href = useEditHref();

  const typeInfo = useTypedSelector((state) => selectAppliedNodeConfig(state, href).information);

  return (
    <div className={`form-group`}>
      <label className="control-label">{config.label}</label>
      <div className={`error-message type-warning`}>
        <div className="error-message-content">
          <span>
            Er zijn geen facetten voor {typeInfo.definiteArticle ? 'dit' : 'deze'}{' '}
            {typeInfo.single.toLowerCase()}
          </span>
        </div>
      </div>
    </div>
  );
};

AsideWebFacetPlaceHolder.displayName = 'AsideWebFacetPlaceHolder';

export default AsideWebFacetPlaceHolder;

AsideWebFacetPlaceHolder.propTypes = {
  config: any,
};
