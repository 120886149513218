import { isRequiresRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

export const selectDevelopmentPhaseOptions = createTypedSelector(
  [(state) => state.referenceFrames[referenceFrames.ontwikkelingsfasesAN]?.content],
  (referenceFrameContent) => {
    if (!referenceFrameContent) {
      return [];
    }

    return Object.values(referenceFrameContent).flatMap((c) =>
      c.type === 'THEME'
        ? {
            href: c.$$meta.permalink,
            name: c.title,
          }
        : []
    );
  }
);

export const selectChangeMessageForDevelopmentPhase: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectDevelopmentPhaseOptions(state),
      (state, href) => href,
    ],
    (relationsMap, rawRelationsMap, options, href): string | null => {
      const currentRelation = relationsMap.from[href]?.find(isRequiresRelation);
      const originalRelation = rawRelationsMap.from[href]?.find(isRequiresRelation);

      if (currentRelation?.to.href === originalRelation?.to.href) {
        return null;
      }

      let to: string | null = null;
      if (currentRelation) {
        const currentOption = options.find((v) => v.href === currentRelation.to.href);
        if (currentOption) {
          to = currentOption.name as string;
        }
      }

      let from: string | null = null;
      if (originalRelation) {
        const originalOption = options.find((v) => v.href === originalRelation.to.href);
        if (originalOption) {
          from = originalOption.name as string;
        }
      }

      return stringDiffArrowFunction(to, from);
    }
  );
