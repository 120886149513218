import { nanoid } from 'nanoid';
import React, { useRef } from 'react';

interface SimpleCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  readOnly: boolean;
}

const SimpleCheckbox: React.FC<SimpleCheckboxProps> = ({ label, checked, onChange, readOnly }) => {
  const change = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  const inputIdRef = useRef(`aside-checkbox-${nanoid()}`);
  const inputId = inputIdRef.current;

  return (
    <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
      <label className="control-label" htmlFor={inputId} style={{ width: 'auto' }}>
        <input
          type="checkbox"
          id={inputId}
          style={{ marginRight: '1rem' }}
          checked={checked}
          disabled={readOnly}
          onChange={change}
        />
        {label}
      </label>
    </div>
  );
};

export default SimpleCheckbox;
