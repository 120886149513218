import { selectImageNode } from '@newStore/documentUI/transformContent/imageSelectors';
import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { imageComponent } from '@nodeTypeConfig/helpers/editComponents/imageComponent';

const IMAGE: BuildingBlockNodeConfig = {
  nodeSelector: selectImageNode,
  information: {
    single: 'Afbeelding',
    plural: 'Afbeeldingen',
    ribonIcon: require('../../../../img/buildingBlocks/IMAGE.svg'),
  },

  buildingBlocks: [],
  edit: [
    {
      ...imageComponent,
      required: RequiredType.ERROR,
      options: {
        ...imageComponent.options,
        fields: ['description', 'alt'],
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default IMAGE;
