import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectApiWithPendingChangesOldSlice,
  selectContentItem,
  selectPathToRoot,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { EditComponent, EditAsideNamedSet } from '@nodeTypeConfig/configTypes';
import { get } from 'lodash';

export const selectCurrentEditingNode = (state: RootState) =>
  selectContentItem(state, state.documentUI.currentEditingNode as ContentHref);

export const GoalRelationsSelector = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesOldSlice(state),
    (state) => state.document.viewModel?.aside?.editDocument?.key,
  ],
  (api, documentKey) => {
    const relations = api?.contentRelations?.from[`/content/${documentKey}`] ?? [];
    return relations;
  }
);

export const selectOptionsForAsideReferenceFrames = createTypedSelector(
  [
    (state, href) => state.referenceFrames[href]?.content,
    (state, href, typesToFilter: ContentType[]) => typesToFilter,
  ],
  (referenceFrameContent, typesToFilter) => {
    if (!referenceFrameContent) {
      return [];
    }
    return Object.values(referenceFrameContent).filter((option) =>
      typesToFilter.includes(option.type)
    );
  }
);

const emptyArr = [];

export const selectInheritedPropertyValue = createTypedSelector(
  [
    (state, href: ContentHref) => selectPathToRoot(state, href),
    (state, href: ContentHref, config: EditComponent) =>
      config.property as EditAsideNamedSet['property'],
  ],
  (pathToRoot, property) => {
    // the first item is the current node, so we skip it with slice
    return (
      get(
        pathToRoot.slice(1).find((node) => get(node, property)),
        property
      ) || emptyArr
    );
  }
);

export const selectCurrentOrInheritedPropertyValue = createTypedSelector(
  [
    (state, _href: ContentHref, config: EditComponent) =>
      'property' in config && selectCurrentEditingNode(state)?.[config.property],
    (state, href: ContentHref, config: EditComponent) =>
      selectInheritedPropertyValue(state, href, config),
  ],
  (currentValue, inheritedValue) => {
    // the value is either the currentValue of the editingNode, or the value of one of it parents (traversing up the tree)
    return currentValue || inheritedValue;
  }
);
