import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proDocumentStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proDocumentStandardComponents';
import { proThemeTocTypes } from '@nodeTypeConfig/helpers/buildingBlocks/proThemeTocTypes';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';

import { proThemeDefaultValidationRules } from '../proGenericConfigProps';

// Extends WEBPAGE2 => When adding STATIC WebConfig to a WEBPAGE2 it becomes a PRO_STATIC_PAGE
export const PRO_STATIC_PAGE: ExtendedRootNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: proThemeDefaultValidationRules,
  information: {
    description:
      'Pro Static Page (webTemplate code STATIC) can only be a root root (single page pro theme). This is not really a structured document because it has mainly one big node with static html.',
    single: 'PRO.-Thema: Statische pagina',
    plural: "Statische pagina's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  buildingBlocks: proDefaultBuildingBlocks,
  edit: [...proDocumentStandardComponents, ...proLinksAndMenuReferences],
  addAccessRightsToAudienceTab: true,
};
