import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { extendsSection } from '../../helpers/genericConfigHelpers';
// Extends SECTION => A SECTION inside the PRO_HOME_AGE becomes a PRO_MENU_SECTION
const PRO_MENU_SECTION: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Menu Item',
    plural: 'Menu Items',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
    {
      component: 'menuItemReference',
      label: 'Navigeert naar',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [NodeType.WEBPAGE2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
    },
  ],
};

export default PRO_MENU_SECTION;
