import { Content, ContentHref, isVideoAttachmentMetaInfo } from '@generalTypes/apiTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { getEmbedVideoLinkFrom } from '@store/helpers/videoHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { VideoNode } from '../documentUITypes';

export const selectVideoNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): VideoNode => {
    const playbackUrl = content.attachments?.find(isVideoAttachmentMetaInfo)?.playbackUrl;
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type: NodeType.VIDEO,
      videoSrc: playbackUrl && getEmbedVideoLinkFrom(playbackUrl),
      customRender: true,
    };
  }
);
