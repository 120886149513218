import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import constants from '../../../../reduxLoop/constants/constants';
import { leerplanSitesThemeComponents } from './leerplansitesGenericConfigProps';

export const SHARED_FAQ: RootNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [selectCurriculumThemesValidation],
  information: {
    definiteArticle: false,
    single: 'Gedeelde FAQ (leerplansites so)',
    plural: 'Gedeelde FAQ (leerplansites so)',
    category: 'LEERPLANSITES_SO',
  },
  onLoadActions: [loadReferenceFrame({ referenceFrame: referenceFrames.pro })],
  tocTypes: [],
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
  ],
  edit: [
    {
      ...titleComponent,
      label: 'Vraag',
    },
    {
      ...authorsComponent,
      options: {
        queryParams: constants.allAuthorsParameters,
      },
    },
    publishComponent,
    ...leerplanSitesThemeComponents,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: 'Categorie FAQ-groep',
      options: {
        referenceFrameHref: `/content/${constants.faqCategoriesKey}` as ContentHref,
        types: [ContentType.THEME],
      },
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
  ],
  audienceTab: proThemeAudienceTab,
  createModal: {
    title: {
      label: 'Vraag',
    },
    authors: {
      addUser: false,
      queryParams: constants.allAuthorsParameters,
    },
  },
  isCreatable: true,
  isCloneable: true,
  isSearchable: true,
  allowSuggestions: true,
};
