import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ValidationDataResult, ValidationDataState, ValidationRuleName } from './validationTypes';

const initialState: ValidationDataState = {};

export type ValidationPayload<T extends ValidationRuleName> = {
  href: string;
  validationRuleName: T;
  data: ValidationDataResult<T>;
};

const validationDataSlice = createSlice({
  name: 'validationData',
  initialState,
  reducers: {
    loadAllValidation: () => {
      return {};
    },
    loadValidationDataForBatch(
      state,
      action: PayloadAction<{ items: { href: string; validationRuleName: ValidationRuleName }[] }>
    ) {
      const { items } = action.payload;

      items.forEach(({ href, validationRuleName }) => {
        if (!state[href]) {
          state[href] = {};
        }
        if (!state[href][validationRuleName]) {
          state[href][validationRuleName] = {
            isLoading: true,
            data: [],
          };
        }
      });
    },
    setValidationDataFromBatch: <T extends ValidationRuleName>(
      state,
      action: PayloadAction<ValidationPayload<T>[]>
    ) => {
      const validationBatchResults = action.payload;
      validationBatchResults.forEach(({ href, validationRuleName, data }) => {
        state[href][validationRuleName] = {
          isLoading: false,
          data,
        };
      });
    },
    invalidateData: (
      state,
      action: PayloadAction<
        Array<{
          href: string;
          validationRuleName: ValidationRuleName;
        }>
      >
    ) => {
      const toInvalidate = action.payload;
      toInvalidate.forEach(({ href, validationRuleName }) => {
        if (state[href] && state[href][validationRuleName]) {
          delete state[href]?.[validationRuleName];
        }
      });
    },
  },
});

export const {
  loadAllValidation,
  loadValidationDataForBatch,
  setValidationDataFromBatch,
  invalidateData,
} = validationDataSlice.actions;

export default validationDataSlice;
