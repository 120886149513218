import { EditAsideImage } from '@nodeTypeConfig/configTypes';
import { imageComponentBase } from './imageComponent';

export const proThumbnailComponent: EditAsideImage = {
  ...imageComponentBase,
  label: 'Afbeelding',
  options: {
    type: 'THUMBNAIL',
    crop: {
      aspectRatio: 4 / 3,
      resize: {
        width: 710,
        height: 533,
        quality: 0.6,
      },
    },
    fields: ['alt'],
  },
};
