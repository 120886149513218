import { Content, ContentTag, ContentType, WebPage, WebTemplateHref } from '@generalTypes/apiTypes';
import isMatch from 'lodash/isMatch';
import { NodeType } from './configTypes';

const proThemeNode = {
  node: {
    type: ContentType.STRUCTURED_DOCUMENT,
    tags: ['WEBPAGE2' as ContentTag],
  },
} as const;

const sectionNode = {
  node: {
    type: ContentType.SECTION,
  },
} as const;

export const nodeTypeDefinitions = {
  // PRO Menu (technically also a Pro Theme because it is tag WEBPAGE2, but actually the Pro Menu which is not a Pro Theme Page)
  PRO_HOME_PAGE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/980e5264-e611-4ced-bf41-181ef7c81f2c' as WebTemplateHref,
  },
  PRO_MENU_LEVEL_2: {
    node: {
      type: ContentType.SECTION,
    },
    webTemplateHref: '/web/templates/974f629a-7905-4d56-af5f-31a4f003d6f5' as WebTemplateHref,
  },
  PRO_MENU_LEVEL_3: {
    node: {
      type: ContentType.SECTION,
    },
    webTemplateHref: '/web/templates/3df7d5e7-2188-4c11-88bb-55d67811c0ea' as WebTemplateHref,
  },
  PRO_MENU_SECTION: {
    node: {
      type: ContentType.SECTION,
    },
    documentTypes: [NodeType.PRO_HOME_PAGE],
  },
  // PRO Theme
  PRO_THEME_HOME_FULL: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/1a909cc2-b861-4081-b8bf-bbf7da48778e' as WebTemplateHref,
  },
  PRO_THEME_HOME_TEXT: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/1d45237b-6484-4119-a485-ef0015c702a1' as WebTemplateHref,
  },
  PRO_THEME_HOME_PICTURE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/dc53d1ff-d84e-461a-8aea-932c5dd9c69b' as WebTemplateHref,
  },
  PRO_TEXT_PAGE_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/3e09d09a-11b6-4bfe-a20d-62223633b09b' as WebTemplateHref,
  },
  PRO_TEXT_PAGE_STANDALONE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/3e09d09a-11b6-4bfe-a20d-62223633b09b' as WebTemplateHref,
  },
  PRO_DATABASE_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/34371fa8-c06b-4ace-83ad-180e5cd0b50b' as WebTemplateHref,
  },
  PRO_DATABASE_STANDALONE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/34371fa8-c06b-4ace-83ad-180e5cd0b50b' as WebTemplateHref,
  },
  PRO_DATABASE_ITEM: {
    ...sectionNode,
    webTemplateHref: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a' as WebTemplateHref,
  },
  PRO_BLOG_STANDALONE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/c5ef8e93-4e68-44e7-8149-cf4ca61c4e72' as WebTemplateHref,
  },
  PRO_BLOG_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/c5ef8e93-4e68-44e7-8149-cf4ca61c4e72' as WebTemplateHref,
  },
  PRO_BLOG_ITEM: {
    ...sectionNode,
    webTemplateHref: '/web/templates/35213fa8-b26b-7ace-92ad-180e5cd0a37c' as WebTemplateHref,
  },
  PRO_DOWNLOAD_PAGE_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/6a090cca-c380-4248-8722-2951431018a9' as WebTemplateHref,
  },
  PRO_DOWNLOAD_PAGE_STANDALONE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/6a090cca-c380-4248-8722-2951431018a9' as WebTemplateHref,
  },
  PRO_FAQ_PAGE_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/f309b530-72b9-4546-beb6-e912fff16426' as WebTemplateHref,
  },
  PRO_CURRICULUM_PAGE_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/93a15016-3983-4175-8804-c8f2a2d8134a' as WebTemplateHref,
  },
  PRO_TEMPORARY_PAGE_TILE: {
    ...sectionNode,
    webTemplateHref: '/web/templates/263342e7-58a0-42d7-94fa-8f353b46e772' as WebTemplateHref,
  },
  PRO_TEMPORARY_PAGE_STANDALONE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/263342e7-58a0-42d7-94fa-8f353b46e772' as WebTemplateHref,
  },
  PRO_GLOBAL_DATABASE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/0d4092bf-584b-461c-a723-b031b3633e18' as WebTemplateHref,
  },
  PRO_GLOBAL_DATABASE_SECTION: { ...sectionNode, documentTypes: [NodeType.PRO_GLOBAL_DATABASE] },
  PRO_STATIC_PAGE: {
    ...proThemeNode,
    webTemplateHref: '/web/templates/a623e1c1-81a3-4de7-a231-281da633a9cc' as WebTemplateHref,
  },
  PRO_THEME_SECTION: {
    ...sectionNode,
    documentTypes: [
      NodeType.WEBPAGE2,
      NodeType.PRO_THEME_HOME_FULL,
      NodeType.PRO_THEME_HOME_PICTURE,
      NodeType.PRO_THEME_HOME_TEXT,
      NodeType.PRO_TEXT_PAGE_STANDALONE,
      NodeType.PRO_BLOG_STANDALONE,
      NodeType.PRO_DATABASE_STANDALONE,
      NodeType.PRO_DOWNLOAD_PAGE_STANDALONE,
      NodeType.PRO_TEMPORARY_PAGE_STANDALONE,
      NodeType.PRO_STATIC_PAGE,
      NodeType.SHARED_MINI_DATABASE_ITEM,
    ],
  },
  WEBPAGE2: { ...proThemeNode }, // generic Pro Theme
  // Pro leerplansites so
  SHARED_MINI_DATABASE_ITEM: {
    node: {
      type: ContentType.SHARED_MINI_DATABASE_ITEM,
      tags: ['SHARED_MINI_DATABASE_ITEM'],
    },
  },
  SHARED_FAQ: {
    node: {
      type: ContentType.SHARED_FAQ,
      tags: ['SHARED_FAQ'],
    },
  },
  SHARED_ATTACHMENTS_GROUP: {
    node: {
      type: ContentType.SHARED_ATTACHMENTS_GROUP,
      tags: ['SHARED_ATTACHMENTS_GROUP'],
    },
  },
  // Pro Newsletter
  TEASER: {
    node: {
      type: ContentType.TEASER,
      tags: ['TEASER'],
    },
  },
  PRONEWSITEM: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['PRONEWSITEM'],
    },
  },
  PRONEWSLETTER: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['PRONEWSLETTER'],
    },
  },
  PRONEWSLETTERTEMPLATE: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['PRONEWSLETTERTEMPLATE'],
    },
  },
  PRO_NEWSLETTER_SECTION: {
    ...sectionNode,
    documentTypes: [NodeType.PRONEWSLETTER, NodeType.PRONEWSLETTERTEMPLATE],
  },

  // WWW
  WWW_STATIC_PAGE: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['WWW_CONTENT'],
    },
    webTemplateHref: '/web/templates/18fac7d8-a1c3-4a11-bfaa-125bbc44ed7a' as WebTemplateHref,
  },
  WWW: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['WWW_CONTENT'],
    },
  },
  WWWNEWSITEM: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['NEWS_ITEM'],
    },
  },
  JOB_OFFER: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['JOB_OFFER'],
    },
  },
  PRESS: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['PRESS_RELEASE'],
    },
  },

  // ZILL
  CURRICULUM_ZILL: {
    node: {
      type: ContentType.CURRICULUM_ZILL,
      tags: ['CURRICULUM'],
    },
  },
  CURRICULUM_ZILL_CLUSTER: {
    node: {
      type: ContentType.CURRICULUM_ZILL_CLUSTER,
    },
  },
  CURRICULUM_ZILL_DEVELOPMENT_FIELD: {
    node: {
      type: ContentType.CURRICULUM_ZILL_DEVELOPMENT_FIELD,
    },
  },
  CURRICULUM_ZILL_DEVELOPMENT_THEME: {
    node: {
      type: ContentType.CURRICULUM_ZILL_DEVELOPMENT_THEME,
    },
  },
  CURRICULUM_ZILL_GENERIC_GOAL: {
    node: {
      type: ContentType.CURRICULUM_ZILL_GENERIC_GOAL,
    },
  },
  CURRICULUM_ZILL_CONTENT_GROUP: {
    node: {
      type: ContentType.CURRICULUM_ZILL_CONTENT_GROUP,
    },
  },
  CURRICULUM_ZILL_DEVELOPMENT_CONTENT: {
    node: {
      type: ContentType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT,
    },
  },
  CURRICULUM_ZILL_LEERLIJN: {
    node: {
      type: ContentType.CURRICULUM_ZILL_LEERLIJN,
      specificForAN: false,
    },
  },
  CURRICULUM_ZILL_LEERLIJN_AN: {
    node: {
      type: ContentType.CURRICULUM_ZILL_LEERLIJN,
      specificForAN: true,
    },
  },
  CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE: {
    node: {
      type: ContentType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE,
    },
  },
  CURRICULUM_ZILL_LEERLIJN_CLUSTER: {
    node: {
      type: ContentType.CURRICULUM_ZILL_LEERLIJN_CLUSTER,
    },
  },
  CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE: {
    node: {
      type: ContentType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE,
    },
  },
  ZILL_ILLUSTRATION: {
    node: {
      type: ContentType.ZILL_ILLUSTRATION,
      tags: ['ZILL_ILLUSTRATION'],
    },
  },
  PRACTICAL_EXAMPLE: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['PRACTICAL_EXAMPLE'],
    },
  },
  WEBPAGE: {
    node: {
      type: ContentType.WEBPAGE,
      tags: ['WEBPAGE'],
    },
  },

  // ODET
  CURRICULUM_ODET: {
    node: {
      type: ContentType.CURRICULUM_ODET,
      tags: ['CURRICULUM'],
    },
  },
  CURRICULUM_ODET_LEVEL: {
    node: {
      type: ContentType.CURRICULUM_ODET_LEVEL,
    },
  },
  CURRICULUM_ODET_LEERGEBIED: {
    node: {
      type: ContentType.CURRICULUM_ODET_LEERGEBIED,
    },
  },
  CURRICULUM_ODET_KEY_COMPETENCE: {
    node: {
      type: ContentType.CURRICULUM_ODET_KEY_COMPETENCE,
    },
  },
  CURRICULUM_ODET_SUBSECTION: {
    node: {
      type: ContentType.CURRICULUM_ODET_SUBSECTION,
    },
  },
  CURRICULUM_ODET_DEVELOPMENT_GOAL: {
    node: {
      type: ContentType.CURRICULUM_ODET_DEVELOPMENT_GOAL,
    },
  },
  CURRICULUM_ODET_ENDTERM: {
    node: {
      type: ContentType.CURRICULUM_ODET_ENDTERM,
    },
  },

  // LLINKID
  LLINKID_CURRICULUM: { node: { type: ContentType.LLINKID_CURRICULUM, foundational: false } },
  LLINKID_FOUNDATIONAL_CURRICULUM: {
    node: { type: ContentType.LLINKID_CURRICULUM, foundational: true },
  },
  LLINKID_GOAL_LIST: {
    node: {
      type: ContentType.LLINKID_GOAL_LIST,
    },
  },
  LLINKID_GOAL_SECTION: {
    node: {
      type: ContentType.LLINKID_GOAL_SECTION,
    },
  },
  LLINKID_GOAL: {
    node: {
      type: ContentType.LLINKID_GOAL,
    },
  },
  LLINKID_GOAL_DEMARCATION: {
    node: {
      type: ContentType.LLINKID_GOAL_DEMARCATION,
    },
  },
  LLINKID_PEDAGOGICAL_TIP: {
    node: {
      type: ContentType.LLINKID_PEDAGOGICAL_TIP,
    },
  },
  LLINKID_EXTRA_GOAL_INFORMATION: {
    node: {
      type: ContentType.LLINKID_EXTRA_GOAL_INFORMATION,
    },
  },
  LLINKID_GOAL_EXPLANATION: {
    node: {
      type: ContentType.LLINKID_GOAL_EXPLANATION,
    },
  },
  LLINKID_INITIAL_SITUATION: {
    node: {
      type: ContentType.LLINKID_INITIAL_SITUATION,
    },
  },
  LLINKID_GUIDING_PRINCIPLE_GROUP: {
    node: {
      type: ContentType.LLINKID_GUIDING_PRINCIPLE_GROUP,
    },
  },
  LLINKID_GUIDING_PRINCIPLE: {
    node: {
      type: ContentType.LLINKID_GUIDING_PRINCIPLE,
    },
  },
  LLINKID_STATIC_PART: {
    node: {
      type: ContentType.LLINKID_STATIC_PART,
    },
  },
  LLINKID_CONTEXT: {
    node: {
      type: ContentType.LLINKID_CONTEXT,
    },
  },
  LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT: {
    node: {
      type: ContentType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
    },
  },
  LLINKID_BASIC_MATERIAL_REQUIREMENTS: {
    node: {
      type: ContentType.LLINKID_BASIC_MATERIAL_REQUIREMENTS,
    },
  },
  LLINKID_CORRELATION_ENDTERMS: {
    node: {
      type: ContentType.LLINKID_CORRELATION_ENDTERMS,
    },
  },
  LLINKID_CONCORDANCE: {
    node: {
      type: ContentType.LLINKID_CONCORDANCE,
    },
  },
  LLINKID_CONCRETIZATION: {
    node: {
      type: ContentType.LLINKID_CONCRETIZATION,
    },
  },

  // Other generic root documents
  VISION_TEXT: {
    node: {
      type: ContentType.STRUCTURED_DOCUMENT,
      tags: ['VISION_TEXT'],
    },
  },
  VISION_TEXT_SECTION: { ...sectionNode, documentTypes: [NodeType.VISION_TEXT] },
  TERM: {
    node: {
      type: ContentType.TERM,
      tags: ['TERM'],
    },
  },
  MARK_EXPLANATION: {
    node: {
      type: ContentType.MARK_EXPLANATION,
      tags: ['MARK_EXPLANATION'],
    },
  },
  GLOBAL_DOCUMENT: {
    node: {
      type: ContentType.UNSTRUCTURED_DOCUMENT,
      tags: ['PRO_UNSTRUCTURED_DOCUMENT'],
    },
  },

  // "Themes": Reference frames (ordeningskaders)
  REFERENCE_FRAME: {
    node: {
      type: ContentType.REFERENCE_FRAME,
    },
  },
  THEME: {
    node: {
      type: ContentType.THEME,
    },
  },
  CURRICULUM_CLUSTER: {
    node: {
      type: ContentType.CURRICULUM_CLUSTER,
    },
  },
  CURRICULUM_THEME: {
    node: {
      type: ContentType.CURRICULUM_THEME,
    },
  },

  // BUILDING BLOCKS (need to be at the bottom, buildingBlocks can be overwritten with a more specific definition)
  REFERENCE_GROUP: { node: { type: ContentType.REFERENCE_GROUP } },
  REFERENCE: {
    node: {
      type: ContentType.REFERENCE,
    },
  },
  SECTION: { ...sectionNode },
  PARAGRAPH: {
    node: {
      type: ContentType.PARAGRAPH,
    },
  },
  IMAGE: {
    node: {
      type: ContentType.IMAGE,
    },
  },
  IMAGE_GROUP: {
    node: {
      type: ContentType.IMAGE_GROUP,
    },
  },
  VIDEO: {
    node: {
      type: ContentType.VIDEO,
    },
  },
  VIDEO_GROUP: {
    node: {
      type: ContentType.VIDEO_GROUP,
    },
  },
  EXAMPLE: {
    node: {
      type: ContentType.EXAMPLE,
    },
  },
  ATTACHMENTS_GROUP: {
    node: {
      type: ContentType.ATTACHMENTS_GROUP,
      tags: ['INLINE_ATTACHMENTS_GROUP'], // vs PAGE_ATTACHMENTS_GROUP -> used in redactie
      // importance: 'MEDIUM', // in Pro they filter on importance high to not show attachments INLINE so it can not be high but either LOW or MEDIUM
    },
  },
  ATTACHMENT: {
    node: {
      type: ContentType.ATTACHMENT,
    },
  },
  MUSIC: {
    node: {
      type: ContentType.MUSIC,
    },
  },
  QUOTE: {
    node: {
      type: ContentType.QUOTE,
    },
  },
  FAQ: {
    node: {
      type: ContentType.FAQ,
    },
  },
  FAQ_GROUP: {
    node: {
      type: ContentType.FAQ_GROUP,
    },
  },
  SUMMARY: {
    node: {
      type: ContentType.SUMMARY,
    },
  },
  LEGAL: {
    node: {
      type: ContentType.LEGAL,
    },
  },
  // WORD_IMPORT: {
  //   node: null,
  //   /* node: {
  //     type: NodeType.WORD_IMPORT, // @Stefan can this go? Does not make sense to try to match on a node with type WORD_IMPORT
  //   }, */
  // }, // Not really a node type but only a building block
  // nodes only managable in aside
  SOURCE: {
    node: {
      type: ContentType.SOURCE,
    },
  },
  LINK_GROUP: {
    node: {
      type: ContentType.LINK_GROUP,
    },
  },
  PAGE_ATTACHMENTS_GROUP: {
    node: {
      type: ContentType.ATTACHMENTS_GROUP,
      tags: ['PAGE_ATTACHMENTS_GROUP'], // redactie uses this tag to distinguish them
      importance: 'HIGH', // pro matches on importance == high to distinguish them
    },
  },
};

/**
 * For the old vm and the aside which is still used
 * We will make sure we keep returning the same old types
 */

// TODO: getNodeType is called like 100k times in a document like Leeftocht.
// once the refactor is close to done and the old VM is not used, check if we can improve performance by using a selector selectTypeForContent for the new VM
export const getOldNodeType = (node): NodeType => {
  for (const [nodeType, nodeTypeDef] of Object.entries(nodeTypeDefinitions)) {
    if (
      nodeTypeDef.node &&
      !('webTemplateHref' in nodeTypeDef) &&
      !('documentTypes' in nodeTypeDef) &&
      isMatch(node, nodeTypeDef.node)
    ) {
      if (nodeType === NodeType.PAGE_ATTACHMENTS_GROUP) {
        // introduced in refactor but to be sure use the old one in old code
        return NodeType.ATTACHMENTS_GROUP;
      }
      if (nodeType === NodeType.LLINKID_FOUNDATIONAL_CURRICULUM) {
        // introduced in refactor but to be sure use the old one in old code
        return NodeType.LLINKID_CURRICULUM;
      }
      return nodeType as NodeType;
    }
  }
  console.log('[getOldNodeType] NodeType could not be found for ', node);
  throw new Error(`[getOldNodeType] Type not found for: ${node.$$meta.permalink}`);
};

export const getNodeType = (
  node: Content,
  webPageThatMightBeDeleted: WebPage | undefined,
  documentType: NodeType | null
): NodeType => {
  const webPage =
    !node.$$meta.deleted && webPageThatMightBeDeleted?.$$meta.deleted
      ? undefined
      : webPageThatMightBeDeleted;
  for (const [nodeType, nodeTypeDef] of Object.entries(nodeTypeDefinitions)) {
    const isWebPageMatch =
      (!webPage && !('webTemplateHref' in nodeTypeDef)) || // if no webPage is given the configType should not specify a web template
      (webPage &&
        'webTemplateHref' in nodeTypeDef &&
        nodeTypeDef.webTemplateHref === webPage.template.href);
    const isDocumentTypeMatch =
      !('documentTypes' in nodeTypeDef) ||
      (documentType && nodeTypeDef.documentTypes.includes(documentType));

    if (
      isWebPageMatch &&
      isDocumentTypeMatch &&
      nodeTypeDef.node &&
      isMatch(node, nodeTypeDef.node)
    ) {
      return nodeType as NodeType;
    }
  }
  if (webPage) {
    // if match could not be made with webPage try without for more generic type (for example CURRICULA_NAVIGATION can just be a WEBPAGE2)
    return getNodeType(node, undefined, documentType);
  }
  console.log('[getNodeType] NodeType could not be found for ', node);
  throw new Error(`[getNodeType] Type not found for: ${node.$$meta.permalink}`);
};

// call this if you explecitely know that you don't want a specific NodeType
// from list screen or for the onLoadActions
export const getGenericNodeType = (node) => getNodeType(node, undefined, null);
