import { NodeType, NodeTypeSelectors } from './configTypes';

/**
 * Configuration Map that defines which selectors are used for each NodeType
 * To avoid circular dependency cycles configuration this is in a separate file
 * because selector files typically need the NodeTypeConfiguration,
 * so if that would include selectors you would have a cycle
 */
const nodeTypeSelectorsMap: NodeTypeSelectors = {
  // Pro Themes and Pro Menu
  [NodeType.WEBPAGE2]: {},
  [NodeType.PRO_THEME_HOME_FULL]: {},
  [NodeType.PRO_THEME_HOME_TEXT]: {},
  [NodeType.PRO_THEME_HOME_PICTURE]: {},
  [NodeType.PRO_TEXT_PAGE_TILE]: {},
  [NodeType.PRO_TEXT_PAGE_STANDALONE]: {},
  [NodeType.PRO_DATABASE_TILE]: {},
  [NodeType.PRO_DATABASE_STANDALONE]: {},
  [NodeType.PRO_DATABASE_ITEM]: {},
  [NodeType.PRO_BLOG_TILE]: {},
  [NodeType.PRO_BLOG_STANDALONE]: {},
  [NodeType.PRO_BLOG_ITEM]: {},
  [NodeType.PRO_DOWNLOAD_PAGE_TILE]: {},
  [NodeType.PRO_DOWNLOAD_PAGE_STANDALONE]: {},
  [NodeType.PRO_FAQ_PAGE_TILE]: {},
  [NodeType.PRO_CURRICULUM_PAGE_TILE]: {},
  [NodeType.PRO_TEMPORARY_PAGE_TILE]: {},
  [NodeType.PRO_TEMPORARY_PAGE_STANDALONE]: {},
  [NodeType.PRO_STATIC_PAGE]: {},
  [NodeType.PRO_GLOBAL_DATABASE]: {},
  [NodeType.PRO_GLOBAL_DATABASE_SECTION]: {},
  [NodeType.PRO_THEME_SECTION]: {},
  // Pro Menu
  [NodeType.PRO_HOME_PAGE]: {},
  [NodeType.PRO_MENU_LEVEL_2]: {},
  [NodeType.PRO_MENU_LEVEL_3]: {},
  [NodeType.PRO_MENU_SECTION]: {},
  // Pro News
  [NodeType.TEASER]: {},
  [NodeType.PRONEWSITEM]: {},
  [NodeType.PRONEWSLETTER]: {},
  [NodeType.PRONEWSLETTERTEMPLATE]: {},
  [NodeType.PRO_NEWSLETTER_SECTION]: {},
  // Leerplansites
  [NodeType.SHARED_MINI_DATABASE_ITEM]: {},
  [NodeType.SHARED_FAQ]: {},
  [NodeType.SHARED_ATTACHMENTS_GROUP]: {},
  // www
  [NodeType.WWW_STATIC_PAGE]: {},
  [NodeType.WWW]: {},
  [NodeType.WWWNEWSITEM]: {},
  [NodeType.JOB_OFFER]: {},
  [NodeType.PRESS]: {},
  // LLINKID
  [NodeType.LLINKID_CURRICULUM]: {},
  [NodeType.LLINKID_FOUNDATIONAL_CURRICULUM]: {},
  [NodeType.LLINKID_GOAL_LIST]: {},
  [NodeType.LLINKID_GOAL_SECTION]: {},
  [NodeType.LLINKID_GOAL]: {},
  [NodeType.LLINKID_GOAL_DEMARCATION]: {},
  [NodeType.LLINKID_PEDAGOGICAL_TIP]: {},
  [NodeType.LLINKID_EXTRA_GOAL_INFORMATION]: {},
  [NodeType.LLINKID_GOAL_EXPLANATION]: {},
  [NodeType.LLINKID_INITIAL_SITUATION]: {},
  [NodeType.LLINKID_GUIDING_PRINCIPLE_GROUP]: {},
  [NodeType.LLINKID_GUIDING_PRINCIPLE]: {},
  [NodeType.LLINKID_STATIC_PART]: {},
  [NodeType.LLINKID_CONTEXT]: {},
  [NodeType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT]: {},
  [NodeType.LLINKID_BASIC_MATERIAL_REQUIREMENTS]: {},
  [NodeType.LLINKID_CORRELATION_ENDTERMS]: {},
  [NodeType.LLINKID_CONCORDANCE]: {},
  [NodeType.LLINKID_CONCRETIZATION]: {},
  // Zill
  [NodeType.CURRICULUM_ZILL]: {},
  [NodeType.CURRICULUM_ZILL_CLUSTER]: {},
  [NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD]: {},
  [NodeType.CURRICULUM_ZILL_DEVELOPMENT_THEME]: {},
  [NodeType.CURRICULUM_ZILL_GENERIC_GOAL]: {},
  [NodeType.CURRICULUM_ZILL_CONTENT_GROUP]: {},
  [NodeType.CURRICULUM_ZILL_LEERLIJN]: {},
  [NodeType.CURRICULUM_ZILL_LEERLIJN_AN]: {},
  [NodeType.CURRICULUM_ZILL_DEVELOPMENT_CONTENT]: {},
  [NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER]: {},
  [NodeType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE]: {},
  [NodeType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE]: {},
  [NodeType.ZILL_ILLUSTRATION]: {},
  [NodeType.PRACTICAL_EXAMPLE]: {},
  [NodeType.WEBPAGE]: {},
  // ODET
  [NodeType.CURRICULUM_ODET]: {},
  [NodeType.CURRICULUM_ODET_LEVEL]: {},
  [NodeType.CURRICULUM_ODET_LEERGEBIED]: {},
  [NodeType.CURRICULUM_ODET_KEY_COMPETENCE]: {},
  [NodeType.CURRICULUM_ODET_SUBSECTION]: {},
  [NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL]: {},
  [NodeType.CURRICULUM_ODET_ENDTERM]: {},
  // Other generic root documents
  [NodeType.VISION_TEXT]: {},
  [NodeType.VISION_TEXT_SECTION]: {},
  [NodeType.TERM]: {},
  [NodeType.MARK_EXPLANATION]: {},
  [NodeType.GLOBAL_DOCUMENT]: {},
  // Refererence Frames
  [NodeType.REFERENCE_FRAME]: {},
  [NodeType.THEME]: {},
  [NodeType.CURRICULUM_CLUSTER]: {},
  [NodeType.CURRICULUM_THEME]: {},
  // Building blocks
  [NodeType.REFERENCE_GROUP]: {},
  [NodeType.REFERENCE]: {},
  [NodeType.SECTION]: {},
  [NodeType.PARAGRAPH]: {},
  [NodeType.IMAGE]: {},
  [NodeType.IMAGE_GROUP]: {},
  [NodeType.VIDEO]: {},
  [NodeType.VIDEO_GROUP]: {},
  [NodeType.EXAMPLE]: {},
  [NodeType.ATTACHMENT]: {},
  [NodeType.ATTACHMENTS_GROUP]: {},
  [NodeType.MUSIC]: {},
  [NodeType.QUOTE]: {},
  [NodeType.FAQ]: {},
  [NodeType.FAQ_GROUP]: {},
  [NodeType.SUMMARY]: {},
  [NodeType.LEGAL]: {},
  [NodeType.SOURCE]: {},
  [NodeType.LINK_GROUP]: {},
  [NodeType.PAGE_ATTACHMENTS_GROUP]: {},
  [NodeType.WORD_IMPORT]: {},
};

export default nodeTypeSelectorsMap;
