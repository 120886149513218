import { RootState } from '@generalTypes/rootStateTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { selectIsReferenceFrameLoaded } from '@newStore/referenceFrames/referenceFramesSelectors';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { EditAsideCurriculumTheme } from '@nodeTypeConfig/configTypes';
import { patchThemes } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { escapeRegExp } from 'lodash';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { selectAsidePrimaryReferenceFrameOptions } from './asideCurriculumThemeSelectors';

const filterData = ({ data }, str) => {
  if (!str || str.trim().length === 0) {
    return true;
  }
  const regExp = new RegExp(escapeRegExp(str), 'gi');
  return regExp.test(data.title);
};

const AsideCurriculumTheme: React.FC<{
  config: EditAsideCurriculumTheme;
}> = ({ config }) => {
  const editHref = useEditHref();
  const editKey = getResourceKey(editHref);
  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();
  const dispatch = useDispatch();

  const onChange = (item) => {
    dispatch(patchThemes(editKey, [item.$$meta.permalink]));
  };

  const isReferenceFrameLoaded = useSelector((state: RootState) =>
    selectIsReferenceFrameLoaded(state, referenceFrames.pro)
  );

  const primaryReferenceFrameOptions = useSelector((state: RootState) =>
    selectAsidePrimaryReferenceFrameOptions(state)
  );
  const selectedTheme = primaryReferenceFrameOptions?.find(
    (o) => initialValue?.[0] === o.$$meta.permalink
  );

  useEffect(() => {
    if (!isReferenceFrameLoaded) {
      dispatch(loadReferenceFrame({ referenceFrame: referenceFrames.pro }));
    }
  }, [dispatch, isReferenceFrameLoaded]);

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        <label className="control-label">{config.label}</label>
        {config.subLabel && (
          <label className="control-label curriculum-theme-sub-label">{config.subLabel}</label>
        )}
        {!isReferenceFrameLoaded ? (
          <div className="form-group shimmer narrowLine"></div>
        ) : (
          <KovResourcePicker
            value={selectedTheme}
            localData={primaryReferenceFrameOptions}
            clearable={false}
            disabled={readOnly}
            onChange={onChange}
            placeholder="Selecteer een item"
            display="title"
            multiple={false}
            menuPlacement="bottom"
            filter={filterData}
          />
        )}
      </AsideValidation>
    </div>
  );
};

AsideCurriculumTheme.displayName = 'AsideCurriculumTheme';

export default AsideCurriculumTheme;

AsideCurriculumTheme.propTypes = {
  config: any,
};
