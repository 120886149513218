import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { validateDownloadListAndFaqPageShouldNotHaveDescription } from '@newStore/validation/validationRules/downloadPageShouldNotHaveDescription';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proTileStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proTileStandardComponents';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';
import { proTileDefaultValidionRules } from '../proGenericConfigProps';
// Extends SECTION => A SECTION inside a Pro Theme and WebConfig FAQ_PAGE becomes a PRO_FAQ_PAGE_TILE
export const PRO_FAQ_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [
    ...proTileDefaultValidionRules,
    validateDownloadListAndFaqPageShouldNotHaveDescription,
  ],
  information: {
    description:
      'Pro faq page (webTemplate code FAQ_PAGE) can be only be a SECTION (a tile) in a home',
    single: 'PRO.-tegel: Veelgestelde vragen',
    plural: 'Veelgestelde vragen',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: [...proDefaultBuildingBlocks, { type: NodeType.FAQ_GROUP }],
  edit: [
    ...proTileStandardComponents.filter((comp) => comp.property !== 'description'),
    ...proLinksAndMenuReferences,
  ],
  confirmDelete: true,
};
