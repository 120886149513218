import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { ExtendedBuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { importanceComponent } from '@nodeTypeConfig/helpers/editComponents/importanceComponent';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { proWebFacetComponent } from '@nodeTypeConfig/helpers/editComponents/proWebFacetComponent';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proThumbnailComponent } from '@nodeTypeConfig/helpers/editComponents/proThumbnailComponent';
import { proContactsComponent } from '@nodeTypeConfig/helpers/editComponents/proContactsComponent';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';
import { proTileDefaultValidionRules } from '../proGenericConfigProps';
// Extends SECTION => A SECTION inside a Pro Theme and webConfig MINI_DATABASE_ITEM becomes a PRO_DATABASE_ITEM
const PRO_BLOG_ITEM: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: proTileDefaultValidionRules,
  information: {
    definiteArticle: true,
    description: 'A Blog Item, can only appear as child of a blog',
    single: 'Blog item',
    plural: 'Blog Items',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: proDefaultBuildingBlocks,
  edit: [
    {
      ...identifierComponent, // can go if we work on Aside (confirmed by Isabelle)
      label: 'Prefix',
      width: '2',
    },
    {
      ...titleComponent,
      width: '10',
      label: 'Titel',
    },
    {
      ...proThumbnailComponent,
      width: '6',
    },
    {
      ...importanceComponent,
      width: '6',
    },
    {
      ...authorsComponent,
      width: '6',
    },
    {
      ...proContactsComponent,
      width: '12',
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    ...proLinksAndMenuReferences,
    proWebFacetComponent,
  ],
  confirmDelete: true,
};

export default PRO_BLOG_ITEM;
