import { Person } from '@generalTypes/personApiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectOus, selectPersons } from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector, formatDate } from '@newStore/genericHelpers';
import { getAllowedTypes } from '@newStore/user/userHelpers';
import {
  selectAllowedAbilities,
  selectHasUserReviewAbility,
  selectSecurityData,
  selectSecurityUserRoles,
} from '@newStore/user/userSelectors';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { getNodeTypeConfig, getIcon } from '@nodeTypeConfig/index';
import { getGenericNodeType } from '@nodeTypeConfig/nodeTypeConfig';
import { allowVersionItemSelected, isCloneableItemSelected } from '@store/helpers/copyHelpers';
import { isGlobalDocument } from '@store/viewmodels/viewModelHelpers';
import { sortBy } from 'lodash';
import constants from '../../reduxLoop/constants/constants';
import { config as documentTypes } from '../../reduxLoop/constants/documentTypes';
import { allowPublishItemSelected } from '../../reduxLoop/helpers/documentHelpers';
import { getTitle } from '../../services/types';
import { areAllResultsSelected, getSelectedResults } from './newDocumentListHelpers';
import { DocumentListResult } from './newDocumentListTypes';

// Define input selectors so that we can reuse them in the output selectors and they only have to be typed once
const selectDocumentListResults = (state: RootState) => state.newDocumentList.results;
const selectDocumentListSelected = (state: RootState) => state.newDocumentList.selected;

export const selectSearchResults = createTypedSelector(
  [
    (state) => selectDocumentListResults(state),
    (state) => selectPersons(state),
    (state) => selectOus(state),
    (state) => state.newDocumentList.proposalsToReviewCount,
    (state) => selectDocumentListSelected(state),
  ],
  (
    results,
    persons: Record<string, Person>,
    ous: Record<string, any>,
    proposalsToReviewCount,
    selectedItems
  ): DocumentListResult[] => {
    const vmresults = results.map((item) => {
      const $$titleAsHtml: string = getTitle(item, { addIdentifier: true });
      const $$uiType = getGenericNodeType(item);
      const fullTypeConfig = getNodeTypeConfig($$uiType) as RootNodeConfig;
      // trying to avoid sending the whole typeconfig.
      const $$typeConfig = fullTypeConfig && {
        information: fullTypeConfig.information,
        allowSuggestions: fullTypeConfig.allowSuggestions,
      };
      const $$iconSrc: string = getIcon($$uiType);
      const issued = formatDate(item.issued, false);
      const modifiedTZ = item.$$meta.modified;
      const metaModified = formatDate(item.$$meta.modified, false);
      const $$selected = selectedItems[item.$$meta.permalink] || false;
      const authors: any[] = item.creators?.map((z) => persons[z] || ous[z]).filter((z) => z) || [];

      const authorNames = authors
        .map((author) => {
          // We have authors of different types. So, depending on the type, we
          // will get the information from one place or another:
          // - authorItem.$$meta.type === 'ORGANISATIONAL_UNIT' => We get it in `$$displayName`
          // - author.$$meta.type === 'ORGANISATION' => We get it in `$$name`
          // - authorItem.$$meta.type === 'PERSON' => We get it in `firstname` + `lastname`
          return author.$$displayName || author.$$name || `${author.lastName} ${author.firstName}`;
        })
        .join(', ');

      const hasProposalsToReview = proposalsToReviewCount[item.$$meta.permalink] > 0;
      return {
        ...item,
        $$titleAsHtml,
        $$uiType,
        $$typeConfig,
        $$iconSrc,
        issued,
        modifiedTZ,
        $$meta: { ...item.$$meta, modified: metaModified },
        expandedAuthors: authorNames,
        hasProposalsToReview,
        $$selected,
      };
    });

    return vmresults;
  }
);

export const selectAreAllResultsSelected = createTypedSelector(
  [(state) => selectDocumentListResults(state), (state) => selectDocumentListSelected(state)],
  (results, selected) => {
    return areAllResultsSelected(results, selected);
  }
);

export const selectSelectedResults = createTypedSelector(
  [(state) => selectSearchResults(state), (state) => selectDocumentListSelected(state)],
  (results, selected) => {
    return getSelectedResults(results, selected);
  }
);

// legacy viewmodel for document list which is not part of the refactor => we did not type the returned vm because it is still a mess
export const selectUserVmForDocumentList = createTypedSelector(
  [(state) => selectSecurityData(state), (state) => selectAllowedAbilities(state)],
  (securityData: Record<string, string[]> | null, allowedAbilities: string[]) => {
    if (!securityData) {
      return {
        creatables: [],
        searchables: [],
        cloneables: [],
        publishedEditables: [],
        searchCategories: [],
      };
    }
    // const { nodeTypeConfigurations } = require('../../config/nodeTypeConfigurations/index');
    const allowedTypes = getAllowedTypes(securityData);
    const searchableTypes = allowedTypes.filter((t) => t.isSearchable);
    const vm = {
      creatables: sortBy(
        allowedTypes
          .filter((t) => t.isCreatable)
          .map((t) => ({ ...getNodeTypeConfig(t.type), type: t.type })),
        [(o) => o.information.single]
      ),
      searchables: searchableTypes.map((t) => ({
        ...getNodeTypeConfig(t.type),
        type: t.type,
      })),
      cloneables: allowedTypes
        .filter((t) => t.isCloneable)
        .map((t) => ({ ...getNodeTypeConfig(t.type), type: t.type })),
      publishedEditables: allowedTypes
        .filter((t) => t.isCreatable && t.isPublishedEditable)
        .map((t) => ({ ...getNodeTypeConfig(t.type), type: t.type })),
      searchCategories: searchableTypes.reduce(
        (ret: any[], t: any) => {
          const category = ret.find(
            (c) => c.category === t.category || (!t.category && c.category === 'GENERAL')
          );
          if (category) {
            category.searchables.push({ ...getNodeTypeConfig(t.type), type: t.type });
          }
          return ret;
        },
        [
          { category: 'PRO', searchables: [] },
          { category: 'WWW', searchables: [] },
          { category: 'LEERPLAN', searchables: [] },
          { category: 'ZILL', searchables: [] },
          { category: 'LEERPLANSITES_SO', searchables: [] },
          { category: 'GENERAL', searchables: [] },
        ]
      ),
      allowedAbilities,
    };
    console.log('VM for documentList', vm);
    return vm;
  }
);

export const selectAreCloneableItemsSelected = createTypedSelector(
  [
    (state) => selectSelectedResults(state),
    (state) => selectUserVmForDocumentList(state).cloneables,
  ],
  (selectedResults, cloneables) => {
    return isCloneableItemSelected(selectedResults, cloneables);
  }
);

export const selectIsPublishDisabled = createTypedSelector(
  [
    (state) => selectSelectedResults(state),
    (state) => selectUserVmForDocumentList(state).publishedEditables,
  ],
  (selectedResults, publishedEditables) => {
    return (
      selectedResults.length === 0 ||
      selectedResults.length > constants.maxOperationsAllowed ||
      !allowPublishItemSelected(selectedResults, publishedEditables)
    );
  }
);

export const selectIsPublishHidden = createTypedSelector(
  [(state) => selectSelectedResults(state)],
  (selectedResults) => {
    return (
      selectedResults.length === 0 ||
      selectedResults.some((result) => !result.tags?.includes('PRACTICAL_EXAMPLE'))
    );
  }
);

export const selectIsDeleteDisabled = createTypedSelector(
  [(state) => selectSelectedResults(state), (state) => selectSecurityUserRoles(state)],
  (selectedResults, userRoles) => {
    const isNotAllowedToDeleteProTheme =
      selectedResults.some((result) => result.$$uiType === 'WEBPAGE2') &&
      !userRoles.includes('pro_webmaster') &&
      !userRoles.includes('super_user');
    return (
      selectedResults.length === 0 ||
      selectedResults.length > constants.maxOperationsAllowed ||
      selectedResults.some((result) => result.issued) ||
      isNotAllowedToDeleteProTheme
    );
  }
);

export const selectAreVersionableItemsSelected = createTypedSelector(
  [(state) => selectSelectedResults(state)],
  (selectedResults) => {
    return allowVersionItemSelected(selectedResults);
  }
);

export const selectColumnsToShow = createTypedSelector(
  [(state) => selectHasUserReviewAbility(state), (state) => selectSearchResults(state)],
  (hasReviewerRole, results) => {
    const hasAnyElementWithSuggestions = results && results.find((x) => x.hasProposalsToReview);
    const souldShowProposalsLabel = hasAnyElementWithSuggestions && hasReviewerRole;

    return {
      hasSomeProThemaResult: results.some((result) => result.$$uiType === documentTypes.prothema),
      hasAllGlobalDocumentResults: results.every((result) => isGlobalDocument(result)),
      souldShowProposalsLabel,
    };
  }
);

export const selectAuthorHrefsToLoad = createTypedSelector(
  [
    (state) => selectDocumentListResults(state),
    (state) => selectPersons(state),
    (state) => selectOus(state),
  ],
  (results, persons, ous): string[] => {
    const authors = { ...persons, ...ous };
    const hrefs = [
      ...new Set(
        results
          .filter((x) => x.creators)
          .map((x) => x.creators)
          .flat()
          .filter((z) => z && !authors[z])
      ),
    ] as string[];

    return hrefs;
  }
);

export type AuthorHrefsToLoad = ReturnType<typeof selectAuthorHrefsToLoad>;
