import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectValidateAttachmentAudienceParentIntersectionNotEmpty } from '@newStore/validation/validationRules/audienceIntersectionRules';
import { ExtendedBuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { proPageAttachmentsComponents } from '@nodeTypeConfig/helpers/editComponents/proPageAttachmentsComponents';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proTileStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proTileStandardComponents';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';
import { proTileDefaultValidionRules } from '../proGenericConfigProps';

// Extends SECTION => A SECTION inside a Pro Theme with webConfig CURRICULUM_PAGE becomes a PRO_CURRICULUM_PAGE_TILE
export const PRO_CURRICULUM_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: [
    ...proTileDefaultValidionRules,
    selectValidateAttachmentAudienceParentIntersectionNotEmpty,
  ],
  information: {
    description:
      'Pro curriculum page (webTemplate code CURRICULUM_PAGE) can be only be a SECTION (a tile) in a home',
    single: 'PRO.-tegel: Leerplan pagina',
    plural: "Leerplan pagina's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  ...extendsSection,
  isCollapsible: true,
  isCollapsedByDefault: true,
  buildingBlocks: proDefaultBuildingBlocks,
  confirmDelete: true,
  edit: [...proTileStandardComponents, proPageAttachmentsComponents, ...proLinksAndMenuReferences],
};
