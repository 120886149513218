import { ContentType } from '@generalTypes/apiTypes';
import { RootEditComponent } from '@nodeTypeConfig/configTypes';

export const proPageAttachmentsComponents: RootEditComponent = {
  component: 'attachmentsGroup',
  label: 'Bijlagen',
  hiddenChildren: {
    containerType: ContentType.ATTACHMENTS_GROUP,
    items: [
      {
        type: ContentType.ATTACHMENT,
        label: 'bijlage',
      },
      {
        type: ContentType.UNSTRUCTURED_DOCUMENT,
        label: 'gedeeld document',
      },
    ],
  },
  options: { global: true },
};
