import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import constants from '@store/constants/constants';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proAliasesComponent } from '@nodeTypeConfig/helpers/editComponents/proAliasesComponent';
// Extends SECTION => A SECTION inside a Pro Theme and WebConfig FALLBACK_MENU_LEVEL_3 becomes a PRO_MENU_LEVEL_3
export const PRO_MENU_LEVEL_3: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [],
  information: {
    description: 'Pro Cluster: 3rd level in the navigation/menu structure of the Pro Home Page',
    single: 'Cluster',
    plural: 'Clusters',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    proAliasesComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
      },
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
  ],
};
