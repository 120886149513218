import { Autocomplete, Chip, TextField } from '@mui/material';

type CustomAutocompleteProps<T> = {
  defaultValue: T[];
  options?: T[];
  getOptionLabel?: (option: T) => string;
  multiple?: boolean;
  freeText?: boolean;
  readOnly?: boolean;
  onChange: (event, newValue) => void;
};

const CustomAutocomplete = <T,>({
  defaultValue,
  options = [],
  multiple = false,
  freeText = false,
  readOnly = false,
  getOptionLabel,
  onChange,
}: CustomAutocompleteProps<T>) => {
  const getLabel = (option: string | T): string => {
    if (typeof option === 'string') {
      return option;
    }
    if (getOptionLabel) {
      return getOptionLabel(option);
    }
    return String(option);
  };

  return (
    <>
      <Autocomplete
        multiple={multiple}
        freeSolo={freeText}
        options={options}
        value={defaultValue}
        onChange={onChange}
        getOptionLabel={getLabel}
        disabled={readOnly}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            // boxShadow: 'none',
            padding: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            // border: 'none',
          },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={getLabel(option)}
              {...getTagProps({ index })}
              key={index}
              disabled={readOnly}
            />
          ))
        }
        renderInput={(params) => (
          // @ts-expect-error text field wants a size
          <TextField
            variant="outlined"
            {...params}
            sx={{ borderRadius: 0 }}
            placeholder="Typ en druk op Enter..."
            disabled={readOnly}
          />
        )}
      />
    </>
  );
};

export default CustomAutocomplete;
