import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectReferenceGroupNode } from '@newStore/documentUI/transformContent/referenceGroupSelectors';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import constants from '../../../reduxLoop/constants/constants';

const REFERENCE_GROUP: BuildingBlockNodeConfig = {
  nodeSelector: selectReferenceGroupNode,
  validationRules: [selectCurriculumThemesValidation],
  information: {
    single: 'Verwijsgroep',
    plural: 'Verwijsgroepen',
    ribonIcon: require('../../../../img/buildingBlocks/REFERENCE_GROUP.svg'),
  },

  edit: [
    {
      component: 'referenceGroup',
      property: 'description',
      label: 'Globale verwijzingen',
      hiddenChildren: {
        containerType: ContentType.REFERENCE_GROUP,
        items: [
          {
            type: ContentType.REFERENCE,
            label: 'verwijzing',
          },
        ],
      },
    },
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      showInAncestorTypes: [NodeType.PRONEWSLETTERTEMPLATE, NodeType.PRONEWSLETTER],
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
      showInAncestorTypes: [NodeType.PRONEWSLETTERTEMPLATE, NodeType.PRONEWSLETTER],
    },
  ],
  isCollapsible: false,
  hideChildrenInDocument: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default REFERENCE_GROUP;
