import { Content } from '@generalTypes/apiTypes';
import { selectChangeMessageForDate } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForDate';
import { formatDate } from '@newStore/genericHelpers';
import { EditAsideIssuedDatePicker } from '@nodeTypeConfig/configTypes';

export const publishComponent: EditAsideIssuedDatePicker = {
  component: 'issued',
  property: 'issued',
  label: 'Publicatiedatum',
  reactComponent: 'AsideIssuedDatePicker',
  options: {
    openTo: 'day',
  },
  valueToString: (c: Content) => formatDate(c.issued, false),
  asideChangeMessageSelector: selectChangeMessageForDate,
};
