import { RootState } from '@generalTypes/rootStateTypes';
import { pathMap } from '@newStore/externalData/externalDataTypes';

import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { stringDiffArrowFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';
import { get } from 'lodash';

export const selectSecondaryEducationTypes = (state: RootState) =>
  state.externalData.data[pathMap.secondaryEducationTypes].items;

export const selectChangeMessageForSecondaryEducationTypes: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state) => selectSecondaryEducationTypes(state),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, options, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newValue = options[get(content, config.property) as string]?.name ?? '';
      const oldValue = options[get(originalContent, config.property) as string]?.name ?? '';

      return stringDiffArrowFunction(newValue, oldValue);
    }
  );
