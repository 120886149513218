import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import {
  loadPracticalExampleForZillIllustrations,
  loadZillGoalsForZillIllustrations,
} from '@newStore/zill/zillSagas';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';

const ZILL_ILLUSTRATION: RootNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    definiteArticle: false,
    single: 'Zill-illustratie',
    plural: 'Zill-illustraties',
    icon: require('../../../../img/icons/zillIllustration.svg'),
    category: 'ZILL',
  },

  onLoadActions: [loadZillGoalsForZillIllustrations(), loadPracticalExampleForZillIllustrations()],
  createModal: {
    authors: {
      addUser: true,
    },
  },
  tocTypes: [],
  buildingBlocks: [],
  edit: [
    titleComponent,
    authorsComponent,
    publishComponent,
    { component: 'practicalExampleReferences', label: 'Praktijkvoorbeeld' },
    { component: 'goalsByZillIllustration', label: 'Doel selecteren' },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
};

export default ZILL_ILLUSTRATION;
