import { proAccessRightsComponent } from '@nodeTypeConfig/helpers/editComponents/proAccessRightsComponent';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { RequiredType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import * as ACTION_TYPES from '@store/constants/actionTypes';
// import { globalDocumentDeleteValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import { selectGlobalDocumentNode } from '@newStore/documentUI/transformContent/globalDocumentSelectors';
import { getAccessRightsToStringFunction } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { globalDocumentDeleteValidationCmd } from '@store/commands/documentListCommands';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';

const GLOBAL_DOCUMENT: RootNodeConfig = {
  nodeSelector: selectGlobalDocumentNode,
  deleteFromListValidations: [globalDocumentDeleteValidationCmd],
  information: {
    definiteArticle: true,
    single: 'Gedeeld document',
    plural: 'Gedeelde documenten',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_IS_INCLUDED_IN_PRO_THEME,
    },
  ],

  createDefaults: { accessRights: accessRights.publiek.namedsets },
  buildingBlocks: [],
  tocTypes: [],
  createModal: {
    identifier: {},
    attachmentDocumentType: { required: true },
    authors: { addUser: true },
    uploadAttachment: { required: true },
  },
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      label: 'Titel',
      required: RequiredType.ERROR,
      width: '10',
    },
    {
      ...identifierComponent,
      width: '2',
      label: 'Referentienummer',
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Inleiding / korte inhoud',
      required: RequiredType.WARNING,
    },
    authorsComponent,
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: {
    ...proAccessRightsComponent,
    property: 'accessRights',
    valueToString: getAccessRightsToStringFunction('accessRights'),
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
};

export default GLOBAL_DOCUMENT;
