import { selectGoalSubItem } from '@newStore/documentUI/transformContent/llinkidSelectors';
import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { addMonths } from 'date-fns';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectChangeMessageForDate } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForDate';

const LLINKID_CONCRETIZATION: BuildingBlockNodeConfig = {
  nodeSelector: selectGoalSubItem,
  information: {
    single: 'Concretisering',
    plural: 'Concretiseringen',
  },
  createDefaults: {
    newUntilDate: addMonths(new Date().setUTCHours(0, 0, 0, 0), 3).toISOString(),
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.ERROR,
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'newUntilDate',
      property: 'newUntilDate',
      label: 'Toon als nieuw tot',
      reactComponent: 'AsideDatePicker',
      asideChangeMessageSelector: selectChangeMessageForDate,
      required: RequiredType.ERROR,
      options: {
        openTo: 'day',
      },
      width: 4,
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_CONCRETIZATION;
