import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { any } from 'prop-types';

import { patchThemes } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { EditAsideCurriculumSelector } from '@nodeTypeConfig/configTypes';
import Button from '@UI/button/Button';
import AsideCurriculumSelectorModal from './AsideCurriculumSelectorModal';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';

const AsideCurriculumSelector: React.FC<{
  config: EditAsideCurriculumSelector;
}> = ({ config }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const initialValue = useInitialValue(config.property) || [];
  const readOnly = useReadOnly();

  const openCurriculumSelectorModal = async () => {
    setIsModalOpen(true);
  };

  const submitCurriculumSelectorModal = (data) => {
    if (data) {
      const dataHref = data.map((o) => o.href);
      dispatch(patchThemes(getResourceKey(editHref), dataHref));
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      <AsideValidation property={config.property} component={config.component}>
        {/* This is just a button, does not need AsideChangeIndicator */}
        <span className="input-group">
          <span className="input-group-btn">
            <Button
              icon="glyphicon-add"
              onClick={() => openCurriculumSelectorModal()}
              disabled={readOnly}
            >
              Leerplanthema&apos;s selecteren
            </Button>
          </span>
        </span>
      </AsideValidation>

      {isModalOpen && (
        <AsideCurriculumSelectorModal
          handleSubmit={submitCurriculumSelectorModal}
          handleClose={() => setIsModalOpen(false)}
          selectedThemes={initialValue}
        />
      )}
    </div>
  );
};

AsideCurriculumSelector.displayName = 'AsideCurriculumSelector';

AsideCurriculumSelector.propTypes = {
  config: any,
};

export default AsideCurriculumSelector;
