import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { selectDocumentRootType } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import {
  selectIsReferenceFrameLoaded,
  selectProReferenceFrame,
  selectProReferenceFrameRelationsMap,
} from '@newStore/referenceFrames/referenceFramesSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { Content, ContentHref } from '@generalTypes/apiTypes';
import { VALIDATION_PENDING } from '../validationHelpers';
import { createError } from '../createError';
import { ValidationRule } from '../validationTypes';

const teaserThemeMismatchError = createError(
  'teaserThemeMismatch',
  'selectValidateTeaserMatchesSection',
  'Het thema van de teaser is geen onderdeel van het thema van de titel en wordt dus niet getoond. Pas het thema van de teaser aan.',
  { component: 'themes', property: 'themes' },
  RequiredType.WARNING
);

export const selectValidateTeaserMatchesSection: ValidationRule = createTypedSelector(
  [
    (state) => selectDocumentRootType(state),
    (state) => selectIsReferenceFrameLoaded(state, referenceFrames.pro),
    (state) => selectProReferenceFrameRelationsMap(state),
    (state) => selectProReferenceFrame(state),
    (state, href: ContentHref) => selectContentItem(state, href),
    (state, href, parentHref) => selectContentItem(state, parentHref),
  ],
  (
    rootType,
    referenceFrameLoaded,
    relationsMap,
    referenceFrame,
    content: Content | undefined,
    parentContent: Content | undefined
  ) => {
    if (
      rootType !== NodeType.PRONEWSLETTER ||
      !content?.themes?.length ||
      !parentContent?.themes?.length
    ) {
      return true;
    }

    if (!referenceFrameLoaded || !relationsMap) {
      return VALIDATION_PENDING;
    }

    const teaserThemePathsToRoot = content.themes.map((theme) => {
      return getPathToRoot(relationsMap, referenceFrame.content, theme);
    });

    // check if any of the teaser theme path to roots match with the section theme
    // meaning: check if any of the teaser theme(s) is a child of the section theme(s)
    const teaserThemeParents = new Set(
      teaserThemePathsToRoot.flat().map((z): string => z.$$meta.permalink)
    );

    const matchWithParent = parentContent.themes.some((theme) => teaserThemeParents.has(theme));

    if (!matchWithParent) {
      return teaserThemeMismatchError;
    }

    return true;
  }
);
