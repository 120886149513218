import { WebTemplateKey } from '@generalTypes/apiTypes';
import { loadSharedItemFacets } from '@newStore/externalData/sharedItemFacets';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { selectAttachmentsGroupNode } from '@newStore/documentUI/transformContent/attachmentSelectors';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { selectDoesEveryChildHasTitle } from '@newStore/validation/validationRules/everyChildMustHaveTitle';
import { selectValidateChildrenAreRequired } from '@newStore/validation/validationRules/childrenAreRequired';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import constants from '../../../../reduxLoop/constants/constants';
import { leerplanSitesThemeComponents } from './leerplansitesGenericConfigProps';

export const SHARED_ATTACHMENTS_GROUP: RootNodeConfig = {
  nodeSelector: selectAttachmentsGroupNode,
  validationRules: [
    selectValidateChildrenAreRequired,
    selectDoesEveryChildHasTitle,
    selectCurriculumThemesValidation,
  ],
  information: {
    definiteArticle: true,
    single: 'Achtergronddocument (leerplansites so)',
    plural: 'Achtergronddocumenten (leerplansites so)',
    icon: require('../../../../../img/icons/shared-attachments-group.svg'),
    category: 'LEERPLANSITES_SO',
  },
  onLoadActions: [
    loadReferenceFrame({ referenceFrame: referenceFrames.pro }),
    loadSharedItemFacets({
      matchingParams: {
        themesMatchingOnRoot: { referenceFrameKey: constants.dienstverleningKovKey },
        'webPage.template': '6a090cca-c380-4248-8722-2951431018a9' as WebTemplateKey, // DOWNLOAD_PAGE
        $$includeWebPages: true,
      },
    }),
  ],
  tocTypes: [],
  buildingBlocks: [],
  edit: [
    {
      component: 'downloadDocument',
      label: 'Document',
      avoidDispatchDownloadTag: true,
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
        copyTitle: true,
      },
    },
    publishComponent,
    ...leerplanSitesThemeComponents,
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: false },
    },
    {
      component: 'webFacetsPlaceHolder',
      reactComponent: 'AsideWebFacetPlaceHolder',
      label: 'Facetten',
      width: '12',
    },
  ],
  audienceTab: proThemeAudienceTab,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  allowSuggestions: true,
  hideChildrenInDocument: true,
};
