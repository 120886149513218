import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { FilterableEditComponent, RequiredType } from '@nodeTypeConfig/configTypes';
import { dienstverleningKovKey } from '@store/constants/constants';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { themeHrefsToString } from '../editComponentHelpers';
import { authorsComponent } from './authorsComponent';
import { proContactsComponent } from './proContactsComponent';
import { proThumbnailComponent } from './proThumbnailComponent';
import { publishComponent } from './publishComponent';
import { titleComponent } from './titleComponent';

export const proDocumentStandardComponents: Array<FilterableEditComponent> = [
  titleComponent,
  {
    component: 'description',
    reactComponent: 'AsideDescription',
    property: 'description',
    label: 'Inleiding',
    fullLabel: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    required: RequiredType.WARNING,
    asideChangeMessageSelector: selectChangeMessageForHtmlString,
  },
  {
    ...authorsComponent,
    label: 'Themaverantwoordelijke',
  },
  {
    ...proThumbnailComponent,
    label: 'Tegelfoto',
    width: '6',
  },
  publishComponent,
  {
    component: 'themes',
    property: 'themes',
    reactComponent: 'AsideThemes',
    label: "Thema's",
    options: {
      referenceFrameHref: `/content/${dienstverleningKovKey}` as ContentHref,
      types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
      highlightOldCurrItems: true,
    },
    valueToString: themeHrefsToString,
    asideChangeMessageSelector: selectChangeMessageForThemes,
  },
  {
    component: 'curriculumSelector',
    reactComponent: 'AsideCurriculumSelector',
    property: 'themes',
    label: "Leerplanthema's",
  },
  {
    ...proContactsComponent,
    width: '12',
  },
];
