import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { selectDocumentRootType } from '@newStore/documentUI/nodeTypeConfigSelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { Content, ContentHref } from '@generalTypes/apiTypes';
import { selectHiddenDescendants } from '@newStore/documentUI/documentUISelectors';
import { createError } from '../createError';
import { ValidationRule } from '../validationTypes';

// If a element is included in an content node via a IS_INCLUDED_IN relation, the intersection
// of that element targetaudience and the content node targetaudience should not be empty.
// This can happen with global documents in attachment groups, both in the main view or as hidden
// elements in the aside, and also in teasers included in newsletter. See monday 112131159

const properties = ['mainstructuresOuTypeCombinations', 'coverage'] as const;

const errorMessages = {
  mainstructuresOuTypeCombinations: 'Onderwijsniveaus en instellingstypes',
  coverage: 'regio',
};

const intersectionEmpty = (a, b) => !a || !b || b.filter((x) => a.includes(x)).length === 0;

export const selectValidateTeaserAudienceParentIntersectionNotEmpty: ValidationRule =
  createTypedSelector(
    [
      (state) => selectDocumentRootType(state),
      (state, href: ContentHref) => selectContentItem(state, href),
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href, parentHref) => parentHref,
    ],
    (rootType, content: Content | undefined, relationsMap, contentMap, parentHref) => {
      if (rootType !== NodeType.PRONEWSLETTER) {
        return true;
      }

      const pathToRoot = getPathToRoot(relationsMap, contentMap, parentHref);

      for (const propertyChecked of properties) {
        const ancestorWithAudience = pathToRoot.find((ancestor) => ancestor[propertyChecked]);
        if (
          ancestorWithAudience &&
          content &&
          intersectionEmpty(ancestorWithAudience[propertyChecked], content[propertyChecked])
        ) {
          return createError(
            `audienceIntersectionWithParentEmpty.${propertyChecked}`,
            'getAudienceIntersectionWithParentEmpty',
            `Een teaser moet minstens 1 <strong>${errorMessages[propertyChecked]}</strong> gemeenschappelijk hebben met de bijlagegroep waarin het is opgenomen`,
            { property: propertyChecked, component: 'namedSet' },
            RequiredType.WARNING
          );
        }
      }
      return true;
    }
  );

export const selectValidateAttachmentAudienceParentIntersectionNotEmpty: ValidationRule =
  createTypedSelector(
    [
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href: ContentHref) => selectHiddenDescendants(state, href),
      (state, href) => href,
    ],
    (relationsMap, contentMap, hiddenDescendants, href) => {
      const hrefsToCheck = [
        href,
        ...hiddenDescendants.map((descendant) => descendant.$$meta.permalink),
      ];
      const errorsFound = new Map<string, Set<string>>();

      for (const currentHref of hrefsToCheck) {
        if (relationsMap.to[currentHref]) {
          const pathToRoot = getPathToRoot(relationsMap, contentMap, currentHref);
          const includedChildren = relationsMap.to[currentHref]
            .filter((relation) => relation.relationtype === 'IS_INCLUDED_IN')
            .map((rel) => contentMap[rel.from.href])
            .filter(Boolean);

          for (const propertyChecked of properties) {
            const ancestorWithAudience = pathToRoot.find((ancestor) => ancestor[propertyChecked]);
            if (ancestorWithAudience && ancestorWithAudience[propertyChecked]) {
              for (const includedChild of includedChildren) {
                if (
                  intersectionEmpty(
                    includedChild[propertyChecked],
                    ancestorWithAudience[propertyChecked]
                  )
                ) {
                  if (includedChild.title) {
                    errorsFound.set(
                      includedChild.title,
                      new Set([...(errorsFound.get(includedChild.title) || []), propertyChecked])
                    );
                  }
                }
              }
            }
          }
        }
      }

      if (errorsFound.size > 0) {
        let message = `Een gedeeld document moet minstens 1 <strong>Onderwijsniveaus en instellingstypes</strong> en 1 <strong>regio</strong> gemeenschappelijk hebben met de bijlagegroep waarin het is opgenomen. <br />Probleem bij gedeeld document: <ul>`;
        errorsFound.forEach((props, title) => {
          message += `<li>${title} (${Array.from(props)
            .map((p) => errorMessages[p])
            .join(', ')})</li>`;
        });
        message += `</ul>`;
        return createError(
          `audienceIntersectionWithParentEmpty`,
          'getAudienceIntersectionWithParentEmpty',
          message,
          {
            property: 'mainstructuresOuTypeCombinations',
            component: 'namedSet',
          },
          RequiredType.WARNING
        );
      }
      return true;
    }
  );
