import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { referenceFrameDeleteValidationCmd } from '@store/commands/documentListCommands';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const REFERENCE_FRAME: RootNodeConfig = {
  nodeSelector: selectTitleNode,
  deleteFromListValidations: [referenceFrameDeleteValidationCmd],
  information: {
    definiteArticle: false,
    single: 'Ordeningskader',
    plural: 'Ordeningskaders',
    category: 'GENERAL',
  },

  createModal: {
    authors: {
      addUser: true,
    },
  },
  buildingBlocks: [{ type: NodeType.THEME }],
  tocTypes: [NodeType.THEME],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      label: 'Titel',
    },
  ],

  isSearchable: true,
  isCreatable: true,
  allowSuggestions: false,
};

export default REFERENCE_FRAME;
