import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { getPathToRoot } from '@newStore/externalData/externalDataHelpers';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  selectProReferenceFrame,
  selectProReferenceFrameRelationsMap,
} from '@newStore/referenceFrames/referenceFramesSelectors';
import { isEqual } from 'lodash';
import { createError } from '../createError';
import { ValidationError, ValidationRule } from '../validationTypes';

const selectThemePathToRoots = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href)?.themes,
    (state) => selectProReferenceFrameRelationsMap(state),
    (state) => selectProReferenceFrame(state)?.content,
  ],
  (themes, relationsMap, content) => {
    if (!themes || !relationsMap || !content) {
      return null;
    }

    return themes.map((theme) => {
      return getPathToRoot(relationsMap, content, theme);
    });
  }
);

/**
 * Rule to validate that a node does not include the "parent" CurriculumTheme and one of its children (subCurriculumTheme) at the same time
 */
export const selectCurriculumThemesValidation: ValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectContentItem(state, href)?.themes,
    (state, href: ContentHref) => selectThemePathToRoots(state, href),
    (state, href: ContentHref) => href,
  ],
  (themes, themePathToRoots, href): ValidationError | true => {
    console.log('running selectCurriculumThemesValidation for', href);
    if (themes && themes.length > 0 && themePathToRoots) {
      const doesNodeIncludeBothParentAndSubCurriculumTheme = themePathToRoots.some(
        (currPathToRoot) => {
          // This will return the curriculumThemes of the tree that are present on the themes of the node.
          // If it is greater than 1 it means the node includes the reference to the "parent" curriculumTheme but also one (or more) "subCurriculumThemes"
          const curriculumThemesSelectedOnTree = currPathToRoot?.filter(
            (currThemeRelative) =>
              currThemeRelative?.type === ContentType.CURRICULUM_THEME &&
              themes.includes(currThemeRelative.$$meta.permalink)
          );

          return curriculumThemesSelectedOnTree && curriculumThemesSelectedOnTree.length > 1;
        }
      );

      if (doesNodeIncludeBothParentAndSubCurriculumTheme) {
        return createError(
          'curriculumThemeSelectionNotValid',
          'selectCurriculumThemesValidation',
          "Je mag een leerplanthema en één of meer van zijn sub-leerplanthema's niet samen selecteren.",
          { component: 'themes', property: 'themes' }
        );
      }
    }

    return true;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  }
);
