import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectPathToRootHrefs,
  selectRelationToParent,
} from '@newStore/documentApi/documentApiSelectors';
import { getBuildingBlockType } from '@newStore/documentUI/documentUIHelpers';
import {
  selectContentNode,
  selectContentNodeChildren,
  selectIsNodeSelected,
} from '@newStore/documentUI/documentUISelectors';
import { dropItem, setDraggingNodes, setDropBelowItem } from '@newStore/documentUI/documentUIState';
import { ContentNode } from '@newStore/documentUI/documentUITypes';
import { selectTypeNameSingle } from '@newStore/documentUI/nodeTypeConfigSelectors';
import {
  DropExistingNode,
  DropType,
  HoverPosition,
  HoverPositionEnum,
  isDropFile,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import React, { ReactNode, useEffect, useState } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NativeTypes } from 'react-dnd-html5-backend';
import {
  selectBuildingBlocks,
  selectIsLastChild,
  selectSelectedDragItems,
} from './dragAndDropSelectors';
import DropLine from './DropLine';
import DropZone from './DropZone';

import './DragAndDrop.scss';
import { areDraggingFilesImages } from './dragAndDropHelpers';
import { stripHtml } from '@newStore/genericHelpers';

const DragAndDropRow: React.FC<{
  contentNode: ContentNode;
  parentHref: ContentHref | undefined;
  rowRef: React.MutableRefObject<HTMLDivElement>;
  dragRef: React.MutableRefObject<HTMLDivElement>;
  dropChildZoneRef: React.MutableRefObject<HTMLDivElement>;
  onUnCollapse: () => void;
  children: ReactNode;
  disableDrag: boolean;
  disableDrop: boolean;
}> = ({
  contentNode,
  parentHref,
  rowRef,
  dragRef,
  onUnCollapse,
  children,
  disableDrag,
  disableDrop,
  dropChildZoneRef,
}) => {
  const [hoverPosition, setHoverPosition] = useState<HoverPosition>(null);
  const [canDrop, setCanDrop] = useState(false);
  const [nativeImageDragging, setNativeImageDragging] = useState(false);
  const dispatch = useDispatch();

  const buildingBlocksForChild = useSelector((state: RootState) =>
    selectBuildingBlocks(state, contentNode.href)
  );

  const buildingBlocksForSiblings = useSelector((state: RootState) =>
    selectBuildingBlocks(state, parentHref)
  );

  const parentNode = useSelector(
    (state: RootState) => parentHref && selectContentNode(state, parentHref)
  );

  const relationToParent = useSelector(
    (state: RootState) => parentHref && selectRelationToParent(state, contentNode.href, parentHref)
  );

  const typeNameSingle = useSelector((state: RootState) =>
    selectTypeNameSingle(state, contentNode.href)
  );

  const parentTypeNameSingle = useSelector(
    (state: RootState) => parentHref && selectTypeNameSingle(state, parentHref)
  );

  const isSelected = useSelector(
    (state: RootState) => parentHref && selectIsNodeSelected(state, contentNode.href, parentHref)
  );

  const selectedDragItems = useSelector(selectSelectedDragItems);

  const [{ draggingHrefs }, dragHandleRef, preview] = useDrag(
    () => ({
      type: 'contentRow',
      item: () => {
        if (!relationToParent) {
          return null;
        }

        const item = {
          node: contentNode,
          buildingBlockType: getBuildingBlockType(contentNode.type),
          relation: relationToParent,
        };
        let draggingNodes: DropExistingNode;

        // dragging a non-selected item => only drag this item
        if (!isSelected) {
          draggingNodes = {
            items: [item],
          };
        } else {
          // dragging a selected item => drag all selected items
          draggingNodes = {
            items: selectedDragItems,
          };
        }

        dispatch(setDraggingNodes(draggingNodes));
        return draggingNodes;
      },
      end: () => {
        dispatch(setDropBelowItem(null));
        dispatch(setDraggingNodes({ items: [] }));
      },
      canDrag: () => {
        return !disableDrag;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        draggingHrefs:
          (monitor.isDragging() &&
            (monitor.getItem()?.items?.map((z) => z.node?.href) as string[] | undefined)) ||
          null,
      }),
    }),
    [contentNode, relationToParent, selectedDragItems]
  );

  if (!disableDrag) {
    dragHandleRef(dragRef);
  }

  const dropBelowItemIsNull = useSelector((state: RootState) => {
    const { dropBelowItem } = state.documentUI;
    return dropBelowItem === null;
  });

  /**
   * this selector will show the drop below box (recursively upwards) as long as the item is the last child.
   */
  const showDropBelow = useSelector((state: RootState) => {
    const { dropBelowItem } = state.documentUI;
    if (!dropBelowItem) {
      return false;
    }

    if (contentNode.href === state.documentUI.currentDocument) {
      // is root
      return false;
    }

    // when we hover over an item for some time, the dropBelowItem will be set.
    // we select the path to the root from the dropBelowItem href.
    const pathToRoot = selectPathToRootHrefs(state, dropBelowItem);

    // then we get the index of the first item on the path upwards, that is not a last child.
    const index = pathToRoot.findIndex(
      (href, idx) => !selectIsLastChild(state, href, pathToRoot[idx + 1], draggingHrefs)
    );
    if (index === -1) {
      return false;
    }

    // we slice the path to root, so we have an array of last items.
    const pathToRootOfLastItems = pathToRoot.slice(0, index + 1);

    // if the current node occurs, we show the drop below box.
    return pathToRootOfLastItems.some((href) => href === contentNode.href);
  });

  // is checked in validation rule where you get a clearer messages that it is not allowed (instead of just not allowing to drop without message)
  const canDropSiblingCheck = (item: DropType) => {
    if (isDropFile(item)) {
      if (!nativeImageDragging) {
        return false;
      }

      return buildingBlocksForSiblings.some((z) => z.type === NodeType.IMAGE);
    }

    const buildingBlocksMatch = item.items.every((it) =>
      buildingBlocksForSiblings.find((z) => z.type === it.buildingBlockType)
    );
    if (!buildingBlocksMatch) {
      return false;
    }

    return !disableDrop;
  };

  const canDropChildCheck = (item: DropType) => {
    if (isDropFile(item)) {
      if (!nativeImageDragging) {
        return false;
      }
      return buildingBlocksForChild.some((z) => z.type === NodeType.IMAGE);
    }

    const buildingBlocksMatch = item.items.every((it) =>
      buildingBlocksForChild.find((z) => z.type === it.buildingBlockType)
    );

    if (!buildingBlocksMatch) {
      return false;
    }

    return !disableDrop;
  };

  const [{ isOverCurrent, dragItemHrefs, nativeDraggingImages }, dropOnRef] = useDrop<
    DropType,
    void,
    {
      isOverCurrent: boolean;
      dragItemHrefs: string[] | null;
      nativeDraggingImages: boolean | null;
    }
  >(
    () => ({
      accept: ['contentRow', 'buildingblock', NativeTypes.FILE],
      drop: (item) => {
        console.log('dropped', item, hoverPosition, contentNode);
        const dropParentHref =
          hoverPosition === HoverPositionEnum.IN ? contentNode.href : (parentHref as string);
        const siblingHref = hoverPosition === HoverPositionEnum.IN ? null : contentNode.href;
        if (hoverPosition === HoverPositionEnum.IN) {
          onUnCollapse();
        }
        let droppedItems;
        if (isDropFile(item)) {
          droppedItems = Array.from(item.files).map((file) => ({
            newNode: true,
            node: { type: NodeType.IMAGE },
            buildingBlockType: NodeType.IMAGE,
            file,
          }));
        } else {
          droppedItems = item.items;
        }

        dispatch(
          dropItem({
            droppedItems,
            parentHref: dropParentHref,
            siblingHref,
            position: hoverPosition as HoverPositionEnum,
          })
        );
      },
      canDrop: (item) => {
        if (hoverPosition === HoverPositionEnum.IN) {
          return canDropChildCheck(item);
        }
        return canDropSiblingCheck(item);
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        if (item && isDropFile(item)) {
          return {
            nativeDraggingImages: areDraggingFilesImages(item),
            isOverCurrent: monitor.isOver({ shallow: true }),
            dragItemHrefs: null,
          };
        }
        const hrefs =
          item &&
          item.items?.reduce((acc, z) => {
            if (!('newNode' in z)) {
              return [...acc, z.node?.href];
            }
            return acc;
          }, [] as string[]);

        return {
          nativeDraggingImages: false,
          isOverCurrent: monitor.isOver({ shallow: true }), // show only this level
          dragItemHrefs: hrefs || null,
        };
      },
      hover: (item, monitor) => {
        if (rowRef.current) {
          const hoverBoundingRect = rowRef.current.getBoundingClientRect();
          const hoverHalfY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const clientOffset = monitor.getClientOffset();
          if (!clientOffset) {
            // this happens when you drag an image outside of the browser window
            return;
          }
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

          const canDropAbove = canDropSiblingCheck(item);
          const canDropBelow = canDropSiblingCheck(item);
          const canDropIn = canDropChildCheck(item);

          if (canDropAbove && canDropBelow && !canDropIn) {
            if (hoverClientY < hoverHalfY) {
              setHoverPosition(HoverPositionEnum.ABOVE);
              setCanDrop(canDropAbove);
            } else {
              setHoverPosition(HoverPositionEnum.BELOW);
              setCanDrop(canDropBelow);
            }
          } else if (canDropIn && !canDropAbove && !canDropBelow) {
            setHoverPosition(HoverPositionEnum.IN);
            setCanDrop(canDropIn);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (hoverClientY < hoverHalfY) {
              setHoverPosition(HoverPositionEnum.ABOVE);
              setCanDrop(canDropAbove);
            } else {
              setHoverPosition(HoverPositionEnum.IN);
              setCanDrop(canDropIn);
            }
          }
        }
      },
    }),
    [contentNode, hoverPosition]
  );

  useEffect(() => {
    // for some reason, when native dragging, the items list is sometimes 0
    // this is a workaround to get the correct value, to remember if we were dragging images.
    if (nativeDraggingImages === null) {
      return;
    }
    if (nativeDraggingImages) {
      setNativeImageDragging(true);
    }
    if (nativeDraggingImages === false) {
      setNativeImageDragging(false);
    }
  }, [nativeDraggingImages]);

  const [{ isOverDropzone }, dropBelowRef] = useDrop<
    DropType,
    void,
    {
      isOverDropzone: boolean;
    }
  >(
    () => ({
      accept: ['contentRow', 'buildingblock', NativeTypes.FILE],
      drop: (item) => {
        console.log('dropped', item, HoverPositionEnum.BELOW, contentNode);
        const dropParentHref = parentHref as string;
        const siblingHref = contentNode.href;

        let droppedItems;
        if (isDropFile(item)) {
          droppedItems = Array.from(item.files).map((file) => ({
            newNode: true,
            node: { type: NodeType.IMAGE },
            buildingBlockType: NodeType.IMAGE,
            file,
          }));
        } else {
          droppedItems = item.items;
        }

        dispatch(
          dropItem({
            droppedItems,
            parentHref: dropParentHref,
            siblingHref,
            position: HoverPositionEnum.BELOW,
          })
        );
      },
      canDrop: (item) => {
        return canDropSiblingCheck(item);
      },
      hover: (item, monitor) => {
        const canDropBelow = canDropSiblingCheck(item);
        setCanDrop(canDropBelow);
      },
      collect: (monitor) => {
        return {
          isOverDropzone: monitor.isOver({ shallow: true }), // show only this level
        };
      },
    }),
    [contentNode, hoverPosition]
  );

  const [{ isOverChildDropzone }, dropOnChildRef] = useDrop<
    DropType,
    void,
    {
      isOverChildDropzone: boolean;
    }
  >(
    () => ({
      accept: ['contentRow', 'buildingblock', NativeTypes.FILE],
      drop: (item) => {
        console.log('dropped', item, HoverPositionEnum.IN, contentNode);
        onUnCollapse();
        let droppedItems;
        if (isDropFile(item)) {
          droppedItems = Array.from(item.files).map((file) => ({
            newNode: true,
            node: { type: NodeType.IMAGE },
            buildingBlockType: NodeType.IMAGE,
            file,
          }));
        } else {
          droppedItems = item.items;
        }

        dispatch(
          dropItem({
            droppedItems,
            parentHref: contentNode.href,
            siblingHref: null,
            position: hoverPosition as HoverPositionEnum,
          })
        );
      },
      canDrop: (item) => {
        return canDropChildCheck(item);
      },
      hover: (item, monitor) => {
        const canDropIn = canDropChildCheck(item);
        setCanDrop(canDropIn);
      },
      collect: (monitor) => {
        return {
          isOverChildDropzone: monitor.isOver({ shallow: true }), // show only this level
        };
      },
    }),
    [contentNode, hoverPosition]
  );

  const hasChildren = useSelector((state: RootState) => {
    const childHrefs = selectContentNodeChildren(state, contentNode.href);

    return Boolean(childHrefs && childHrefs.filter((z) => !dragItemHrefs?.includes(z)).length > 0);
  });

  useEffect(() => {
    let cancel: NodeJS.Timeout | null = null;

    if (isOverCurrent && hoverPosition === HoverPositionEnum.IN) {
      cancel = setTimeout(() => {
        onUnCollapse();
      }, 500);
    }

    return () => {
      if (cancel) {
        clearTimeout(cancel);
      }
    };
  }, [onUnCollapse, isOverCurrent, hoverPosition]);

  useEffect(() => {
    let cancel: NodeJS.Timeout | null = null;

    if (
      isOverCurrent &&
      (hoverPosition === HoverPositionEnum.BELOW ||
        (hoverPosition === HoverPositionEnum.IN && !hasChildren))
    ) {
      cancel = setTimeout(() => {
        dispatch(setDropBelowItem({ href: contentNode.href }));
      }, 500);
    } else if (isOverCurrent && hoverPosition === HoverPositionEnum.ABOVE && !dropBelowItemIsNull) {
      dispatch(setDropBelowItem(null)); // Clear setDropBelowItem
    }

    return () => {
      if (cancel) {
        clearTimeout(cancel);
      }
    };
  }, [isOverCurrent, hoverPosition, contentNode.href, dispatch, hasChildren, dropBelowItemIsNull]);

  preview(dropOnRef(rowRef));

  if (disableDrag && disableDrop) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="dropAbove">
        <DropLine
          visible={isOverCurrent && hoverPosition === HoverPositionEnum.ABOVE && canDrop}
        ></DropLine>
      </div>
      {children}
      {dropChildZoneRef?.current &&
        createPortal(
          <div className="dropIn">
            <DropLine
              visible={isOverCurrent && hoverPosition === HoverPositionEnum.IN && canDrop}
            ></DropLine>
            <div ref={dropOnChildRef}>
              <DropZone
                visible={
                  showDropBelow && canDrop && hoverPosition === HoverPositionEnum.IN && !hasChildren
                }
                onHover={isOverChildDropzone}
                canDrop={canDrop}
              >
                Plaats onder{' '}
                {('title' in contentNode && stripHtml(contentNode.title)) || typeNameSingle}
              </DropZone>
            </div>
          </div>,
          dropChildZoneRef.current
        )}
      <div className="dropBelow" ref={dropBelowRef}>
        <DropLine
          visible={isOverCurrent && hoverPosition === HoverPositionEnum.BELOW && canDrop}
        ></DropLine>
        <DropZone visible={showDropBelow} onHover={isOverDropzone} canDrop={canDrop}>
          Plaats onder{' '}
          {(parentNode && 'title' in parentNode && stripHtml(parentNode.title)) ||
            parentTypeNameSingle}
        </DropZone>
      </div>
    </>
  );
};

export default DragAndDropRow;
