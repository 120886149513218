import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { selectAllExternalData } from '@newStore/externalData/externalDataSelectors';
import { AllExternalData } from '@newStore/externalData/externalDataTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideContactsOrCreators,
  EditComponent,
} from '@nodeTypeConfig/configTypes';
import {
  getPersonOrOUDisplayName,
  PersonOrOUTypeFromExternalData,
} from '@UI/personPicker/personHelper';
import { get, isEqual } from 'lodash';

export const selectSelectedContacts = createTypedSelector(
  [
    (state) => selectAllExternalData(state),
    (state, property) =>
      get(selectContentItem(state, state.documentUI.currentEditingNode as ContentHref), property),
  ],
  (externalData, currentValue) => {
    return currentValue?.map((href) => externalData[href]);
  }
);

const mapPersonOrOusToString = (
  values: string[] | undefined,
  externalData: AllExternalData
): string[] => {
  return (
    values?.map((item) =>
      externalData[item]
        ? getPersonOrOUDisplayName(externalData[item] as PersonOrOUTypeFromExternalData)
        : ''
    ) || []
  );
};

export const selectChangeMessageForPersonPicker: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state) => selectAllExternalData(state),
      (state, href: ContentHref, config: EditComponent) => config as EditAsideContactsOrCreators,
    ],
    (content, originalContent, externalData, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const newValue = get(content, config.property);
      const oldValue = get(originalContent, config.property);

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      const newValueString = mapPersonOrOusToString(newValue, externalData);
      const oldValueString = mapPersonOrOusToString(oldValue, externalData);

      const diffHtml = getDiffForArraysOfStrings(oldValueString, newValueString);

      return diffHtml;
    }
  );
