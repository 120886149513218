import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { EditAsideSimpleDropDown } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { set } from 'lodash';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';

import { useEditHref } from '../../../hooks/UseEditHref';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideSimpleDropDown: React.FC<{ config: EditAsideSimpleDropDown }> = ({ config }) => {
  const dispatch = useDispatch();

  const readOnly = useReadOnly();

  const editHref = useEditHref();

  const { options } = config.options;

  const currentValue = useInitialValue(config.property);

  const goalType = options.find((t) => currentValue === t.key);

  const onChange = (selection: EditAsideSimpleDropDown['options']['options'][0]) => {
    dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(
        getResourceKey(editHref),
        set({}, config.property, selection?.key || undefined)
      )
    );
  };

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        <label className="control-label">{config.label}</label>
        <KovResourcePicker
          localData={options}
          display="label"
          defaultValue={goalType}
          clearable={config.options.clearable}
          disabled={readOnly}
          disableSearch={true}
          style={{ option: (provided) => ({ ...provided, cursor: 'pointer' }) }}
          onChange={onChange}
        />
      </AsideValidation>
    </div>
  );
};

AsideSimpleDropDown.displayName = 'AsideSimpleDropDown';

AsideSimpleDropDown.propTypes = {
  config: any,
};

export default AsideSimpleDropDown;
