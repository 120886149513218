import { ContentHref, CoverageHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { selectIsNodeReadOnly } from '@newStore/documentUI/documentUISelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideCoverage,
  EditComponent,
  EditComponentProperty,
} from '@nodeTypeConfig/configTypes';
import { regions } from '@store/helpers/dioceses';
import {
  selectCurrentEditingNode,
  selectCurrentOrInheritedPropertyValue,
  selectInheritedPropertyValue,
} from '@store/selectors/asideSelectors';
import { get, isEqual, sortBy } from 'lodash';

export const selectCurrentSelectedCoverageOptions = createTypedSelector(
  [
    (state, href: ContentHref, config: EditComponent) =>
      selectCurrentOrInheritedPropertyValue(state, href, config),
  ],
  (currentValue) => {
    return regions.filter((region) => {
      return currentValue.includes(region.$$meta.permalink);
    });
  }
);

/**
 * in a Teaser the positions are readonly when there are themes selected
 */
export const selectIsReadOnly = createTypedSelector(
  [
    (state) =>
      Boolean(
        !state.documentUI.currentEditingNode ||
          selectIsNodeReadOnly(state, state.documentUI.currentEditingNode)
      ),
    (state) => selectCurrentEditingNode(state),
    (state, property: EditComponentProperty) => property,
  ],
  (readOnly, currentValue, property) => {
    const isReadOnly =
      readOnly ||
      (property === 'positions' &&
        currentValue.type === 'TEASER' &&
        (currentValue.themes ?? [])?.length > 0);
    return isReadOnly;
  }
);

export const selectChangeMessageForCoverage: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) =>
        selectInheritedPropertyValue(state, href, config),
      (state, href: ContentHref, config: EditComponent) => config as EditAsideCoverage,
    ],
    (content, originalContent, inheritedValues, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      const options = regions;

      const optionsMap = new Map(options.map((option) => [option.$$meta.permalink, option.name]));

      const sortedOptions = sortBy(options, ['name']).map((z) => z.name);

      const newValue = (get(content, config.property) ?? inheritedValues) as CoverageHref[];
      const oldValue = (get(originalContent, config.property) ?? inheritedValues) as CoverageHref[];

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      const diffHtml = getDiffForArraysOfStrings(
        oldValue.map((z) => optionsMap.get(z) || ''),
        newValue.map((z) => optionsMap.get(z) || ''),
        sortedOptions
      );

      return diffHtml;
    }
  );
