import { EditAsideContactsOrCreators } from '@nodeTypeConfig/configTypes';
import { defaultAuthorsParameters } from '@store/constants/constants';
import { selectChangeMessageForPersonPicker } from '@UI/aside/asidePersonPicker/asidePersonPickerSelectors';
import { entityHrefsArrayToString } from '../editComponentHelpers';

export const authorsComponent: EditAsideContactsOrCreators = {
  component: 'authors',
  property: 'creators',
  reactComponent: 'AsidePersonPicker',
  label: 'Auteurs',
  asideChangeMessageSelector: selectChangeMessageForPersonPicker,
  valueToString: (c, a) => entityHrefsArrayToString(c.creators, a),
  options: {
    queryParams: defaultAuthorsParameters,
  },
};
