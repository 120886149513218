import { ContentHref } from '@generalTypes/apiTypes';
import { selectReferenceChild } from '@newStore/documentUI/transformContent/teaserSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { EditComponent, RequiredType } from '@nodeTypeConfig/configTypes';
import { pick } from 'lodash';
import { createError } from '../createError';
import { AsideValidationRule, ValidationError } from '../validationTypes';

export const selectCallToActionTitleValidation: AsideValidationRule = createTypedSelector(
  [
    (state, href: ContentHref) => selectReferenceChild(state, href),
    (state, href, parent, config: EditComponent) => config,
  ],
  (referenceChild, config): ValidationError | true => {
    if (referenceChild) {
      if (!referenceChild.title) {
        return createError(
          'teaserCallToActionContentInvalid',
          'selectCallToActionTitleValidation',
          'Voeg een <strong>Call to action</strong> toe',
          pick(config, ['component', 'property']),
          RequiredType.ERROR
        );
      }
      if (referenceChild.title.split(' ').length > 5) {
        return createError(
          'teaserCallToActionContentInvalid',
          'selectCallToActionTitleValidation',
          '<strong>Call to action</strong> mag maximaal uit 5 woorden bestaan',
          pick(config, ['component', 'property']),
          RequiredType.ERROR
        );
      }
    }
    return true;
  }
);
