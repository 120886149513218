import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import constants from '../../../reduxLoop/constants/constants';

const FAQ_GROUP: BuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Faq-groep',
    plural: 'Faq-groepen',
    ribonIcon: require('../../../../img/buildingBlocks/FAQ_GROUP.svg'),
  },
  buildingBlocks: [{ type: NodeType.FAQ }],
  edit: [
    titleComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      asideChangeMessageSelector: selectChangeMessageForThemes,
      label: 'Categorie FAQ-groep',
      options: {
        referenceFrameHref: `/content/${constants.faqCategoriesKey}` as ContentHref,
        types: [ContentType.THEME],
      },
      valueToString: themeHrefsToString,
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default FAQ_GROUP;
