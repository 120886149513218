import { Crop } from 'react-image-crop';

/**
 *
 * @param imageFile
 * @param fileName
 * @param crop
 * @param displayedImageWidth
 * @param displayedImageHeight
 * @returns the cropped image file and base64 in full size/quality
 */
export const getCroppedImg = (
  imageFile: File,
  fileName: string,
  crop: Crop,
  displayedImageWidth: number,
  displayedImageHeight: number
): Promise<{ file: File; base64: string; width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        const scaleX = image.width / displayedImageWidth;
        const scaleY = image.height / displayedImageHeight;
        const c = {
          x: crop.x * scaleX,
          y: crop.y * scaleY,
          width: crop.width * scaleX,
          height: crop.height * scaleY,
        };
        const canvas = document.createElement('canvas');
        canvas.width = c.width;
        canvas.height = c.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(image, c.x, c.y, c.width, c.height, 0, 0, c.width, c.height);
          const outputFormat = imageFile.type || 'image/jpeg';
          const base64 = canvas.toDataURL(outputFormat);

          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], fileName, {
                type: outputFormat,
              });
              resolve({ file, base64, width: canvas.width, height: canvas.height });
            } else {
              reject(new Error('Canvas is empty'));
            }
          }, outputFormat);
        }
      };
    };
    reader.onerror = () => reject(new Error('Failed to read the image file.'));
  });
};

/**
 *
 * @param imageFile
 * @param width
 * @param height
 * @param quality
 * @returns the scaled down image file and base64 in the specified quality
 */
export const scaleImage = async (
  imageFile: File,
  width: number,
  height: number,
  quality: number = 0.6
): Promise<{ file: File; base64: string; width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();

      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
          const result = canvas.toDataURL(imageFile.type, quality);

          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], imageFile.name, {
                type: imageFile.type,
              });
              resolve({ file, base64: result, width, height });
            } else {
              reject(new Error('Canvas is empty'));
            }
          }, imageFile.type);
        } else {
          reject(new Error('Canvas context is not available'));
        }
      };

      img.onerror = () => {
        reject(new Error('Failed to load image'));
      };

      img.src = reader.result as string;
    };

    reader.onerror = () => {
      reject(new Error('Failed to read the image file.'));
    };

    reader.readAsDataURL(imageFile);
  });
};
