import { AttachmentImageMetaInfo, AttachmentMetaInfo, ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { htmlDiffFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideImage,
  EditComponent,
  isEditAsideImage,
} from '@nodeTypeConfig/configTypes';
import { selectCurrentEditingNode } from '@store/selectors/asideSelectors';
import { get, isEqual } from 'lodash';

export const selectAttachmentsOfType = createTypedSelector(
  [
    (state, config: EditAsideImage) => get(selectCurrentEditingNode(state), config.property),
    (state, config: EditAsideImage) => config.options.type,
  ],
  (attachments, type) => {
    return attachments.filter(
      (a: AttachmentMetaInfo) => a.type === type
    ) as AttachmentImageMetaInfo[];
  }
);

const findAttachment = (content, config, type) =>
  content?.[config.property]?.find(
    (a: AttachmentMetaInfo) => a.type === type && !('resized' in a)
  ) as AttachmentImageMetaInfo | undefined;

const generateHtml = (attachment, addDescription, addAlt) => {
  if (!attachment) {
    return '';
  }
  return `
    <img src="${getAttachmentUrl(attachment)}" />
    ${addDescription ? `<br />Bijschrift: ${attachment.description || ''}` : ''}
    ${addAlt ? `<br />Alt tag: ${attachment.alt || ''}` : ''}
  `;
};

export const selectChangeMessageForImage: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      if (!isEditAsideImage(config)) {
        console.error('config is not for an image', config);
        throw Error('config is not for an image');
      }

      const { type } = config.options;
      const newValue = findAttachment(content, config, type);
      const oldValue = findAttachment(originalContent, config, type);

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      const addDescription =
        config.options.fields?.includes('description') &&
        newValue?.description !== oldValue?.description;
      const addAlt = config.options.fields?.includes('alt') && newValue?.alt !== oldValue?.alt;

      const newHtml = generateHtml(newValue, addDescription, addAlt);
      const oldHtml = generateHtml(oldValue, addDescription, addAlt);

      const diffHtml = htmlDiffFunction(newHtml, oldHtml);

      return diffHtml;
    }
  );
