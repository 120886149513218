import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';

const LLINKID_GOAL_SECTION: BuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Rubriek',
    plural: 'Rubriek',
  },

  buildingBlocks: [
    { type: NodeType.LLINKID_GOAL },
    { type: NodeType.LLINKID_GOAL_SECTION },
    { type: NodeType.PARAGRAPH },
    {
      type: NodeType.LLINKID_STATIC_PART,
      max: 1,
      position: 1,
    },
    {
      type: NodeType.LLINKID_CONCORDANCE,
      max: 1,
      position: 1,
    },
    { type: NodeType.LLINKID_CONCRETIZATION },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
      // required: RequiredType.WARNING, <<== to test identifier validation in aside.
    },
    {
      ...titleComponent,
      width: 10,
      options: {
        showMarkerButton: true,
      },
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: true,
  },
};

export default LLINKID_GOAL_SECTION;
