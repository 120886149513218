import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectProMenuLevel2Node } from '@newStore/documentUI/transformContent/proMenuSelectors';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import constants from '@store/constants/constants';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { selectChangeMessageForColor } from '@UI/aside/asideColor/asideColorSelectors';
import { proAliasesComponent } from '@nodeTypeConfig/helpers/editComponents/proAliasesComponent';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import { extendsSection } from '../../helpers/genericConfigHelpers';
// Extends SECTION => A SECTION inside a Pro Theme and WebConfig FALLBACK_MENU_LEVEL_2 becomes a PRO_MENU_LEVEL_2
export const PRO_MENU_LEVEL_2: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectProMenuLevel2Node,
  validationRules: [],
  information: {
    description: 'Pro Channel: 2nd level in the navigation/menu structure of the Pro Home Page',
    single: 'Kanaal',
    plural: 'Kanalen',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    {
      component: 'color',
      reactComponent: 'AsideColor',
      property: 'color',
      label: 'Kleur',
      asideChangeMessageSelector: selectChangeMessageForColor,
    },
    {
      ...imageComponentBase,
      label: 'Ikoon',
      width: '6',
      options: { type: 'ICON', fields: [] },
    },
    proAliasesComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      options: {
        referenceFrameHref: `/content/${constants.dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
      },
      label: "Thema's",
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
  ],
};
