import React from 'react';
// eslint-disable-next-line
import UserIcon from '!!raw-loader!@img/icon_user.svg';
// eslint-disable-next-line
import GroupIcon from '!!raw-loader!@img/icon_people.svg';

import {
  getPersonOptionDisplayname,
  isOUFromSearch,
  isPersonFromSearch,
  PersonOptionType,
  getAddressString,
} from './personHelper';
import './PersonOption.scss';

export const PersonOption: React.FC<{ option: PersonOptionType; isMismatch: boolean }> = ({
  option,
  isMismatch,
}) => {
  const icon = isPersonFromSearch(option) ? UserIcon : GroupIcon;
  const title = getPersonOptionDisplayname(option);
  const mainResponsibilities =
    (isPersonFromSearch(option) &&
      option.responsibilities?.filter((responsibility) => responsibility.type === 'MAIN')) ||
    [];
  const addressString = isOUFromSearch(option) && getAddressString(option);

  return (
    <div className={`person-option ${isMismatch ? 'mismatch' : ''}`}>
      <span dangerouslySetInnerHTML={{ __html: icon }}></span>
      <div>
        <div className="title">{title}</div>
        {isPersonFromSearch(option) && mainResponsibilities.length > 0 && (
          <div
            className="subtitle"
            title={mainResponsibilities
              .map((responsibility) => responsibility.description)
              .join('\n')}
          >
            <ul style={{ listStyleType: 'none' }}>
              {mainResponsibilities.map((responsibility, index) => (
                <li key={index}>{responsibility.description}</li>
              ))}
            </ul>
          </div>
        )}
        {addressString && (
          <div className="subtitle" title={addressString}>
            {addressString}
          </div>
        )}
      </div>
    </div>
  );
};
