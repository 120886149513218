import { ContentHref } from '@generalTypes/apiTypes';
import { thirdGradeOldStructure } from '@kathondvla/curricula-selector';
import { getAllDescendantHrefs } from '@newStore/documentUI/documentUIHelpers';
import { createTypedSelector, mapRelationsToRelationsToAndFrom } from '@newStore/genericHelpers';
import { sectionCurrVakkenEnLeerplannen } from '@store/constants/constants';

const thirdGradeOldStructureHrefs = [
  ...thirdGradeOldStructure.studyFieldHrefs,
  ...thirdGradeOldStructure.subjectHrefs,
];

export const selectHighlightedThemesOptionsSet = createTypedSelector(
  [(state, href: ContentHref) => state.referenceFrames[href]],
  (referenceFrame) => {
    if (!referenceFrame?.relations) {
      return new Set();
    }
    const relationsMap = mapRelationsToRelationsToAndFrom(referenceFrame.relations);
    const oldCurrHrefs = getAllDescendantHrefs(
      relationsMap?.to,
      `/content/${sectionCurrVakkenEnLeerplannen}` as ContentHref
    );
    const highlightHrefs = oldCurrHrefs.filter(
      (href) => !thirdGradeOldStructureHrefs.includes(href)
    );
    return new Set(highlightHrefs);
  }
);
