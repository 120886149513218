import { ContentType } from '@generalTypes/apiTypes';
import { RootEditComponent, NodeType } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForLinks } from '@UI/aside/asideLinks/asideLinksSelectors';

export const proLinksAndMenuReferences: Array<RootEditComponent> = [
  {
    component: 'menuItemReference',
    label: 'Navigeert naar',
    property: 'referenceRelation.to',
    options: {
      filter: {
        typeIn: 'STRUCTURED_DOCUMENT,SECTION',
        rootTag: [NodeType.WEBPAGE2],
        $$expandPathToRoot: true,
        orderBy: '$$meta.modified',
        descending: true,
      },
    },
  },
  {
    component: 'linkGroup',
    reactComponent: 'AsideLinks',
    asideChangeMessageSelector: selectChangeMessageForLinks,
    label: 'Links',
    width: '12',
    options: {
      type: 'group',
    },
    hiddenChildren: {
      containerType: ContentType.LINK_GROUP,
      items: [
        {
          type: ContentType.REFERENCE,
          label: 'link',
        },
      ],
    },
  },
];
