import { EditComponent, RequiredType } from '@nodeTypeConfig/configTypes';
import { ValidationError } from './validationTypes';

export const createError = (
  code: string,
  rule: string,
  message: string,
  config?: {
    property?: EditComponent['property'] | undefined;
    component: EditComponent['component'];
  },
  type: RequiredType = RequiredType.ERROR
): ValidationError => {
  return {
    code,
    type,
    message,
    property: config?.property,
    component: config?.component,
    rule,
  };
};
