import { RootState } from '@generalTypes/rootStateTypes';
import { StudyProgramme } from '@generalTypes/samTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { EditAsideStudyProgrammes } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { escapeRegExp, set } from 'lodash';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import {
  selectStudyProgrammeOptions,
  selectStudyProgrammesSelectedOptions,
} from './asideStudyProgrammeSelectors';

const filterData = ({ data }, str) => {
  if (!str || str.trim().length === 0) {
    return true;
  }
  const regExp = new RegExp(escapeRegExp(str), 'gi');
  return regExp.test(data.title);
};

const AsideStudyProgrammes: React.FC<{
  config: EditAsideStudyProgrammes;
}> = ({ config }) => {
  const editHref = useEditHref();

  const readOnly = useReadOnly();
  const dispatch = useDispatch();

  const onChange = (item: StudyProgramme[]) => {
    const patch = set(
      {},
      config.property,
      item.map((z) => ({ href: z.$$meta.permalink }))
    );
    dispatch(patchNodeAction(getResourceKey(editHref), patch));
  };

  const studyProgrammeOptions = useSelector((state: RootState) =>
    selectStudyProgrammeOptions(state)
  );

  const selectedOptions = useTypedSelector((state) =>
    selectStudyProgrammesSelectedOptions(state, editHref, config)
  );

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        <label className="control-label">{config.label}</label>
        {studyProgrammeOptions.length === 0 ? (
          <div className="form-group shimmer narrowLine"></div>
        ) : (
          <KovResourcePicker
            value={selectedOptions}
            localData={studyProgrammeOptions}
            clearable={true}
            disabled={readOnly}
            onChange={onChange}
            placeholder="Selecteer een studierichting..."
            display="title"
            multiple={true}
            menuPlacement="bottom"
            filter={filterData}
          />
        )}
      </AsideValidation>
    </div>
  );
};

AsideStudyProgrammes.displayName = 'AsideStudyProgrammes';

export default AsideStudyProgrammes;

AsideStudyProgrammes.propTypes = {
  config: any,
};
