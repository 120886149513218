import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { ExtendedBuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { proWebFacetComponent } from '@nodeTypeConfig/helpers/editComponents/proWebFacetComponent';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proThumbnailComponent } from '@nodeTypeConfig/helpers/editComponents/proThumbnailComponent';
import { proTileDefaultValidionRules } from '../proGenericConfigProps';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';
// Extends SECTION => A SECTION inside a Pro Theme and webConfig MINI_DATABASE_ITEM becomes a PRO_DATABASE_ITEM
const PRO_DATABASE_ITEM: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: proTileDefaultValidionRules,
  information: {
    definiteArticle: true,
    description: 'A Database Item, can only appear as child of a blog',
    single: 'Databank item',
    plural: 'Databank Items',
    icon: require('../../../../../img/icons/webpage.svg'),
  },

  ...extendsSection,
  isCollapsedByDefault: true,
  buildingBlocks: proDefaultBuildingBlocks,
  edit: [
    {
      ...titleComponent,
      width: '10',
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      width: '12',
      label: 'Inleiding',
      fullLabel: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      required: RequiredType.WARNING,
    },
    {
      ...proThumbnailComponent,
      width: '12',
    },
    {
      ...authorsComponent,
      width: '12',
    },
    {
      component: 'attachmentsGroup',
      label: 'Bijlagen',
      options: { global: true },
    },
    ...proLinksAndMenuReferences,
    proWebFacetComponent,
  ],
  confirmDelete: true,
};

export default PRO_DATABASE_ITEM;
