import { Content, ContentHref, ContentRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChangesWithoutDeletes,
  selectChildren,
  selectDocumentRoot,
  selectRelationToParent,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectAllNodeTypesMap,
  selectAppliedNodeConfig,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
import { NodeType, NodeTypeConfigApplied, RequiredType } from '@nodeTypeConfig/configTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { getNodeTypeConfig } from '@nodeTypeConfig/index';
import { getNodeTypeLabel, getNodeTypeLabelFromConfig } from '../validationHelpers';
import { createError } from '../createError';
import { ValidationResult } from '../validationTypes';

export const selectIsReadOrderUniqueWithinParent = createTypedSelector(
  [
    (state, href, parentHref) => selectRelationToParent(state, href, parentHref),
    (state, href, parentHref) =>
      selectApiWithPendingChangesWithoutDeletes(state).relationsToAndFromMap.to[parentHref],
    (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
  ],
  (
    relationToParent,
    parentRelations: ContentRelation[],
    nodeTypeConfig: NodeTypeConfigApplied
  ): ValidationResult => {
    if (!relationToParent) {
      return true;
    }
    const siblingRelWithSameReadOrder: ContentRelation | undefined = parentRelations.find(
      (rel) =>
        rel.key !== relationToParent.key &&
        parentChildRelationFilter(rel) &&
        rel.readorder === relationToParent.readorder
    );
    if (siblingRelWithSameReadOrder) {
      return createError(
        'duplicateReadOrderWithinParent',
        'selectIsReadOrderUniqueWithinParent',
        `De <strong>positie</strong> van ${
          nodeTypeConfig.information.definiteArticle ? 'dit' : 'deze'
        } ${getNodeTypeLabelFromConfig(
          nodeTypeConfig
        )} is niet gekend. Versleep dit item om het een unieke positie te geven in het document.`,
        undefined,
        RequiredType.WARNING
      );
    }
    return true;
  }
);

/** two helper functions that use existing selectors, but invoke them with parentHref and handle the root case (parentHref is undefined) */
const selectParentNodeTypeConfig = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref | undefined
) => {
  if (!parentHref) {
    return null;
  }
  return selectAppliedNodeConfig(state, parentHref);
};
const emptyArray = [];
const selectAllChildrenOfParent = (
  state: RootState,
  href: ContentHref,
  parentHref: ContentHref | undefined
) => {
  if (!parentHref) {
    return emptyArray;
  }
  return selectChildren(state, parentHref);
};

const getBuildingBlock = (nodeType: NodeType, parentNodeTypeConfig: NodeTypeConfigApplied) => {
  const nodeTypeConfig = getNodeTypeConfig(nodeType);
  const extendedNodeType =
    'extends' in nodeTypeConfig && nodeTypeConfig.extends ? nodeTypeConfig.extends : nodeType;
  return parentNodeTypeConfig.buildingBlocks?.find((bb) => bb.type === extendedNodeType);
};

export const selectIsBuildingBlockValidWithinParent = createTypedSelector(
  (state, href: ContentHref, parentHref: ContentHref | undefined) =>
    selectParentNodeTypeConfig(state, href, parentHref),
  (state, href: ContentHref) => selectAppliedNodeConfig(state, href),
  (state, href: ContentHref, parentHref: ContentHref | undefined) =>
    selectAllChildrenOfParent(state, href, parentHref),
  (state) => selectAllNodeTypesMap(state),
  (state) => selectDocumentRoot(state),
  (state, href: ContentHref) => href,
  (
    parentNodeTypeConfig: NodeTypeConfigApplied | null,
    thisNodeTypeConfig,
    siblings: Content[],
    nodeTypeMap,
    rootDocument,
    href: ContentHref
  ): ValidationResult => {
    if (!parentNodeTypeConfig || !parentNodeTypeConfig.buildingBlocks) {
      return true;
    }
    const nodeType = nodeTypeMap[href];
    const parentLabel = parentNodeTypeConfig.information.single;
    const buildingBlock = getBuildingBlock(nodeType, parentNodeTypeConfig);

    if (!buildingBlock) {
      if (
        'createChildDefaults' in parentNodeTypeConfig &&
        parentNodeTypeConfig.createChildDefaults?.find((bb) => bb.type === nodeType)
      ) {
        // Some child building blocks are not defined as buildingblocks but are created on init by createChildDefaults and not editable afterwards
        // We don't need to check "max" or "position" in this case because, regarding that it is not a buildingBlock, it won't be possible for the user
        // to add a new one, so we can return true directly
        return true;
      }
      if (thisNodeTypeConfig.isDeprecated) {
        // There are cases like in Odet that a building block is removed from the config, but it should still be shown in old versions.
        return true;
      }
      return createError(
        'buildingBlockNotAllowedInParent',
        'selectAreSiblingBuildingBlocksValid',
        `Een <strong>${
          thisNodeTypeConfig.information.single
        }</strong> kan niet onder een ${getNodeTypeLabel(parentLabel)} staan.`
      );
    }

    // it is not great that the position error disappears when the max error shows up, but it is not a big deal
    if (buildingBlock.max) {
      const childrenOfType = siblings.filter(
        (child) => nodeTypeMap[child.$$meta.permalink] === buildingBlock.type
      );
      if (childrenOfType.length > buildingBlock.max) {
        const thisNodeTypeLabel =
          buildingBlock.max > 1
            ? thisNodeTypeConfig.information.plural
            : thisNodeTypeConfig.information.single;
        return createError(
          'maxSiblingBuildingBlocksExceeded',
          'selectAreSiblingBuildingBlocksValid',
          `Een ${getNodeTypeLabel(parentLabel)} mag maar <strong>${
            buildingBlock.max
          } ${thisNodeTypeLabel}</strong> hebben. Verwijder ${
            childrenOfType.length - buildingBlock.max
          } ${thisNodeTypeLabel}.`,
          undefined,
          rootDocument?.issued ? RequiredType.WARNING : RequiredType.ERROR
        );
      }
    }

    if (buildingBlock.position) {
      const positionOfThisNodeInParent =
        siblings.findIndex((sibling) => sibling.$$meta.permalink === href) + 1;
      if (positionOfThisNodeInParent !== buildingBlock.position) {
        return createError(
          'buildingBlockPositionNotCorrect',
          'selectAreSiblingBuildingBlocksValid',
          `${thisNodeTypeConfig.information.definiteArticle ? 'Het' : 'De'} ${getNodeTypeLabel(
            thisNodeTypeConfig.information.single
          )} moet op positie ${buildingBlock.position} staan.`
        );
      }
    }

    return true;
  }
);
