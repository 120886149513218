import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

import { selectLegalNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';

const LEGAL: BuildingBlockNodeConfig = {
  nodeSelector: selectLegalNode,
  information: {
    single: 'Wettekst',
    plural: 'Wettekst',
    ribonIcon: require('../../../../img/buildingBlocks/LEGAL.svg'),
  },

  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 3,
    },
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      label: 'Titel',
      width: 9,
    },
    {
      component: 'attachedContent',
      property: '$$html',
      label: ' ',
      reactComponent: 'AsideAttachedContent',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
    },
  ],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default LEGAL;
