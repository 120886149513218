import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';
import { isEqual, escape, get } from 'lodash';
import { htmlDiffFunction } from '../transformProposal/asideDiffText';

export const selectChangeMessageForStaticHtmlString: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`No property found for config`);
      }

      const newValue = (get(content, config.property) as string) ?? '';
      const oldValue = (get(originalContent, config.property) as string) ?? '';

      if (isEqual(newValue, oldValue)) {
        return null;
      }

      const diffHtml = htmlDiffFunction(escape(newValue), escape(oldValue));

      return diffHtml;
    }
  );
