import React from 'react';

import './dropZone.scss';

const DropZone = ({ visible = false, onHover = false, canDrop = true, children }) => {
  if (!visible) {
    return null;
  }
  return (
    <div className={`drop-zone ${onHover && 'on-hover'} ${onHover && !canDrop && 'no-drop'} `}>
      {children}
    </div>
  );
};

export default DropZone;
