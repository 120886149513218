import { CoverageHref } from '@generalTypes/apiTypes';

export type Region = {
  $$meta: {
    permalink: CoverageHref;
  };
  name: string;
};

export const regions: Region[] = [
  {
    $$meta: {
      permalink: '/dioceses/1' as CoverageHref,
    },
    name: 'Antwerpen',
  },
  {
    $$meta: {
      permalink: '/dioceses/2' as CoverageHref,
    },
    name: 'Mechelen-Brussel',
  },
  {
    $$meta: {
      permalink: '/dioceses/3' as CoverageHref,
    },
    name: 'Limburg',
  },
  {
    $$meta: {
      permalink: '/dioceses/4' as CoverageHref,
    },
    name: 'Oost-Vlaanderen',
  },
  {
    $$meta: {
      permalink: '/dioceses/5' as CoverageHref,
    },
    name: 'West-Vlaanderen',
  },
];
