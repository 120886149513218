import { selectThemeItemNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType, RequiredType } from '@nodeTypeConfig/configTypes';
import { themeReferencesWithRootsCmd } from '@store/commands/documentCommands';
import { selectChangeMessageForColor } from '@UI/aside/asideColor/asideColorSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import { selectChangeMessageForStringArray } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForStringArray';

const getThemeReferencesWithRootsCmd = (node) => ({
  cmd: themeReferencesWithRootsCmd,
  params: [node.$$meta.permalink],
  message: 'validation.themeReferenced',
});

const THEME: BuildingBlockNodeConfig = {
  nodeSelector: selectThemeItemNode,
  deleteValidations: [getThemeReferencesWithRootsCmd],
  information: {
    single: 'Item',
    plural: 'Items',
  },

  buildingBlocks: [
    { type: NodeType.THEME },
    { type: NodeType.CURRICULUM_CLUSTER },
    { type: NodeType.CURRICULUM_THEME },
  ],
  edit: [
    {
      component: 'title',
      property: 'title',
      reactComponent: 'AsideTitle',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      label: 'Titel',
      width: '8',
      required: RequiredType.ERROR,
    },
    {
      component: 'color',
      reactComponent: 'AsideColor',
      asideChangeMessageSelector: selectChangeMessageForColor,
      property: 'color',
      label: 'Kleur',
      width: '4',
    },
    {
      ...imageComponentBase,
      width: '6',
      label: 'Ikoon',
      options: {
        type: 'ICON',
      },
    },
    {
      component: 'subjects',
      property: 'subjects',
      reactComponent: 'AsideSubjects',
      label: 'Aliassen',
      asideChangeMessageSelector: selectChangeMessageForStringArray,
    },
  ],
  onNewNodeDropped: {
    focusOnField: 'title',
  },
  confirmDelete: true,
  isCollapsible: true,
  isCollapsedByDefault: false,
};

export default THEME;
