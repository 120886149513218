import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

export const CURRICULUM_ZILL_LEERLIJN: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  information: {
    single: 'Leerlijn Gewoon',
    plural: 'Leerlijn Gewoon',
  },

  createDefaults: {
    educationsorts: ['/onderwijssoorten/0c739f2c-2acf-11e5-937b-00ff001a7a08'],
  },
  buildingBlocks: [
    { type: NodeType.CURRICULUM_ZILL_LEERLIJN_CLUSTER },
    { type: NodeType.CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE },
    { type: NodeType.CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE },
  ],
  edit: [],
  showPlaceholder: true,
  isCollapsible: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export const CURRICULUM_ZILL_LEERLIJN_AN: BuildingBlockNodeConfig = {
  ...CURRICULUM_ZILL_LEERLIJN,
  information: {
    single: 'Leerlijn AN', // anderstalige nieuwkomers
    plural: 'Leerlijn AN',
  },
  createDefaults: {
    educationsorts: ['/onderwijssoorten/0c739f2c-2acf-11e5-937b-00ff001a7a08'],
  },
};
