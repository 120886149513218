import { selectParagraphNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { imageComponent } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectChangeMessageForStaticHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForStaticHtmlString';

const PARAGRAPH: BuildingBlockNodeConfig = {
  nodeSelector: selectParagraphNode,
  information: {
    single: 'Alinea',
    plural: "Alinea's",
    ribonIcon: require('../../../../img/buildingBlocks/PARAGRAPH.svg'),
  },

  createDefaults: {
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html',
      },
    ],
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      reactComponent: 'AsideAttachedContent',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      hideInAncestorTypes: [
        NodeType.PRONEWSLETTER,
        NodeType.LLINKID_CURRICULUM,
        NodeType.LLINKID_FOUNDATIONAL_CURRICULUM,
        NodeType.PRO_STATIC_PAGE,
        NodeType.WWW_STATIC_PAGE,
      ],
    },
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      reactComponent: 'AsideAttachedContent',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      showInAncestorTypes: [NodeType.PRONEWSLETTER],
      options: {
        hideTermButton: true,
      },
    },
    {
      component: 'attachedContent',
      label: 'Inhoud',
      property: '$$html',
      reactComponent: 'AsideAttachedContent',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      showInAncestorTypes: [NodeType.LLINKID_CURRICULUM, NodeType.LLINKID_FOUNDATIONAL_CURRICULUM],
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'staticHtml',
      reactComponent: 'AsideStaticHtml',
      label: 'Inhoud',
      property: '$$html',
      showInAncestorTypes: [NodeType.PRO_STATIC_PAGE, NodeType.WWW_STATIC_PAGE],
      asideChangeMessageSelector: selectChangeMessageForStaticHtmlString,
    },
    {
      ...imageComponent,
      options: {
        ...imageComponent.options,
        fields: ['alt'],
      },
      hideInAncestorTypes: [
        NodeType.LLINKID_STATIC_PART,
        NodeType.TERM,
        NodeType.PRONEWSLETTER,
        NodeType.PRONEWSLETTERTEMPLATE,
      ],
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'html',
  },
};

export default PARAGRAPH;
