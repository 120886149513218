import InlineEditor from '@UI/editor/InlineEditor';
import { LlinkidGoalSubItemNode } from '@newStore/documentUI/documentUITypes';
import { useSelector } from 'react-redux';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectAppliedNodeConfig } from '@newStore/documentUI/nodeTypeConfigSelectors';

import './LlinkidGoalSubItemRow.scss';
import { getPlaceHolderLabel } from '../../contentRowHelpers';

const getIcon = (type: string) => {
  switch (type) {
    case 'LLINKID_PEDAGOGICAL_TIP':
      return 'images/pedagogical-tip.svg';
    case 'LLINKID_EXTRA_GOAL_INFORMATION':
      return 'images/icon_extra.svg';
    case 'LLINKID_GOAL_EXPLANATION':
      return 'images/icon_goal_explanation.svg';
    case 'LLINKID_INITIAL_SITUATION':
      return 'images/icon_initial_situation.svg';
    case 'LLINKID_CONCRETIZATION':
      return 'images/icon_eye.svg';
    default:
      return '';
  }
};

const LlinkidGoalSubItemRow: React.FC<{ contentNode: LlinkidGoalSubItemNode }> = ({
  contentNode,
}) => {
  const nodeTypeConfig = useSelector((state: RootState) =>
    selectAppliedNodeConfig(state, contentNode.href)
  );

  return (
    <div className="llinkid-sub-item">
      <div className="llinkid-sub-item-identifier">
        <div className="tip-icon">
          <img src={getIcon(contentNode.type)}></img>
        </div>
      </div>

      <div className="llinkid-sub-item-divider"></div>

      <div className="llinkid-sub-item-description">
        <span className="component-title">{contentNode.name}</span>
        {contentNode.type === 'LLINKID_CONCRETIZATION' && contentNode.isNew && (
          <span className="concretization-label">NIEUW</span>
        )}
        <InlineEditor
          initial={contentNode.description}
          field="description"
          placeholder={getPlaceHolderLabel(nodeTypeConfig, 'description')}
          skey={contentNode.key}
          href={contentNode.href}
        ></InlineEditor>
      </div>
    </div>
  );
};

export default LlinkidGoalSubItemRow;
