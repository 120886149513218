import { selectZillClusterNode } from '@newStore/documentUI/transformContent/zillContentNodeSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const CURRICULUM_ZILL_CLUSTER: BuildingBlockNodeConfig = {
  nodeSelector: selectZillClusterNode,
  information: {
    single: 'Cluster',
    plural: 'Clusters',
  },
  buildingBlocks: [{ type: NodeType.CURRICULUM_ZILL_DEVELOPMENT_FIELD }],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
    },
  ],
  documentViewIdentifier: 'i',
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ZILL_CLUSTER;
