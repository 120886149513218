import { Content, ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideSimpleDropDown,
  EditComponent,
  isEditAsideSimpleDropDown,
} from '@nodeTypeConfig/configTypes';
import { get } from 'lodash';
import { stringDiffArrowFunction } from '../transformProposal/asideDiffText';

const getSelectedOptionLabel = (content: Content, config: EditAsideSimpleDropDown): string => {
  return config.options.options.find((z) => z.key === get(content, config.property))?.label ?? '';
};

export const selectChangeMessageForSimpleDropdown: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      if (!isEditAsideSimpleDropDown(config)) {
        console.error('config is not a simple dropdown', config);
        throw Error('config is not a simple dropdown');
      }

      const newValue = getSelectedOptionLabel(content, config);
      const oldValue = getSelectedOptionLabel(originalContent, config);

      return stringDiffArrowFunction(newValue, oldValue);
    }
  );
