import { ContentType } from '@generalTypes/apiTypes';
import { loadIllustrationsReferencingPracticalExamples } from '@newStore/zill/zillSagas';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectChangeMessageForLinks } from '@UI/aside/asideLinks/asideLinksSelectors';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import { settings } from '../../settings';

const PRACTICAL_EXAMPLE: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  information: {
    definiteArticle: true,
    single: 'Zill-praktijkvoorbeeld',
    plural: 'Zill-praktijkvoorbeelden',
    icon: require('../../../../img/icons/practicalExample.svg'),
    category: 'ZILL',
  },

  onLoadActions: [loadIllustrationsReferencingPracticalExamples()],
  relationsToIgnore: ['IS_VERSION_OF', 'IS_INCLUDED_IN', 'REPLACES', 'REFERENCES'],
  createModal: {
    authors: {
      addUser: true,
    },
  },
  tocTypes: [NodeType.SECTION],
  buildingBlocks: [
    { type: NodeType.SECTION },
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.ATTACHMENTS_GROUP },
    { type: NodeType.MUSIC },
    { type: NodeType.VIDEO },
    { type: NodeType.VIDEO_GROUP },
    { type: NodeType.QUOTE },
    { type: NodeType.WORD_IMPORT },
  ],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
    authorsComponent,
    {
      component: 'ageRange',
      reactComponent: 'AsideAgeRange',
      label: 'Leeftijd',
    },
    {
      ...imageComponentBase,
      label: 'Miniatuur',
      options: {
        type: 'THUMBNAIL',
      },
    },
    {
      component: 'linkGroup',
      reactComponent: 'AsideLinks',
      asideChangeMessageSelector: selectChangeMessageForLinks,
      // component does not work? Should this be the LinkGroup component same as pro?
      label: 'Links',
    },
    {
      component: 'zillIllustrations',
      label: 'Illustraties',
    },
    {
      component: 'sources',
      label: 'Bronnen',
      reactComponent: 'AsideSources',
      hiddenChildren: {
        containerType: ContentType.STRUCTURED_DOCUMENT,
        items: [
          {
            type: ContentType.SOURCE,
            label: 'bron',
          },
        ],
      },
    },
  ],
  allowSuggestions: false,
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  previewModes: [
    {
      type: 'ZILL',
      name: 'Zill Leerplansite',
      location: `${settings.apisAndUrls.zill}/#!/bib/{%key}?preview&version={%version}`,
    },
  ],
};

export default PRACTICAL_EXAMPLE;
