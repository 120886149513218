import React, { useState } from 'react';
import { Modal } from '@kathondvla/react-shared-components/src/components';

import './SourceModal.scss';

export type Source = {
  title: string;
};

export interface SourceModalProps {
  onClose?: () => void;
  onSubmit?: (source: Source) => void;
}

const SourceModal: React.FC<SourceModalProps> = ({ onClose = () => {}, onSubmit }) => {
  const [sourceForm, setSourceForm] = useState<Source>({ title: '' });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSourceForm((prevLink) => ({ ...prevLink, [name]: value }));
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(sourceForm);
      onClose();
    }
  };

  return (
    <>
      <Modal.Header title="Bron invoegen" onClose={onClose} />
      <Modal.Body>
        <div className="form-group">
          <div className="row">
            <div className="col-md-12">
              <label>Bron invoegen</label>
              <input
                type="text"
                name="title"
                value={sourceForm.title}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-link pull-left" onClick={onClose}>
          Annuleren
        </button>
        <button className="btn btn-primary" onClick={handleSubmit}>
          Ok
        </button>
      </Modal.Footer>
    </>
  );
};

SourceModal.displayName = 'SourceModal';

export default SourceModal;
