import { EditAsideColor } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import jQuery from 'jquery';
import 'jquery-minicolors/jquery.minicolors.css';
import 'jquery-minicolors/jquery.minicolors.min';
import { debounce, set } from 'lodash';
import { any } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideColor: React.FC<{
  config: EditAsideColor;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();

  const initialValue = useInitialValue(config.property);

  const readOnly = useReadOnly();

  const change = debounce((color: string) => {
    dispatch(
      patchNodeAction(getResourceKey(editHref), set({}, config.property, color), true, null, false)
    );
  }, 500);

  const inputRef = useRef(null);
  useEffect(() => {
    const ref = jQuery(inputRef.current);
    if (!readOnly && ref) {
      ref.minicolors({ position: 'bottom', theme: 'bootstrap', change });
    }
  }, [change, readOnly]);

  return (
    <div className={readOnly ? 'aside-component-disabled' : ''}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        {config.label && (
          <label className="control-label control-label-inline">{config.label}</label>
        )}
        <input
          id="color-input"
          className="form-control"
          type="text"
          defaultValue={initialValue}
          ref={inputRef}
          disabled={readOnly}
        />
      </AsideValidation>
    </div>
  );
};

AsideColor.displayName = 'AsideColor';

export default AsideColor;

AsideColor.propTypes = {
  config: any,
};
