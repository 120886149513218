import { ContentType } from '@generalTypes/apiTypes';
import { BuildingBlockNodeConfig, RequiredType } from '@nodeTypeConfig/configTypes';
import { selectGoalSubItem } from '@newStore/documentUI/transformContent/llinkidSelectors';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const LLINKID_EXTRA_GOAL_INFORMATION: BuildingBlockNodeConfig = {
  nodeSelector: selectGoalSubItem,
  information: {
    single: 'Extra',
    plural: "Extra's",
  },

  buildingBlocks: [],
  edit: [
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
      required: RequiredType.ERROR,
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'previousVersionItems',
      reactComponent: 'AsidePreviousVersionItems',
      label: 'Vervangt (uit vorige versie)',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidPreviousVersionModal',
        modalTitle: 'Vervangt (uit vorige versie)',
        listColumn: 'description',
        placeholder: 'Kies een te vervangen extra',
        relation: 'Extra',
        errorMessage: 'Je moet een geldige extra selecteren',
        type: ContentType.LLINKID_EXTRA_GOAL_INFORMATION,
      },
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'description',
  },
};

export default LLINKID_EXTRA_GOAL_INFORMATION;
