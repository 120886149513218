import { RootEditComponent } from '@nodeTypeConfig/configTypes';
import { defaultContactsParameters } from '@store/constants/constants';
import { selectChangeMessageForPersonPicker } from '@UI/aside/asidePersonPicker/asidePersonPickerSelectors';
import { entityHrefsArrayToString } from '../editComponentHelpers';

export const proContactsComponent: RootEditComponent = {
  component: 'contacts',
  property: 'contacts',
  label: 'Contactpersonen',
  reactComponent: 'AsidePersonPicker',
  asideChangeMessageSelector: selectChangeMessageForPersonPicker,
  valueToString: (c, a) => entityHrefsArrayToString(c.contacts, a),
  options: {
    queryParams: defaultContactsParameters,
  },
};
