import { ContentHref } from '@generalTypes/apiTypes';
import { selectChildren } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { getNodeTypeLabel } from '../validationHelpers';
import { createError } from '../createError';
import { ValidationRule } from '../validationTypes';

export const selectDoesEveryChildHasTitle: ValidationRule = createTypedSelector(
  (state, href: ContentHref) => selectChildren(state, href),
  (children) => {
    if (children.every((child) => child.title)) {
      return true;
    }
    return createError(
      'everyChildMustHaveTitle',
      'selectDoesEveryChildHasTitle',
      // if the rule will be used for other things than download we will have to make the message dynamic (suggestion: adding a customMessage to the ValidtionRule in de NodeTypeSelectorsMap per type)
      // but at this moment this rule is only used for downloads
      // for other attachments in attachments groups there is a modal that does not close when you do not fill in the title
      // (when working on the aside we can make sure the aside does not even close without adding a title and just remove this rule)
      `Vul de <strong>titel</strong> van de ${getNodeTypeLabel('download')} in`
    );
  }
);
