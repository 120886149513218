import {
  getBorderLeftColor,
  getUserColor,
} from '@UI/mainScreen/changeIndicators/ProposalIndicatorHelpers';
import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { Popover } from '@mui/material';
import { selectProposalMetaInfoAside } from '@newStore/documentUI/transformProposal/proposalSelectors';
import React, { useState } from 'react';
import {
  AudienceTabComponent,
  EditComponent,
  EditComponentWithAsideChangeMessageSelector,
} from '@nodeTypeConfig/configTypes';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import './AsideChangeIndicator.scss';

export type AsideChangeIndicatorProps = {
  config: EditComponent | AudienceTabComponent;
};

/**
 * Shows the aside change indicator for proposals
 * @param param0
 * @returns
 */
const AsideChangeIndicator: React.FC<AsideChangeIndicatorProps> = ({ config }) => {
  const changeMessage = useTypedSelector((state) => {
    if ('asideChangeMessageSelector' in config) {
      return config.asideChangeMessageSelector(
        state,
        state.documentUI.currentEditingNode as ContentHref,
        config as EditComponentWithAsideChangeMessageSelector
      );
    }
    console.warn('No asideChangeMessageSelector found in config', config);
    return null;
  });

  /// once all the node types are updated to use the new config, this will be changed to:
  // const changeMessage = useTypedSelector((state) =>
  //     config.asideChangeMessageSelector(
  //       state,
  //       state.documentUI.currentEditingNode as ContentHref,
  //       config
  //     )
  // });

  /// and change the AsideChangeIndicatorProps to
  // export type AsideChangeIndicatorProps = {
  //   config: EditComponentWithAsideChangeMessageSelector;
  // };

  const proposalMetaInfo = useTypedSelector((state: RootState) =>
    selectProposalMetaInfoAside(state, state.documentUI.currentEditingNode as ContentHref)
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const mode = useTypedSelector((state: RootState) => state.documentUI.mode);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (changeMessage === null) {
    return null;
  }

  if (!proposalMetaInfo) {
    return null;
  }

  return (
    <div className="redactie-proposal-indicator aside">
      <div className="proposal-line-container">
        <div
          className="line"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          style={{
            borderLeftStyle:
              proposalMetaInfo.status === 'SUBMITTED_FOR_REVIEW' ? 'solid' : 'dashed',
            borderLeftColor: getBorderLeftColor(proposalMetaInfo, mode),
            borderRight: proposalMetaInfo.authors.length > 1 ? `2px solid #3499eb` : undefined,
            paddingRight: proposalMetaInfo.authors.length > 1 ? '2px' : '4px',
          }}
        ></div>
      </div>
      {anchorEl && (
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={true}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          disableRestoreFocus
        >
          <div className="proposal-diff">
            <div className="proposal-diff-header">
              <span style={{ color: getUserColor(proposalMetaInfo) }}>
                {`Wijzigingen door:`}
                <strong>{` ${proposalMetaInfo.authorsDescription}${
                  proposalMetaInfo.submittedOn ? ` - ${proposalMetaInfo.submittedOn}` : ''
                }`}</strong>
              </span>
            </div>

            <div
              className="proposal-diff-html"
              dangerouslySetInnerHTML={{
                __html: changeMessage,
              }}
            ></div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default AsideChangeIndicator;
