import { ContentHref, ContentType, isReferencesRelation } from '@generalTypes/apiTypes';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectRawApiRelationsToAndFromMap,
} from '@newStore/documentApi/documentApiSelectors';
import { ContentRecord, RelationsToAndFromMap } from '@newStore/documentApi/documentApiTypes';
import { htmlDiffFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { selectProposedContentHrefsToDelete } from '@newStore/documentUI/transformProposal/proposalSelectors';
import { createTypedSelector, parentChildRelationFilter } from '@newStore/genericHelpers';
import { AsideChangeMessageSelector, EditComponent } from '@nodeTypeConfig/configTypes';

const getChildLinks = (
  relationsMap: RelationsToAndFromMap,
  content: ContentRecord,
  href: ContentHref
) => {
  const linkGroup = relationsMap.to[href]
    ?.filter(parentChildRelationFilter)
    .map((relation) => content[relation.from.href])
    .find((child) => child?.type === ContentType.LINK_GROUP);

  const linkGroupHref = linkGroup?.$$meta.permalink || href;

  if (!relationsMap.to[linkGroupHref]) {
    return [];
  }

  return relationsMap.to[linkGroupHref].flatMap((relation) => {
    const contentLink = content[relation.from.href];
    if (relation.relationtype === 'IS_PART_OF' && contentLink.type === ContentType.REFERENCE) {
      const referencesRelation = relationsMap.from[contentLink.$$meta.permalink].find((rel) =>
        isReferencesRelation(rel)
      );
      return {
        link: contentLink,
        referencesRelation,
      };
    }
    return [];
  });
};

export const selectAsideLinks = createTypedSelector(
  [
    (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectProposedContentHrefsToDelete(state),
    (state, href: ContentHref) => href,
  ],
  (relationsMap, content, proposedDeleteHrefs, href) => {
    return getChildLinks(relationsMap, content, href).map(({ link, referencesRelation }) => {
      return {
        key: link.key,
        title: link.title,
        href: referencesRelation?.to.href,
        isDeletedByProposal: proposedDeleteHrefs.includes(link.$$meta.permalink),
      };
    });
  }
);

export const selectChangeMessageForLinks: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state) => selectApiWithPendingChangesRelationsToAndFromMap(state),
      (state) => selectRawApiRelationsToAndFromMap(state),
      (state) => selectApiWithPendingChanges(state).content,
      (state, href: ContentHref, _config: EditComponent) => href,
      (state) => state.documentApi.content,
    ],
    (relationsMap, rawRelationsMap, content, href, rawContent): string | null => {
      const currentLinks = getChildLinks(relationsMap, content, href);
      const originalLinks = getChildLinks(rawRelationsMap, rawContent, href);

      const changes = currentLinks
        .map(({ link, referencesRelation }) => {
          if (link.$$meta.deleted) {
            return `<del>${link.title} (${referencesRelation?.to.href})</del>`;
          }

          const rawLink = originalLinks.find(
            (l) => l.link.$$meta.permalink === link.$$meta.permalink
          );
          if (rawLink) {
            const newHtml = `${link.title} (${referencesRelation?.to.href})`;
            const oldHtml = `${rawLink.link.title} (${rawLink.referencesRelation?.to.href})`;
            if (newHtml === oldHtml) {
              return null;
            }
            return htmlDiffFunction(newHtml, oldHtml);
          }

          return `<ins>${link.title} (${referencesRelation?.to.href})</ins>`;
        })
        .filter((z) => z !== null);

      if (changes.length === 0) {
        return null;
      }

      return `<ul>${changes.map((change) => `<li>Link: ${change}</li>`).join('')}</ul>`;
    }
  );
