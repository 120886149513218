import { EditAsideContactsOrCreators } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import React from 'react';
import PersonPicker from '@UI/personPicker/PersonPicker';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsidePersonPicker: React.FC<{
  config: EditAsideContactsOrCreators;
}> = ({ config }) => {
  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <label className="control-label">{config.label}</label>
      <PersonPicker config={config} />
    </AsideValidation>
  );
};

export default AsidePersonPicker;

AsidePersonPicker.displayName = 'AsidePersonPicker';

AsidePersonPicker.propTypes = {
  config: any,
};
