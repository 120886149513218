import { Content, ContentHref, RequiresRelation } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { createAction } from '@reduxjs/toolkit';
import {
  addRelationAction,
  dirtyNodeAction,
  removeRelationAction,
} from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { selectReferenceFramesFacetRelations } from '@UI/aside/asideWebFacets/AsideWebFacetsSelectors';
import { put, select, takeEvery } from 'redux-saga/effects';

export const updateFacetReferenceFrameValuesAction = createAction<{
  values: Content[];
  referenceFrameHref: ContentHref;
  nodeHref: ContentHref;
}>('documentChanges/updateFacetReferenceFrameValues');

function* updateFacetReferenceFrameValuesSaga(
  action: ReturnType<typeof updateFacetReferenceFrameValuesAction>
) {
  const { values, referenceFrameHref, nodeHref } = action.payload;

  const relations: RequiresRelation[] = yield select((state: RootState) =>
    selectReferenceFramesFacetRelations(state, nodeHref, referenceFrameHref)
  );

  const valueHrefs = new Set(values.map((value) => value.$$meta.permalink));

  let relationKeysToDelete: RequiresRelation[] = [];
  if (relations) {
    relationKeysToDelete = relations.filter((relation) => !valueHrefs.has(relation.from.href));

    for (const relation of relationKeysToDelete) {
      yield put(removeRelationAction(relation.key));
    }
  }

  if (values?.length) {
    for (const value of values) {
      const relation = relations.find((rel) => rel.from.href === value.$$meta.permalink);
      if (!relation) {
        yield put(
          addRelationAction({
            to: { href: nodeHref },
            from: { href: value.$$meta.permalink },
            relationtype: 'REQUIRES',
            strength: 'MEDIUM',
          })
        );
      }
    }
  }

  yield put(dirtyNodeAction(getResourceKey(nodeHref), false));
}

export function* watchDocumentChangesSaga() {
  yield takeEvery(updateFacetReferenceFrameValuesAction.type, updateFacetReferenceFrameValuesSaga);
}
