import { ContentHref } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { getDiffForArraysOfStrings } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideThemes,
  EditComponent,
  isEditAsideThemes,
} from '@nodeTypeConfig/configTypes';
import { selectOptionsForAsideReferenceFrames } from '@store/selectors/asideSelectors';
import { get } from 'lodash';

export const selectReferenceFramesSelectedOptions = createTypedSelector(
  [
    (state, _href, config: EditAsideThemes) =>
      selectOptionsForAsideReferenceFrames(
        state,
        config.options.referenceFrameHref,
        config.options.types
      ),
    (state, href: ContentHref, config: EditAsideThemes) =>
      get(selectContentItem(state, href), config.property),
  ],
  (options, values) => options.filter((option) => values?.includes(option.$$meta.permalink))
);

export const selectChangeMessageForThemes: AsideChangeMessageSelector<EditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, _href: ContentHref, config: EditComponent) =>
        isEditAsideThemes(config) &&
        selectOptionsForAsideReferenceFrames(
          state,
          config.options.referenceFrameHref,
          config.options.types
        ),
      (state, _href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, options, config): string | null => {
      if (!options) {
        return null;
      }
      if (!isEditAsideThemes(config)) {
        console.error('config is not for themes', config);
        throw Error('config is not for themes');
      }

      const selectedThemes = get(content, config.property);
      const rawSelectedThemes = get(originalContent, config.property);

      const optionsMap = new Map(options.map((option) => [option.$$meta.permalink, option.title]));

      const sortedOptions = options.map((z) => z.title as string).toSorted();

      const diff = getDiffForArraysOfStrings(
        rawSelectedThemes?.map((z) => optionsMap.get(z) || ''),
        selectedThemes?.map((z) => optionsMap.get(z) || ''),
        sortedOptions
      );

      return diff;
    }
  );
