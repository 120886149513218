import { EditAsideIdentifier } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { set } from 'lodash';
import { useCurrentEditNode } from '../../../hooks/UseCurrentEditNode';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import SimpleInput from './SimpleInput';

const AsideIdentifier: React.FC<{
  config: EditAsideIdentifier;
}> = ({ config }) => {
  const dispatch = useDispatch();

  const editHref = useEditHref();

  const currentEditNode = useCurrentEditNode();
  const initialValue = currentEditNode.identifiers?.[0] || '';

  const change = (value: string) => {
    dispatch(
      patchNodeAction(getResourceKey(editHref), set({}, config.property, value), true, null, false)
    );
  };

  const readOnly = useReadOnly();

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <SimpleInput
        defaultValue={initialValue}
        label={config.label}
        readOnly={readOnly}
        onChange={change}
      />
    </AsideValidation>
  );
};

AsideIdentifier.displayName = 'AsideIdentifier';

export default AsideIdentifier;

AsideIdentifier.propTypes = {
  config: any,
};
