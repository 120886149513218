import { isPerson, Person } from '@generalTypes/personApiTypes';
import { OrganisationalUnit, VosOrganisationalUnit } from '@generalTypes/samTypes';

type ResponsibilityFromSearch = {
  type: 'MAIN' | 'SECONDARY' | 'DELEGATION';
  description: string;
};

export type PersonFromSearch = Pick<Person, '$$meta' | 'firstName' | 'lastName'> & {
  fullName: string;
  responsibilities?: ResponsibilityFromSearch[];
};

type AddressFromSearch = {
  city: string;
  street: string;
  country: string;
  subCity: string;
  zipCode: string;
  streetHref: string;
  countryHref: string;
  houseNumber: string;
  subCityHref: string;
};

export type OUFromSearch = Pick<OrganisationalUnit, '$$meta'> & {
  name: string;
  seatAddress?: AddressFromSearch;
  mailAddresses?: Array<{
    address: AddressFromSearch;
  }>;
  locations?: Array<{
    name?: string;
    address: AddressFromSearch;
    permalink: string;
  }>;
};

export type PersonOptionType = PersonFromSearch | OUFromSearch;

export const isPersonFromSearch = (option: PersonOptionType): option is PersonFromSearch => {
  return option.$$meta.type === 'PERSON';
};

export const isOUFromSearch = (option: PersonOptionType): option is OUFromSearch => {
  return option.$$meta.type === 'ORGANISATIONAL_UNIT';
};

/**
 * get the person or OU display name from search items
 * @param option
 * @returns
 */
export const getPersonOptionDisplayname = (option: PersonOptionType) => {
  let title = '';
  if (isPerson(option)) {
    title = `${option.firstName} ${option.lastName}`;
  } else if ('name' in option) {
    title = option.name as string;
  }
  return title;
};

export type PersonOrOUTypeFromExternalData = Person | OrganisationalUnit | VosOrganisationalUnit;

export const getPersonOrOUDisplayName = (option: PersonOrOUTypeFromExternalData) => {
  if (isPerson(option)) {
    return `${option.firstName} ${option.lastName}`;
  }
  if ('$$name' in option) {
    return option.$$name;
  }
  if ('$$displayName' in option) {
    return option.$$displayName;
  }
  console.error('Could not get display name for option', option);
  return 'error';
};

export const getAddressString = (option: OUFromSearch): string => {
  const formatAddress = (address?: AddressFromSearch) =>
    address
      ? `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.subCity}`
      : '';

  const seatAddress = formatAddress(option.seatAddress);
  const locationAddress =
    option.locations && option.locations.length > 0
      ? formatAddress(option.locations[0].address)
      : '';
  const mailAddress =
    option.mailAddresses && option.mailAddresses.length > 0
      ? formatAddress(option.mailAddresses[0].address)
      : '';

  const address = seatAddress || locationAddress || mailAddress;

  return address;
};
