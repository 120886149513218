import { selectOdetSubsectionNode } from '@newStore/documentUI/transformContent/odetContentNodeSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';

const CURRICULUM_ODET_SUBSECTION: BuildingBlockNodeConfig = {
  nodeSelector: selectOdetSubsectionNode,
  information: {
    single: 'Subsectie',
    plural: "Subsectie's",
  },

  buildingBlocks: [
    { type: NodeType.CURRICULUM_ODET_SUBSECTION },
    { type: NodeType.CURRICULUM_ODET_DEVELOPMENT_GOAL },
    { type: NodeType.CURRICULUM_ODET_ENDTERM },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
  ],
  isCollapsible: true,
  documentViewIdentifier: 'i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_SUBSECTION;
