import { selectPlaceholderNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectValidateChildrenAreRequired } from '@newStore/validation/validationRules/childrenAreRequired';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';

const VIDEO_GROUP: BuildingBlockNodeConfig = {
  nodeSelector: selectPlaceholderNode,
  validationRules: [selectValidateChildrenAreRequired],
  information: {
    single: 'Videogroep',
    plural: 'Videogroepen',
    ribonIcon: require('../../../../img/buildingBlocks/VIDEO_GROUP.svg'),
  },
  buildingBlocks: [{ type: NodeType.VIDEO }],
  edit: [],
  showPlaceholder: true,
  isCollapsible: true,
  readOnlyNode: true,
  onNewNodeDropped: {
    openAside: false,
  },
};

export default VIDEO_GROUP;
