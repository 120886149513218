import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { EditAsideTitle, RequiredType } from '@nodeTypeConfig/configTypes';

export const titleComponent: EditAsideTitle = {
  component: 'title',
  reactComponent: 'AsideTitle',
  property: 'title',
  label: 'Titel',
  asideChangeMessageSelector: selectChangeMessageForHtmlString,
  required: RequiredType.ERROR,
};
