import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-image-crop/dist/ReactCrop.css';
import { Modal } from '@kathondvla/react-shared-components/src/components';
import './ImageCropSelector.scss';
import { getCroppedImg } from './imageUtils';
import { useReadOnly } from '../../hooks/UseReadonly';

interface ImageCropperProps {
  modalTitle?: string;
  ratio?: number | undefined;
  onClose?: () => void;
  onSubmit?: (prop: { file: File; base64: string; width: number; height: number }) => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  modalTitle = '',
  ratio,
  onClose = () => {},
  onSubmit,
}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Crop | null>(null);

  const readOnly = useReadOnly();

  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleCropChange = (completedCrop: Crop) => {
    setCroppedAreaPixels(completedCrop);
  };

  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const initialCrop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 90,
        },
        ratio || width / height,
        width,
        height
      ),
      width,
      height
    );

    setCrop(initialCrop);
  }

  const handleSubmit = async () => {
    if (selectedImage && croppedAreaPixels && imgRef.current) {
      const displayedImageWidth = imgRef.current.width;
      const displayedImageHeight = imgRef.current.height;
      const { file, base64, width, height } = await getCroppedImg(
        selectedImage,
        selectedImage?.name,
        croppedAreaPixels,
        displayedImageWidth,
        displayedImageHeight
      );
      if (onSubmit) {
        onSubmit({ file, base64, width, height });
      }
    }
    if (onClose) {
      onClose();
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  // Automatically trigger file input click when modal opens
  useEffect(() => {
    if (!selectedImage && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [selectedImage]);

  return (
    <>
      <Modal.Header title={modalTitle} onClose={onClose} />
      <Modal.Body>
        <div>
          {!selectedImage && (
            <span className="input-group">
              <span className="input-group-btn">
                <span className="btn btn-default btn-file">
                  Blader...
                  <input
                    type="file"
                    ref={fileInputRef}
                    readOnly={readOnly}
                    onChange={handleImageSelected}
                  />
                </span>
              </span>
              <input type="text" className="form-control" readOnly />
            </span>
          )}
          {previewUrl && (
            <>
              {/* @ts-expect-error crop not always defined */}
              <ReactCrop
                crop={crop}
                aspect={ratio}
                // locked={ratio !== 1}
                style={{ width: '100%' }}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={handleCropChange}
              >
                <img ref={imgRef} src={previewUrl} alt="Selected" onLoad={onImageLoad} />
              </ReactCrop>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button" onClick={onClose}>
          Annuleren
        </button>
        <button className="confirm-button" onClick={handleSubmit}>
          Toevoegen
        </button>
      </Modal.Footer>
    </>
  );
};

export default ImageCropper;
