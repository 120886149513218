import { RootState } from '@generalTypes/rootStateTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { updateFacetReferenceFrameValuesAction } from '@newStore/documentUI/documentChangesSagas';
import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { EditAsideReferenceFramePicker } from '@nodeTypeConfig/configTypes';
import { any } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOptionsForAsideReferenceFrames } from '@store/selectors/asideSelectors';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { selectReferenceFramesFacetSelectedOptions } from './AsideWebFacetsSelectors';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

export const AsideReferenceFramePicker: React.FC<{
  config: EditAsideReferenceFramePicker;
}> = ({ config }) => {
  const readOnly = useReadOnly();

  const href = useEditHref();
  const dispatch = useDispatch();

  const { referenceFrameHref } = config.options;

  useEffect(() => {
    if (referenceFrameHref) {
      dispatch(loadReferenceFrame({ referenceFrame: referenceFrameHref }));
    }
  }, [dispatch, referenceFrameHref]);

  const options = useTypedSelector((state: RootState) =>
    selectOptionsForAsideReferenceFrames(state, referenceFrameHref, config.options.types)
  );

  const selectedOptions = useTypedSelector((state: RootState) =>
    selectReferenceFramesFacetSelectedOptions(state, href, config)
  );

  const change = (values) => {
    dispatch(
      updateFacetReferenceFrameValuesAction({
        values,
        referenceFrameHref,
        nodeHref: href,
      })
    );
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      {/* TODO */} <AsideChangeIndicator config={config} /> {/* TODO */}
      <label className="control-label control-label-inline">{config.label}</label>
      {
        <KovResourcePicker
          localData={options}
          value={selectedOptions}
          onChange={change}
          disabled={readOnly}
          clearable={true}
          multiple={true}
          disableSearch={true}
          optionValue={(o) => o.$$meta.permalink}
          style={{ option: (provided) => ({ ...provided, cursor: 'pointer' }) }}
          optionTemplate={(option) => option.title}
        />
      }
    </AsideValidation>
  );
};

AsideReferenceFramePicker.displayName = 'AsideReferenceFramePicker';

export default AsideReferenceFramePicker;

AsideReferenceFramePicker.propTypes = {
  config: any,
};
