import {
  ExtendedBuildingBlockNodeConfig,
  ExtendedRootNodeConfig,
  NodeType,
} from '@nodeTypeConfig/configTypes';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectValidateAttachmentAudienceParentIntersectionNotEmpty } from '@newStore/validation/validationRules/audienceIntersectionRules';
import { proThemeTocTypes } from '@nodeTypeConfig/helpers/buildingBlocks/proThemeTocTypes';
import { proTileStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proTileStandardComponents';
import { proDocumentStandardComponents } from '@nodeTypeConfig/helpers/editComponents/proDocumentStandardComponents';
import { proAliasesComponent } from '@nodeTypeConfig/helpers/editComponents/proAliasesComponent';
import { proLinksAndMenuReferences } from '@nodeTypeConfig/helpers/editComponents/proLinksAndMenuReferences';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { proPageAttachmentsComponents as proPageAttachmentsComponent } from '@nodeTypeConfig/helpers/editComponents/proPageAttachmentsComponents';
import { extendsSection } from '../../helpers/genericConfigHelpers';
import {
  proTileDefaultValidionRules,
  proThemeDefaultValidationRules,
} from '../proGenericConfigProps';
import { proDefaultBuildingBlocks } from '../../helpers/buildingBlocks/proDefaultBuildingBlocks';

const proTextPageConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  information: {
    description:
      'Pro textPage (webTemplate code THEME_PAGE [not a very good name]) can be a SECTION (a tile) in a home or can be the root (single page pro theme)',
    single: 'PRO.-tegel: Tekstpagina',
    plural: "Tekstpagin's",
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  buildingBlocks: proDefaultBuildingBlocks,
};

// Extends SECTION => A SECTION inside a Pro Theme and WebConfig THEME_PAGE becomes a PRO_TEXT_PAGE_TILE
export const PRO_TEXT_PAGE_TILE: ExtendedBuildingBlockNodeConfig = {
  ...proTextPageConfig,
  validationRules: [
    ...proTileDefaultValidionRules,
    selectValidateAttachmentAudienceParentIntersectionNotEmpty,
  ],
  ...extendsSection,
  isCollapsedByDefault: true,
  edit: [
    ...proTileStandardComponents,
    proAliasesComponent,
    proPageAttachmentsComponent,
    ...proLinksAndMenuReferences,
  ],
  addAccessRightsToAudienceTab: true,
  confirmDelete: true,
};

// Extends WEBPAGE2 => When adding THEME_PAGE WebConfig to a WEBPAGE2 it becomes a PRO_TEXT_PAGE_STANDALONE
export const PRO_TEXT_PAGE_STANDALONE: ExtendedRootNodeConfig = {
  ...proTextPageConfig,
  validationRules: [
    ...proThemeDefaultValidationRules,
    selectValidateAttachmentAudienceParentIntersectionNotEmpty,
  ],
  information: {
    ...proTextPageConfig.information,
    definiteArticle: true,
    single: 'PRO.-thema: Tekstpagina',
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: proThemeTocTypes,
  edit: [
    ...proDocumentStandardComponents,
    proAliasesComponent,
    proPageAttachmentsComponent,
    ...proLinksAndMenuReferences,
  ],
  audienceTab: proThemeAudienceTab,
  addAccessRightsToAudienceTab: true,
};
