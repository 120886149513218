import { selectOdetTitleNode } from '@newStore/documentUI/transformContent/odetContentNodeSelectors';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const CURRICULUM_ODET: RootNodeConfig = {
  nodeSelector: selectOdetTitleNode,
  information: {
    definiteArticle: true,
    single: 'Decretal leerplan',
    plural: 'Decretale leerplannen',
    icon: require('../../../../img/icons/odet.svg'),
    category: 'LEERPLAN',
  },

  buildingBlocks: [{ type: NodeType.CURRICULUM_ODET_LEVEL }],
  tocTypes: [
    NodeType.CURRICULUM_ODET_LEVEL,
    NodeType.CURRICULUM_ODET_LEERGEBIED,
    NodeType.CURRICULUM_ODET_KEY_COMPETENCE,
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
    },
    publishComponent,
  ],
  isSearchable: true,
  allowSuggestions: false,
};

export default CURRICULUM_ODET;
