import { Content, ContentHref, ContentKey, Proposal } from '@generalTypes/apiTypes';
import { PrivateState } from '@generalTypes/personApiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import {
  selectApiWithPendingChanges,
  selectApiWithPendingChangesRelationsToAndFromMap,
  selectAreContentAndProposalsLoaded,
  // selectApiWithPendingChanges,
  selectDocumentRoot,
  selectIsWebpagesFetchedOrNotRequired,
} from '@newStore/documentApi/documentApiSelectors';
import { RelationsToAndFromMap } from '@newStore/documentApi/documentApiTypes';
import { selectUserVmForDocumentList } from '@newStore/documentList/newDocumentListSelectors';
import { getPathsToRootWithIsIncludedIn } from '@newStore/externalData/externalDataHelpers';
import {
  selectAllowedAbilities,
  selectSecurityUserRoles,
  selectUserHref,
} from '@newStore/user/userSelectors';
import { UserRole } from '@newStore/user/userTypes';
import { HoverPositionEnum, NodeType } from '@nodeTypeConfig/configTypes';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  addAttachment,
  addNodeToParentNodeAction,
  addTeaser,
  clearSelectionsAction,
  importDocumentInSectionAction,
  moveSelectionsToParentNodeAction,
  updateAsideViewModelAction,
} from '@store/actions/documentActions';
import { addNotificationAction } from '@store/actions/notificationActions';
import { privateStateApi } from '@store/api/apiConfig';
import {
  DOCUMENT_PUBLISHED,
  DOCUMENT_SAVED,
  UPDATE_DOCUMENT_VIEW_MODEL,
} from '@store/constants/actionTypes';
import { getBase64, getResourceKey } from '@store/helpers/documentHelpers';
import { waitFor } from '@store/helpers/sagaUtils';
import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import uuidv4 from 'uuid/v4';
import { settings } from '../../config/settings';
import { createNewPrivateState } from './documentUIHelpers';
import { selectAllCollapsibleNodes, selectDefaultCollapsedNodes } from './documentUISelectors';
import {
  DropItemAction,
  UpdateLastReadAction,
  dropItem,
  newNodeDropped,
  selectAreSomeNodesCollapsed,
  setCollapsedNodes,
  setMode,
  setNodeThatShouldBeFocused,
  setPrivateState,
  setSelectedDocument,
  toggleAllCollapsed,
  unCollapseNode,
  updateLastRead,
} from './documentUIState';
import {
  selectAppliedNodeConfig,
  selectDocumentRootType,
  selectGenericDocumentRootConfig,
  selectGenericDocumentRootType,
} from './nodeTypeConfigSelectors';

const fetchPrivateSate = (documentHref, userHref) => {
  return privateStateApi.getAll('/privatestates', {
    owner: userHref,
    context: settings.privateState.component,
    'state.root': documentHref,
  });
};

const putPrivateState = (privateState) => {
  return privateStateApi.put(privateState.$$meta.permalink, privateState);
};

function* savePrivateState() {
  const privateState: PrivateState | null = yield select(
    (state: RootState) => state.documentUI.privateState
  );
  try {
    yield call(putPrivateState, privateState);
    yield put({ type: UPDATE_DOCUMENT_VIEW_MODEL });
  } catch (error) {
    console.error(error);
    yield put(
      addNotificationAction({
        type: 'WARNING',
        message: `"Alles bekeken" (paarse markering) kan niet juist worden weergegeven`,
      })
    );
  }
}

function* savePrivateStateUpdateLastRead(action: UpdateLastReadAction) {
  yield call(savePrivateState);
  if (!action.payload || !action.payload.doNotShowLastReadUpdatedNotification) {
    yield put(addNotificationAction({ type: 'SUCCESS', message: 'lastRead.markUpdated' }));
  }
}

function* initialiseDefaultCollapsedState() {
  yield call(waitFor, selectAreContentAndProposalsLoaded);
  yield call(waitFor, selectIsWebpagesFetchedOrNotRequired);
  yield call(waitFor, (state) => selectApiWithPendingChanges(state).relations.length);
  const defaultCollapsedNodes: ReturnType<typeof selectDefaultCollapsedNodes> = yield select(
    selectDefaultCollapsedNodes
  );
  yield put(setCollapsedNodes({ collapsedNodes: defaultCollapsedNodes }));
}

function* unCollapseNodeSaga(action: PayloadAction<{ nodeHref: ContentHref }>) {
  const relations: RelationsToAndFromMap = yield select((state: RootState) =>
    selectApiWithPendingChangesRelationsToAndFromMap(state)
  );

  const pathsToRoot: ContentHref[][] = getPathsToRootWithIsIncludedIn(
    relations,
    action.payload.nodeHref
  );

  const ancestorHrefs = [...new Set(pathsToRoot.flat())];

  const collapsedNodes = yield select((state: RootState) => state.documentUI.collapsedNodes);
  const collapsedAncestors = ancestorHrefs.filter((nodeHref) => collapsedNodes[nodeHref]);
  if (collapsedAncestors.length) {
    yield put(
      setCollapsedNodes({
        collapsedNodes: Object.fromEntries(collapsedAncestors.map((nodeHref) => [nodeHref, false])),
      })
    );
  }
}

function* toggleAllCollapsedSaga() {
  const collapsedNodes: Record<string, boolean> = yield select(
    (state: RootState) => state.documentUI.collapsedNodes
  );
  const areNodesCollapsed = yield select(selectAreSomeNodesCollapsed);

  if (areNodesCollapsed) {
    yield put(
      setCollapsedNodes({
        collapsedNodes: Object.fromEntries(Object.keys(collapsedNodes).map((key) => [key, false])),
      })
    );
  } else {
    const collapsibleNodes: ReturnType<typeof selectAllCollapsibleNodes> = yield select(
      selectAllCollapsibleNodes
    );

    yield put(
      setCollapsedNodes({
        collapsedNodes: collapsibleNodes,
      })
    );
  }
}

function* initialisePrivateState() {
  try {
    yield call(waitFor, selectAreContentAndProposalsLoaded);
    const root: Content = yield select(selectDocumentRoot);
    if (!root) {
      return;
    }
    const userHref: string = yield select(selectUserHref);
    const privateStates = yield call(fetchPrivateSate, root.$$meta.permalink, userHref);
    if (privateStates.length > 0) {
      yield put(setPrivateState({ privateState: privateStates[0] }));
      return;
    }
    yield put(
      setPrivateState({
        privateState: createNewPrivateState(root.$$meta.permalink, userHref, []),
      })
    );
  } catch (error) {
    console.error(error);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'Er is een onverwachte fout opgetreden bij het ophalen van de private state.',
      })
    );
  }
}

function* onDocumentSaved() {
  const rootType: NodeType = yield select(selectDocumentRootType);
  if (rootType !== NodeType.PRONEWSLETTER) {
    yield put(updateLastRead({ doNotShowLastReadUpdatedNotification: true }));
  }
}

function* dropItemSaga(action: DropItemAction) {
  yield put({ type: UPDATE_DOCUMENT_VIEW_MODEL });
  const modalWindowService = getAngularService('ModalWindowService');

  const { droppedItems, parentHref, siblingHref, position } = action.payload;
  console.log(droppedItems, parentHref, position);

  const isNewNode = droppedItems.some((z) => 'newNode' in z && z.newNode === true);

  if (isNewNode && droppedItems.length > 1) {
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'Je kan nieuwe nodes slechts 1 per 1 toevoegen.',
      })
    );
    return;
  }

  const levelParent = yield select((state: RootState) =>
    state.document.viewModel.flatWithHiddens.find((z) => z.key === parentHref.split('/').pop())
  );

  let positionIndex = 1;

  if (siblingHref !== null) {
    const arrIndex = levelParent.$$children.findIndex(
      (child) => child.key === siblingHref.split('/').pop()
    );

    if (position === HoverPositionEnum.ABOVE) {
      positionIndex += arrIndex;
    } else {
      positionIndex += arrIndex + 1;
    }
  }

  if (!isNewNode) {
    const relationKeys = droppedItems.map((z) => z.relation.key);
    yield put(moveSelectionsToParentNodeAction(levelParent.key, positionIndex, relationKeys));
    const areWeDroppingASelection: boolean = yield select((state: RootState) =>
      state.document.selections.includes(relationKeys[0])
    );
    if (areWeDroppingASelection) {
      yield put(clearSelectionsAction());
    }
  } else {
    const [droppedItem] = droppedItems;

    if (isNewNode && droppedItem.node.type === NodeType.WORD_IMPORT) {
      const opts = {
        component: 'documentImportModal',
        dataForModal: { parentDocument: levelParent, positionIndex },
      };

      const importResult = yield modalWindowService.open(opts);

      console.log('Imported nodes:', importResult.resources);

      // mutating some stuff here, as it was
      importResult.attachmentsToUpload.forEach(async (upload) => {
        const base64 = await getBase64(upload.file);
        upload.$$base64 = base64;
      });
      yield put(importDocumentInSectionAction(importResult, levelParent.key));
    } else if (droppedItem.node.type === 'TEASER') {
      const referenceFrameThemes = yield select(
        (state: RootState) => state.document.viewModel.referenceFrameThemes
      );
      const opts = {
        component: 'teaserModal',
        dataForModal: {
          referenceFrameThemes,
          parentDocumentThemes: levelParent.themes,
        },
      };

      const importResult = yield modalWindowService.open(opts);

      yield put(addTeaser(levelParent.key, positionIndex, importResult.teaser));
    } else {
      const newKey = uuidv4();
      yield put(
        addNodeToParentNodeAction(
          levelParent.key,
          positionIndex,
          droppedItem.node.type,
          undefined,
          newKey
        )
      );

      if ('file' in droppedItem && droppedItem.file) {
        const { file } = droppedItem;
        if (/\.(jpe?g|png)$/i.test(file.name)) {
          const base64 = yield getBase64(file);
          const newAttachment = {
            key: uuidv4(),
            newKey: uuidv4(),
            type: 'ILLUSTRATION',
            name: file.name,
            size: file.size,
            $$base64: base64,
            isNew: true,
          };

          yield put(addAttachment(newKey, newAttachment, file));
        }
      }
    }
  }
}

// const getNonFunctionValues = (options) => Object.entries(options).filter(([k]) => k !== 'filter');

// const isOptionsEqual = (a, b) => getNonFunctionValues(a).every(([k, v]) => shallowEqual(v, b[k]));

// const isEditEqual = (a: EditComponent, b: EditComponent) => {
//   return (
//     a.component === b.component &&
//     ((!a.options && !b.options) || (a.options && b.options && isOptionsEqual(a.options, b.options)))
//   );
// };

// const areEditsEqual = (a: Array<EditComponent>, b: Array<EditComponent>) =>
//   a.length === b.length && a.every((e) => b.some((e2) => isEditEqual(e, e2)));

// const tellMe = (a: Array<EditComponent>, b: Array<EditComponent>) =>
//   a.filter((e) => !b.some((e2) => isEditEqual(e, e2)));

// function* checkAsideItemAndChildren(nodeHref: ContentHref) {
//   try {
//     if (!nodeHref.startsWith('/content')) {
//       // you get errors if you try to generate aside for commons
//       return;
//     }
//     const content: Content = yield select((state) => selectContentItem(state, nodeHref));
//     const nodeType = yield content && select((state) => selectNodeType(state, nodeHref));
//     const label = `${nodeType} ${content?.title || content?.$$html?.substring(0, 20)}`;
//     const asideNewOldEdit = yield select((state) => selectOldEditConfigForNode(state, nodeHref));
//     const asideNewEdit = yield select((state) => selectEditConfigForNode(state, nodeHref));
//     const asideNode = yield select((state) => {
//       const editKey = nodeHref.split('/').pop() as string;
//       const newApi = selectNewDocumentApi(state);
//       const newAside = generateAsideViewModel(editKey, state.document.viewModel.flat[0], {
//         ...state.document,
//         api: newApi,
//       });
//       return newAside;
//     });
//     const asideOldVm = asideNode.editDocument.$$editSections;

//     const showAttachmentsGroup =
//       asideNode.editDocument.type === 'ATTACHMENTS_GROUP' ||
//       asideNode.editDocument.type === 'SHARED_ATTACHMENTS_GROUP' ||
//       isAttachmentsGroupOnNodeLevelAllowed(asideNode.editDocument);

//     if (!shallowEqual(asideNewOldEdit, asideOldVm)) {
//       console.warn(
//         'Aside edit configs not the same with old edit',
//         nodeHref,
//         asideNewOldEdit,
//         asideOldVm
//       );
//     }

//     const filterOldEdit = (z) => {
//       if (['namedSet', 'coverage', 'accessRights', 'webFacets'].includes(z.component)) {
//         return false;
//       }
//       if (nodeType === 'SECTION' && z.label === 'Tegelfoto') {
//         // Tegelfoto stond te veel in SECTION, dat moet enkel als je een tegel bent of een Pro Thema (want die zijn een tegel in de menu)
//         return false;
//       }
//       if (!showAttachmentsGroup && z.component === 'attachmentsGroup') {
//         return false;
//       }
//       return true;
//     };
//     const filterNewEdit = (z) => {
//       if (['namedSet', 'coverage', 'accessRights', 'webFacets'].includes(z.component)) {
//         return false;
//       }
//       if (nodeType === 'SECTION' && z.label === 'Tegelfoto') {
//         // Tegelfoto stond te vele in SECTION, dat moet enkel als je een tegel bent of een Pro Thema (want die zijn een tegel in de menu)
//         return false;
//       }
//       return true;
//     };
//     const relevantNewEdit = asideNewEdit.filter(filterNewEdit);
//     const relevantOldEdit = asideNewOldEdit.filter(filterOldEdit);

//     if (!areEditsEqual(relevantNewEdit, relevantOldEdit)) {
//       console.warn(
//         'Aside edit configs not the same with NEW SPLIT edit',
//         `${label} - ${nodeHref}`,
//         relevantNewEdit,
//         relevantOldEdit,
//         tellMe(relevantNewEdit, relevantOldEdit)
//       );
//     }
//   } catch (e) {
//     console.error('Error checking aside item', e);
//   }

//   const children: ContentHref[] = yield select((state) =>
//     selectContentNodeChildren(state, nodeHref)
//   );
//   for (const child of children) {
//     yield checkAsideItemAndChildren(child);
//   }
// }

// // @ts-expect-error not used for now
// function* checkAsideEditSaga() {
//   yield call(waitFor, selectAreContentAndProposalsLoaded);
//   yield call(waitFor, selectIsWebpagesFetchedOrNotRequired);
//   yield take('UPDATE_DOCUMENT_VIEW_MODEL_DEBOUNCED');
//   yield delay(3000);
//   console.log('RUNNING CHECK ASIDE EDIT SAGA');
//   const selectedDocument = yield select((state: RootState) => state.documentUI.currentDocument);
//   if (!selectedDocument) {
//     return;
//   }
//   yield checkAsideItemAndChildren(selectedDocument);
// }

function* calculateDefaultMode() {
  try {
    yield call(waitFor, (state: RootState) => state.documentApi.isContentAndRelationsFetched);
    yield call(waitFor, (state: RootState) => selectIsWebpagesFetchedOrNotRequired(state));
    yield call(waitFor, (state: RootState) => state.newUser.areUserRolesFetched);
    const rootDocument: Content = yield select(selectDocumentRoot);
    const nodeType: NodeType = yield select(selectGenericDocumentRootType);
    const nodeTypeConfig: ReturnType<typeof selectGenericDocumentRootConfig> = yield select(
      selectGenericDocumentRootConfig
    );
    if (
      (nodeType === NodeType.LLINKID_CURRICULUM ||
        nodeType === NodeType.LLINKID_FOUNDATIONAL_CURRICULUM) &&
      rootDocument.issued
    ) {
      const security = yield select(selectUserVmForDocumentList);
      const { publishedEditables } = security;
      const isUserAllowedToEdit = publishedEditables.some((e) => e.type === rootDocument.type);
      yield put(setMode(isUserAllowedToEdit ? 'EDIT' : 'READ_ONLY'));
    } else if (nodeTypeConfig?.allowSuggestions) {
      yield call(waitFor, (state: RootState) => state.documentApi.isProposalsFetched);
      const proposals: Proposal[] = yield select((state: RootState) =>
        Object.values(state.documentApi.proposals)
      );
      if (!rootDocument.issued && proposals.length === 0) {
        // document type works with suggestions but this is a concept
        yield put(setMode('EDIT'));
        return;
      }
      // * TODO define WWW user roles in security because you can be pro reviewer but not a www reviewer
      // and then you will still get into Review mode
      // * for security there is only the whole pro which is everything about pro including news and there is www
      // if you can allowSuggestions and it is not WWW then we assume it is Pro
      const wwwTypes: NodeType[] = [
        NodeType.WWW,
        NodeType.PRESS,
        NodeType.JOB_OFFER,
        NodeType.WWWNEWSITEM,
      ];
      const proUserRoles: UserRole = yield select(selectSecurityUserRoles);
      const allowedAbilities = yield select(selectAllowedAbilities);
      const isReviewer =
        (wwwTypes.includes(nodeType) && allowedAbilities.includes('REVIEW')) ||
        proUserRoles.includes('pro_webmaster') ||
        proUserRoles.includes('pro_editor');
      if (isReviewer) {
        const hasSubimttedProposal = proposals.some((p) => p.status === 'SUBMITTED_FOR_REVIEW');
        yield put(setMode(hasSubimttedProposal ? 'REVIEW' : 'EDIT'));
      } else {
        yield put(setMode('SUGGEST'));
      }
    } else {
      // this document type does not work with suggestions it is just EDIT
      yield put(setMode('EDIT'));
    }
  } catch (e) {
    console.error('Something went wrong calculating the default mode', e);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'Er is een onverwachte fout opgetreden bij het bereken van de mode.',
      })
    );
    yield put(setMode('EDIT'));
  }
}

function* onNewNodeDropped(action: PayloadAction<{ newHref: ContentHref }>) {
  const { newHref } = action.payload;
  const nodeTypeConfig: ReturnType<typeof selectAppliedNodeConfig> = yield select(
    (state: RootState) => selectAppliedNodeConfig(state, newHref)
  );
  if ('onNewNodeDropped' in nodeTypeConfig) {
    if (nodeTypeConfig.onNewNodeDropped.openAside) {
      const newKey = getResourceKey(newHref) as ContentKey;
      getAngularService('$state').go('edit.aside', { editKey: newKey });
      yield put(updateAsideViewModelAction(newKey));
    } else if (nodeTypeConfig.onNewNodeDropped.focusOnField) {
      yield put(
        setNodeThatShouldBeFocused({
          field: nodeTypeConfig.onNewNodeDropped.focusOnField,
          href: newHref,
        })
      );
    }
  }
}

/**
 * make sure that the state does not keep saying it should focus on this node indefinetely
 */
function* resetFocussedNode() {
  // give InlineEditor component time to react on the state.nodeThatShouldBeFocused before setting it to null again
  const value = yield select((state: RootState) => state.documentUI.nodeThatShouldBeFocused);
  if (value !== null) {
    yield delay(500);
    yield put(setNodeThatShouldBeFocused(null));
  }
}

export function* documentUISaga() {
  yield takeEvery(setSelectedDocument.match, initialisePrivateState);
  yield takeEvery(setSelectedDocument.match, initialiseDefaultCollapsedState);
  // yield takeEvery(setSelectedDocument.match, checkAsideEditSaga);

  yield takeEvery([setSelectedDocument, DOCUMENT_PUBLISHED], calculateDefaultMode);

  yield takeEvery(toggleAllCollapsed.match, toggleAllCollapsedSaga);
  yield takeLatest(updateLastRead.match, savePrivateStateUpdateLastRead);
  yield takeLatest(DOCUMENT_SAVED, onDocumentSaved);
  yield takeLatest(unCollapseNode, unCollapseNodeSaga);
  yield takeEvery(dropItem.match, dropItemSaga);
  yield takeLatest(newNodeDropped, onNewNodeDropped);
  yield takeLatest(setNodeThatShouldBeFocused, resetFocussedNode);
}
