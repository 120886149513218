/* eslint-disable max-len */
import { pathToRootTemplate } from '@store/helpers/documentAsideHelpers';
import { escapeRegExp } from 'lodash';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

require('./asideMenuItemReference.scss');

class asideMenuItemReference {
  constructor($scope, $ngRedux, contentApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.contentApi = contentApi;
    this.type = 'external';
  }

  setDefaultReferenceRelation() {
    // set the correct radio type selected according to reference relation if present
    if (this.referenceRelation) {
      if (this.referenceRelation.to.$$expanded) {
        this.selectedWebpage = this.referenceRelation.to.$$expanded;
        this.type = 'webpage';
      } else {
        this.externalUrl = this.referenceRelation.to.href;
      }

      this.defaultReferenceLoaded = true;
    }
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const referenceRelation =
        state.document.viewModel.aside.editDocument.referenceRelationsFrom?.length > 0
          ? state.document.viewModel.aside.editDocument.referenceRelationsFrom.find(
              (rel) => !rel.to.href.match(/^\/content\//) || rel.to.$$expanded
            )
          : undefined;

      if (!this.defaultReferenceLoaded) {
        this.setDefaultReferenceRelation();
      }

      return {
        referenceRelation,
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);

    this.filterParams = this.sConfig.options.filter;
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(e, model) {
    if (model) {
      this.updateReferenceRelation(model.$$meta.permalink);
    } else {
      this.deleteReferenceRelation();
    }

    this.type = 'webpage';
    this.externalUrl = undefined;
    this.selectedWebpage = model;
  }

  externalUrlChanged() {
    if (this.externalUrl) {
      this.updateReferenceRelation(this.externalUrl);

      if (this.selectedWebpage) {
        this.selectedWebpage = undefined;
      }
      this.type = 'external';
    } else if (!this.selectedWebpage) {
      this.deleteReferenceRelation();
    }
  }

  updateReferenceRelation(toHref) {
    if (this.referenceRelation) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.patchRelationAction(this.referenceRelation.key, { to: { href: toHref } })
      );
    } else {
      const newRelation = {
        relationtype: 'REFERENCES',
        from: {
          href: `/content/${this.editKey}`,
        },
        to: {
          href: toHref,
        },
      };
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    }
  }

  deleteReferenceRelation() {
    if (this.referenceRelation) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(this.referenceRelation.key));
    }
  }

  displayName(item) {
    return pathToRootTemplate(item);
  }

  filterData(data, str) {
    if (str.length === 0) {
      return data;
    }
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  }
}

export default {
  template: require('./asideMenuItemReference.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<',
  },
  controller: asideMenuItemReference,
};
