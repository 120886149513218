import { Content, ContentHref } from '@generalTypes/apiTypes';
import { selectContentItem } from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector, getAttachmentUrl } from '@newStore/genericHelpers';

import { NodeType } from '@nodeTypeConfig/configTypes';
import { ImageNode } from '../documentUITypes';
import { getIllustration } from './helpers';

export const selectImageNode = createTypedSelector(
  [(state, href: ContentHref) => selectContentItem(state, href)],
  (content: Content): ImageNode => {
    const image = getIllustration(content.attachments, 800);
    return {
      href: content.$$meta.permalink,
      key: content.key,
      type: NodeType.IMAGE,
      src: image && getAttachmentUrl(image),
      customRender: true,
    };
  }
);
