import { EditAsideSubjects } from '@nodeTypeConfig/configTypes';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { any } from 'prop-types';
import React from 'react';
import { patchNodeAction } from '@store/actions/documentActions';
import { set } from 'lodash';
import CustomAutocomplete from '@UI/autocomplete/CustomAutocomplete';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import { useReadOnly } from '../../../hooks/UseReadonly';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';

const AsideSubjects: React.FC<{
  config: EditAsideSubjects;
}> = ({ config }) => {
  const editHref = useEditHref();
  const dispatch = useDispatch();
  const initialValue = useInitialValue(config.property);
  const readOnly = useReadOnly();

  const onChange = (event, newValue) => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, newValue)));
  };

  return (
    <div className={`${readOnly ? 'aside-component-disabled' : ''}`}>
      <AsideValidation property={config.property} component={config.component}>
        <AsideChangeIndicator config={config} />
        <label className="control-label">{config.label}</label>
        <div>
          <CustomAutocomplete
            multiple
            freeText
            defaultValue={initialValue}
            onChange={onChange}
            readOnly={readOnly}
          />
        </div>
      </AsideValidation>
    </div>
  );
};

AsideSubjects.displayName = 'AsideSubjects';

export default AsideSubjects;

AsideSubjects.propTypes = {
  config: any,
};
