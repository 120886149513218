import { ExtendedRootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { proThemeAudienceTab } from '@nodeTypeConfig/helpers/editComponents/proThemeAudienceTab';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
// Extends WEBPAGE2 => When adding HOME_PAGE WebConfig to a WEBPAGE2 it becomes "the" (there is only one) PRO_HOME_PAGE
// technically also a Pro Theme because it is tag WEBPAGE2, but actually the Pro Menu which is not a Pro Theme Page and something totally different
export const PRO_HOME_PAGE: ExtendedRootNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [],
  information: {
    description:
      'The Pro Home page: just one page which is the actual navigation/menu structure for PRO',
    single: 'Pro Home Pagina',
    plural: 'Pro Home Pagina',
    icon: require('../../../../../img/icons/webpage.svg'),
  },
  extends: NodeType.WEBPAGE2,
  tocTypes: [NodeType.PRO_MENU_LEVEL_2, NodeType.PRO_MENU_LEVEL_3, NodeType.PRO_MENU_SECTION],
  buildingBlocks: [{ type: NodeType.SECTION }],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      ...imageComponentBase,
      width: '6',
      label: 'Afbeelding voor Social media',
      options: {
        type: 'THUMBNAIL',
        crop: {
          aspectRatio: 4 / 3,
          resize: {
            width: 710,
            height: 533,
            quality: 0.6,
          },
        },
        fields: ['alt'],
      },
    },
    {
      ...authorsComponent,
      label: 'Themaverantwoordelijke',
    },
    publishComponent,
  ],
  audienceTab: proThemeAudienceTab,
};
