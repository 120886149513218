import { CoverageHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';
import { selectIsRoot } from '@newStore/documentUI/documentUISelectors';
import { EditAsideCoverage } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { Region, regions } from '@store/helpers/dioceses';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { set } from 'lodash';
import { any } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRootItem } from '@newStore/documentApi/documentApiSelectors';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useInitialValue } from '../../../hooks/UseInitialValue';
import AsideValidation from '../asideValidationErrors/AsideValidation';

import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { selectCurrentSelectedCoverageOptions, selectIsReadOnly } from './asideCoverageSelectors';
import './AsideCoverage.scss';

const AsideCoverage: React.FC<{
  config: EditAsideCoverage;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const readOnly = useSelector((state: RootState) => selectIsReadOnly(state, config.property));

  const itemValue: CoverageHref[] | undefined = useInitialValue(config.property);
  const hasRootCoverage = useSelector((state: RootState) =>
    Boolean(selectRootItem(state)?.[config.property]?.length)
  );
  const isRoot = useSelector((state: RootState) => selectIsRoot(state, editHref));

  const currentCoverageOptions: Region[] = useSelector((state: RootState) =>
    selectCurrentSelectedCoverageOptions(state, editHref, config)
  );

  const isInherited = !isRoot && !itemValue;

  const onChange = (values) => {
    const newValue = values.map((r) => r.$$meta.permalink);
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, newValue)));
  };

  const clearAll = () => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, [])));
  };

  const selectAll = () => {
    const allRegions = regions.map((r) => r.$$meta.permalink);
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, allRegions)));
  };

  const backToInherited = () => {
    dispatch(patchNodeAction(getResourceKey(editHref), set({}, config.property, undefined)));
  };

  return (
    <AsideValidation property={config.property} component={config.component}>
      <AsideChangeIndicator config={config} />
      <div className={`coverage${isInherited ? ' inherited' : ''}`}>
        {!isRoot && !hasRootCoverage && (
          <>
            <label className="control-label">{`${config.label} ${isRoot ? '*' : ''}`}</label>
            <div>
              <label className="warning-label">
                {`De root heeft geen ${config.label} gedefinieerd. Voeg het eerst toe aan het hoofdniveau.`}
              </label>
            </div>
          </>
        )}
        {(isRoot || hasRootCoverage) && (
          <>
            <div className="title">
              <label className="control-label">{`${config.label} ${isRoot ? '*' : ''}`}</label>
              {isInherited && <span className="inherited-info">Overgenomen</span>}
              {!isInherited && !isRoot && (
                <span className="inherited-revert" onClick={backToInherited}>
                  Overnemen
                </span>
              )}
            </div>

            <KovResourcePicker
              value={currentCoverageOptions}
              localData={regions}
              clearable={true}
              disabled={readOnly}
              onChange={onChange}
              placeholder={`Selecteer een ${config.labelSingular}...`}
              multiple={true}
              menuPlacement="bottom"
              disableSearch={true}
              optionValue={(o) => o.$$meta.permalink}
              optionTemplate={(option) => option.name}
              className="coverage-picker"
            />

            {!readOnly && (
              <div className="col-md-12 coverage">
                <a onClick={clearAll}>Alles wissen</a>
                <a onClick={selectAll} style={{ marginLeft: '10px' }}>
                  Alles selecteren
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </AsideValidation>
  );
};

export default AsideCoverage;

AsideCoverage.displayName = 'AsideCoverage';

AsideCoverage.propTypes = {
  config: any,
};
