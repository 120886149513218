import { ImageNode } from '@newStore/documentUI/documentUITypes';

const ImageRow: React.FC<{ contentNode: ImageNode }> = ({ contentNode }) => {
  return (
    <div className="center">
      {contentNode.src && (
        <img
          src={contentNode.src}
          style={{ marginBottom: '10px', maxWidth: '70%', maxHeight: '300px' }}
          draggable="false"
        />
      )}
    </div>
  );
};

export default ImageRow;
