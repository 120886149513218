import React, { useState, useEffect, useCallback } from 'react';

import { EditAsideVideo } from '@nodeTypeConfig/configTypes';
import {
  patchNodeAttachmentAction,
  removeNodeAttachmentAction,
} from '@store/actions/documentActions';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { getEmbedVideoLinkFrom, isValidVideoUrl } from '@store/helpers/videoHelpers';
import { any } from 'prop-types';
import { useDispatch } from 'react-redux';
import uuidv4 from 'uuid/v4';
import { debounce } from 'lodash';
import { useEditHref } from '../../../hooks/UseEditHref';
import { useReadOnly } from '../../../hooks/UseReadonly';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import AsideValidation from '../asideValidationErrors/AsideValidation';
import AsideChangeIndicator from '../changeIndicators/asideChangeIndicator/AsideChangeIndicator';
import { selectVideoAttachment } from './asideVideoSelectors';

const AsideVideo: React.FC<{
  config: EditAsideVideo;
}> = ({ config }) => {
  const dispatch = useDispatch();
  const editHref = useEditHref();
  const video = useTypedSelector((state) => selectVideoAttachment(state, editHref, config));
  const [playbackUrl, setPlaybackUrl] = useState(video?.playbackUrl || '');

  const readOnly = useReadOnly();

  const videoSource = playbackUrl ? getEmbedVideoLinkFrom(playbackUrl) : '';
  const isValid = isValidVideoUrl(playbackUrl);

  const updateVideo = useCallback(
    (value: string) => {
      dispatch(
        patchNodeAttachmentAction(
          getResourceKey(editHref),
          video?.key,
          {
            key: video?.key || uuidv4(),
            type: config.options.type,
            playbackUrl: value,
          },
          true
        )
      );
    },
    [dispatch, editHref, video?.key, config.options.type]
  );

  const debouncedUpdateVideo = useCallback(
    debounce((value: string) => updateVideo(value), 500),
    [updateVideo]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPlaybackUrl(value);
    debouncedUpdateVideo(value);
  };

  const removeVideo = () => {
    dispatch(removeNodeAttachmentAction(getResourceKey(editHref), video?.key));
  };

  useEffect(() => {
    setPlaybackUrl(video?.playbackUrl || '');
  }, [video?.playbackUrl]);

  return (
    <>
      <div className={`panel panel-default ${readOnly ? 'aside-component-disabled' : ''}`}>
        <AsideChangeIndicator config={config} />
        {config.label && <label className="panel-heading control-label">{config.label}</label>}
        <div className="panel-body">
          <AsideValidation property={config.property} component={config.component}>
            <div className="form-group">
              {playbackUrl && isValid && (
                <div className="row" style={{ marginBottom: '15px' }}>
                  <div className="col-md-12">
                    <iframe
                      width="480"
                      height="295"
                      frameBorder="0"
                      allowFullScreen
                      src={videoSource}
                    ></iframe>
                  </div>
                </div>
              )}

              <div className="row" style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div className="col-md-10">
                  <label className="control-label">URL</label>
                  <input
                    type="text"
                    value={playbackUrl}
                    onChange={handleInputChange}
                    disabled={readOnly}
                    className="form-control"
                  />
                </div>
                {playbackUrl && (
                  <div className="col-md-2">
                    <div>
                      {!readOnly && (
                        <button className="btn btn-danger" onClick={removeVideo}>
                          <span className="glyphicon glyphicon-remove"></span>
                          <span>Verwijder</span>
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AsideValidation>
        </div>
      </div>
    </>
  );
};

export default AsideVideo;

AsideVideo.displayName = 'AsideVideo';

AsideVideo.propTypes = {
  config: any,
};
