import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const LLINKID_CONCORDANCE: BuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Concordantie (S)MD/BK',
    plural: 'Concordanties (S)MD/BK',
  },
  createDefaults: {
    title: 'Minimumdoelen, specifieke minimumdoelen of doelen die leiden naar BK',
  },
  buildingBlocks: [],
  edit: [
    titleComponent,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Concordaties',
    },
  ],
  isCollapsible: false,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default LLINKID_CONCORDANCE;
