import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';

const SUMMARY: BuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  information: {
    single: 'Samenvatting',
    plural: 'Samenvattingen',
    ribonIcon: require('../../../../img/buildingBlocks/SUMMARY.svg'),
  },

  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE_GROUP },
    { type: NodeType.IMAGE },
    { type: NodeType.VIDEO },
  ],
  edit: [titleComponent],
  isCollapsible: true,
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default SUMMARY;
