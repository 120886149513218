import { PersonHref } from '@generalTypes/apiTypes';
import { Person } from '@generalTypes/personApiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { selectPersons } from '@newStore/externalData/externalDataSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import { NodeType } from '@nodeTypeConfig/configTypes';
import { settings } from '../../config/settings';
import { getAllowedTypes, getUrlsForPath } from './userHelpers';

const emptyArray = [];

export const selectSecurityData = (state: RootState): Record<string, string[]> | null =>
  state.newUser.securityData;

export const selectUser = (state: RootState): Person | null =>
  state.newUser.personHref ? selectPersons(state)[state.newUser.personHref] : null;

export const selectUserHref = (state: RootState): PersonHref | null =>
  state.newUser.personHref as PersonHref;

export const selectIsUserLoaded = (state: RootState): boolean =>
  state.newUser.isLoading === false && selectUser(state) !== undefined;

export const selectAllowedAbilities = createTypedSelector(
  [selectSecurityData],
  (securityData: Record<string, string[]> | null): string[] => {
    if (!securityData) {
      return [];
    }
    const allowedAbilities: string[] = [];

    settings.securityModes.forEach((mode) => {
      if (mode.rawUrl && securityData[mode.type].includes(mode.rawUrl)) {
        allowedAbilities.push(mode.type);
      }
    });

    if (
      getUrlsForPath(securityData.UPDATE_CONTENT, '/content/hashes').some((rawUrl) => {
        const keys = rawUrl.searchParams.get('keyIn')?.split(',') || [];
        return keys && keys.includes(settings.newsHash);
      })
    ) {
      allowedAbilities.push('UPDATE_NEWS');
    }

    return allowedAbilities;
  }
);

export const selectHasUserReviewAbility = (state: RootState) =>
  selectAllowedAbilities(state).includes('REVIEW');

export const selectHasUserUpdateNewsAbility = (state: RootState) =>
  selectAllowedAbilities(state).includes('UPDATE_NEWS');

export const selectHasUserSuggestAbility = (state: RootState) =>
  selectAllowedAbilities(state).includes('SUGGEST');

export const selectSecurityUserRoles = (state: RootState) => state.newUser.userRoles;

export const selectPublishedEditableTypes = createTypedSelector(
  [(state) => selectSecurityData(state)],
  (securityData: Record<string, string[]> | null): NodeType[] => {
    if (!securityData) {
      return emptyArray;
    }

    const allowedTypes = getAllowedTypes(securityData);

    return allowedTypes.filter((t) => t.isCreatable && t.isPublishedEditable).map((z) => z.type);
  }
);
