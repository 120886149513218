import { showValidationErrors } from '@newStore/documentList/newDocumentListState';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { getAllOfResource } from '@newStore/externalData/externalDataState';
import { NodeType, RootEditComponent, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { createNewsLetterTemplateValidationCmd } from '@store/commands/documentListCommands';
import { PRONEWSLETTER } from './PRONEWSLETTER';

const PRONEWSLETTERTEMPLATE: RootNodeConfig = {
  ...PRONEWSLETTER,
  nodeSelector: selectTitleNode,
  validationRules: [],
  createValidations: [
    {
      cmd: createNewsLetterTemplateValidationCmd,
      failAction: showValidationErrors,
    },
  ],
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbriefsjabloon',
    plural: 'PRO.-nieuwsbriefsjablonen',
    icon: require('../../../../../img/icons/document.svg'),
    category: 'PRO',
  },
  createModal: {
    authors: { addUser: true },
    newsLetterType: { required: true },
  },
  preloadActions: [getAllOfResource({ resource: '/newsletter/senders', refresh: false })],
  tocTypes: [NodeType.SECTION, NodeType.PRO_NEWSLETTER_SECTION],
  edit: PRONEWSLETTER.edit.filter((e) => e.component !== 'dateToSend') as Array<RootEditComponent>,
};

export default PRONEWSLETTERTEMPLATE;
