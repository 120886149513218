import { createTypedSelector } from '@newStore/genericHelpers';
import { selectIsReferenceFrameLoaded } from '@newStore/referenceFrames/referenceFramesSelectors';
import { RequiredType } from '@nodeTypeConfig/configTypes';
import { selectReferenceFramesSelectedOptions } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { pick } from 'lodash';
import { VALIDATION_PENDING } from '../validationHelpers';
import { createError } from '../createError';
import { AsideValidationRule } from '../validationTypes';

export const selectEmptyThemesRule: AsideValidationRule = createTypedSelector(
  [
    (state, _href, _parentHref, config) =>
      selectIsReferenceFrameLoaded(state, config.options.referenceFrameHref),
    (state, href, _parentHref, config) => selectReferenceFramesSelectedOptions(state, href, config),
    (state, _href, _parentHref, config) => config,
  ],
  (isReferenceFrameLoaded, selectedOptions, config) => {
    if (!isReferenceFrameLoaded) {
      return VALIDATION_PENDING;
    }

    if (selectedOptions.length === 0) {
      return createError(
        `emptyThemes.${config.property}.${config.component}`,
        'selectEmptyThemesRule',
        config.customRequiredMessage ||
          'Als je dit veld niet invult, dan verschijnt het item in geen enkele databank.',
        pick(config, ['component', 'property']),
        RequiredType.WARNING
      );
    }

    return true;
  }
);

export const validateNotEmptyThemes = { rule: selectEmptyThemesRule };
