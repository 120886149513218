import { Content, ContentHref, VideoAttachmentMetaInfo } from '@generalTypes/apiTypes';
import {
  selectContentItem,
  selectRawContentItem,
} from '@newStore/documentApi/documentApiSelectors';
import { htmlDiffFunction } from '@newStore/documentUI/transformProposal/asideDiffText';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  AsideChangeMessageSelector,
  EditAsideVideo,
  EditComponent,
  isEditAsideVideo,
  ReactEditComponent,
} from '@nodeTypeConfig/configTypes';
import { getEmbedVideoLinkFrom, isValidVideoUrl } from '@store/helpers/videoHelpers';
import { get, isEqual } from 'lodash';

const getVideoUrl = (content: Content, config: EditAsideVideo) =>
  get(content, config.property)?.filter(
    (a): a is VideoAttachmentMetaInfo => a.type === config.options?.type
  )?.[0]?.playbackUrl ?? '';

export const selectVideoAttachment = createTypedSelector(
  [
    (state, href: ContentHref, config: EditAsideVideo) =>
      get(selectContentItem(state, href), config.property),
    (state, href: ContentHref, config: EditAsideVideo) => config.options.type,
  ],
  (attachments, type) => {
    return attachments.find((a): a is VideoAttachmentMetaInfo => a.type === type) || null;
  }
);

const generateHtml = (videoUrl: string) => {
  if (!videoUrl || !isValidVideoUrl(videoUrl)) {
    return '';
  }
  return `
      <iframe
        frameBorder="0"
        src="${getEmbedVideoLinkFrom(videoUrl)}"
      ></iframe>
      <br />
      <span>${videoUrl}</span>
      `;
};

export const selectChangeMessageForVideoAttachments: AsideChangeMessageSelector<ReactEditComponent> =
  createTypedSelector(
    [
      (state, href: ContentHref) => selectContentItem(state, href),
      (state, href: ContentHref) => selectRawContentItem(state, href),
      (state, href: ContentHref, config: EditComponent) => config,
    ],
    (content, originalContent, config): string | null => {
      if (!config.property) {
        console.error('No property found for config', config);
        throw Error(`Can not convert ${config.property} to a string`);
      }

      if (!isEditAsideVideo(config)) {
        console.error('config is not a video', config);
        throw Error('config is not a video');
      }

      const newValue = getVideoUrl(content, config);
      const oldValue = getVideoUrl(originalContent, config);

      const newHtml = generateHtml(newValue);
      const oldHtml = generateHtml(oldValue);

      if (isEqual(newHtml, oldHtml)) {
        return null;
      }

      return htmlDiffFunction(newHtml, oldHtml);
    }
  );
