import { NodeType } from '@nodeTypeConfig/configTypes';

export const proThemeTocTypes: Array<NodeType> = [
  NodeType.PRO_THEME_SECTION,
  NodeType.PRO_TEXT_PAGE_TILE,
  NodeType.PRO_DATABASE_TILE,
  NodeType.PRO_BLOG_TILE,
  NodeType.PRO_DOWNLOAD_PAGE_TILE,
  NodeType.PRO_FAQ_PAGE_TILE,
  NodeType.PRO_CURRICULUM_PAGE_TILE,
  NodeType.PRO_TEMPORARY_PAGE_TILE,
  NodeType.PRO_BLOG_ITEM,
  NodeType.PRO_DATABASE_ITEM,
  NodeType.FAQ_GROUP,
];
