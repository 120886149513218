import {
  Content,
  ContentHref,
  ContentRelation,
  ContentRelationHref,
  Proposal,
  isContentHref,
  isCreateChange,
  isIncludedInRelation,
  isPatchChange,
} from '@generalTypes/apiTypes';
import {
  AudienceTabComponent,
  FilterableEditComponent,
  EditComponentProperty,
  NodeTypeConfigApplied,
} from '@nodeTypeConfig/configTypes';
// TODO see if fast-json-patch can do the text diff for us and get rid of Diff
import Diff from 'text-diff';
import { Operation, compare } from 'fast-json-patch';
import { AllExternalData } from '@newStore/externalData/externalDataTypes';
import { stripHtml } from '@newStore/genericHelpers';
import { get } from 'lodash';
import { DiffMessage, ProposalType } from '../documentUITypes';
import {
  filterRelevantAttachments,
  getDiffMessagesForAttachments,
} from './attachmentsDiffTextCalculator';
import { getMessageForReferenceRelation } from './referenceRelationDiffTextCalculator';
import { wrapNodeTypeLabel, wrapPropertyLabel } from './proposalHelpers';

export const getProposalType = (
  content: Content,
  proposal: Proposal,
  proposedHrefsToDelete: Array<ContentHref>
): ProposalType => {
  const href = content.$$meta.permalink;
  if (proposedHrefsToDelete.includes(href)) {
    return 'DELETE';
  }
  if (
    proposal.listOfRequestedChanges.some(
      (c) =>
        isCreateChange(c) &&
        ((c.appliesTo.href === href && isContentHref(c.appliesTo.href)) ||
          (c.relatedTo?.href === href && isIncludedInRelation(c.resource)))
    )
  ) {
    return 'CREATE';
  }
  return 'UPDATE';
};

// -- Diff box util functions -- //
export function diffsToHtml(diffs) {
  const html: string[] = [];
  const patternBR = /\n/g;
  for (let x = 0; x < diffs.length; x += 1) {
    const op = diffs[x][0];
    const data = diffs[x][1];
    const text = decodeURIComponent(data).replace(patternBR, '<br/>');
    switch (op) {
      case 1:
        html[x] = `<ins>${text}</ins>`;
        break;
      case -1:
        html[x] = `<del>${text}</del>`;
        break;
      case 0:
        html[x] = `<span>${text}</span>`;
        break;
      default:
        break;
    }
  }
  return html.join('');
}

export const getValue = (
  content: Content,
  component: FilterableEditComponent | AudienceTabComponent,
  allExternalData: AllExternalData
): string => {
  if (component.valueToString) {
    return component.valueToString(content, allExternalData);
  }
  const property = component.property || component.component;
  const value = get(content, property);
  if (value && typeof value !== 'string') {
    throw Error(`Can not convert ${property} to a string`);
  }
  return value ? stripHtml(value) : '';
};

export const getMessageForOldVersusNewDiff = (
  content: Content,
  oldContent: Content,
  component: FilterableEditComponent | AudienceTabComponent,
  allExternalData: AllExternalData
): string => {
  const propertyLabel = component.label;
  try {
    const newValue = getValue(content, component, allExternalData);
    const oldValue = getValue(oldContent, component, allExternalData);
    const diff = new Diff();
    // produces diff array
    const textDiffs = diff.main(oldValue, newValue || '');
    diff.cleanupSemantic(textDiffs);
    // produces a formatted HTML string
    return wrapPropertyLabel`${propertyLabel}: ${
      'isInheritedProperty' in component && component.isInheritedProperty && !oldValue
        ? '<del>[overgenomen]</del>'
        : ''
    }${diffsToHtml(textDiffs)}`;
  } catch (e) {
    console.warn(e.message);
    return wrapPropertyLabel`${propertyLabel} aangepast`;
  }
};

export type DiffTextInput = {
  href: ContentHref;
  proposal: Proposal;
  content: Content;
  rawContent?: Content;
  proposedHrefsToDelete: Array<ContentHref>;
  nodeTypeConfig: NodeTypeConfigApplied;
  externalData: AllExternalData;
  relationsMap: Record<ContentRelationHref, ContentRelation>;
};

const stripPropertiesToIgnore = (c: Content): Partial<Content> => {
  const origWithoutAtt = { ...c } as Partial<Content>;
  origWithoutAtt.attachments = c.attachments.filter(filterRelevantAttachments);
  delete origWithoutAtt.$$meta;
  delete origWithoutAtt.outypes;
  delete origWithoutAtt.mainstructures;
  return origWithoutAtt;
};

export const getDiffMessages = ({
  proposal,
  content,
  rawContent,
  nodeTypeConfig,
  proposedHrefsToDelete,
  externalData,
  relationsMap,
}: DiffTextInput): Array<DiffMessage> => {
  const type = getProposalType(content, proposal, proposedHrefsToDelete);
  const nodeTypeLabel = nodeTypeConfig.information.single.split(':').pop();

  // item deleted
  if (type === 'DELETE') {
    const deletedValue = rawContent && rawContent.title;
    return [
      {
        message: deletedValue
          ? wrapNodeTypeLabel`${nodeTypeLabel} "${deletedValue}" verwijderd`
          : wrapNodeTypeLabel`${nodeTypeLabel} verwijderd`,
      },
    ];
  }

  // item created
  if (type === 'CREATE' || !rawContent) {
    return [{ message: wrapNodeTypeLabel`${nodeTypeLabel} toegevoegd` }];
  }

  // content updated
  const editIncludingAudiences = [...nodeTypeConfig.edit, ...(nodeTypeConfig.audienceTab || [])];
  const contentPatches = compare(
    stripPropertiesToIgnore(rawContent),
    stripPropertiesToIgnore(content)
  );
  const unresolvedPatches: Array<Operation> = [];
  const diffMessagesLinkedToComponent: Record<number, DiffMessage> = {};

  let hasAttachmentsChanges = false;
  contentPatches.forEach((patch) => {
    if (patch.path.startsWith('/attachments')) {
      hasAttachmentsChanges = true;
      return;
    }
    const propertyPath = patch.path.substring(1).replace('/', '.');
    // EditComonent property name can be property || component.
    // But for instance 'TegelTekst' uses component 'description', property 'shortDescription'
    // so make sure to first look on property and only if that fails look on component name.
    let componentIndex = editIncludingAudiences.findIndex(
      (c) => c.property && propertyPath.startsWith(c.property)
    );
    if (componentIndex === -1) {
      componentIndex = editIncludingAudiences.findIndex((c) =>
        propertyPath.startsWith(c.component)
      );
    }
    if (!diffMessagesLinkedToComponent[componentIndex]) {
      // only process component if it is not handled yet:
      // if you remove for example the 3th element in a array of 5 you will have 3 patches for the same property: remove the last + change the 3th and 4th item
      if (componentIndex !== -1) {
        const component = editIncludingAudiences[componentIndex];
        const property = component.property || (component.component as EditComponentProperty); // TODO: do we still need component.component in the future?
        diffMessagesLinkedToComponent[componentIndex] = {
          message: getMessageForOldVersusNewDiff(content, rawContent, component, externalData),
          property,
        };
      } else {
        unresolvedPatches.push(patch);
      }
    }
  });

  // convert ObjectMap with indexes to have messages in the same order as in the aside
  const diffMessages = Object.values(diffMessagesLinkedToComponent);

  if (unresolvedPatches.length > 0) {
    // Handle paths which could not be linked to a component...
    console.log('unresolved patches', unresolvedPatches);
    diffMessages.push({ message: 'Metadata aangepast' });
  }

  // content.attachment changes
  if (hasAttachmentsChanges) {
    // attachment changes are now after all other content changes, we could put them in the map on conmponent index to have them in same order as in aside...
    const attachmentsDiffMessages = getDiffMessagesForAttachments(
      proposal,
      content,
      rawContent,
      nodeTypeConfig
    );
    attachmentsDiffMessages.forEach((dm) => {
      diffMessages.push(dm);
    });
  }

  let nodeReplaced = false;
  let webConfiguartionChanged = false;

  proposal.listOfRequestedChanges.forEach((c) => {
    if (isPatchChange(c) && c.patch.some((p) => p.path === '/readorder')) {
      nodeReplaced = true;
    }
    if (c.appliesTo.href.startsWith('/web/pages')) {
      webConfiguartionChanged = true;
    }

    if (
      c.appliesTo.href.startsWith('/content/relations') &&
      relationsMap[c.appliesTo.href]?.relationtype === 'REFERENCES' &&
      relationsMap[c.appliesTo.href]?.strength !== 'LOW'
    ) {
      diffMessages.push(getMessageForReferenceRelation(c, nodeTypeConfig));
    }
  });

  if (webConfiguartionChanged) {
    // WebPage can be further elaborated. For now we just say the webconfiguration changed if there is at least one requested change about /web/pages
    const webConfigLabel = 'Webconfiguratie';
    diffMessages.push({ message: wrapNodeTypeLabel`${webConfigLabel} aangepast` });
  }

  if (nodeReplaced) {
    diffMessages.push({
      message: wrapNodeTypeLabel`${nodeTypeLabel} verplaatst`,
      // property: 'parentRelation.readorder',
    });
  }

  if (!diffMessages.length) {
    // this is the final catch-all, for cases where the CONTENT is not changed, but perhaps relations are added or removed
    diffMessages.push({ message: 'Metadata aangepast' });
  }

  return diffMessages;
};
