import { AudienceTabComponent } from '@nodeTypeConfig/configTypes';
import { selectChangeMessageForNamedSets } from '@UI/aside/asideNamedSet/asideNamedSetSelectors';
import { selectChangeMessageForCoverage } from '@UI/aside/asideCoverage/asideCoverageSelectors';
import { coverageHrefsToString } from '../editComponentHelpers';

export const proThemeAudienceTab: Array<AudienceTabComponent> = [
  {
    component: 'namedSet',
    reactComponent: 'AsideNamedSet',
    property: 'mainstructuresOuTypeCombinations',
    label: 'Onderwijsniveaus en instellingstypes',
    labelSingular: 'Onderwijsniveau en instellingstype',
    tag: 'mainstructure_outype_ext_comm',
    isInheritedProperty: true,
    asideChangeMessageSelector: selectChangeMessageForNamedSets,
    // valueToString: msAndOuTypesHrefsToString, TODO
  },
  {
    component: 'coverage',
    reactComponent: 'AsideCoverage',
    property: 'coverage',
    label: "Regio's",
    labelSingular: 'Regio',
    isInheritedProperty: true,
    asideChangeMessageSelector: selectChangeMessageForCoverage,
    valueToString: coverageHrefsToString,
  },
];
