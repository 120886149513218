import { ContentType } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { NodeType, RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import {
  validateWebConfigurationRequired,
  validateWebTemplateConfig,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { selectChangeMessageForLinks } from '@UI/aside/asideLinks/asideLinksSelectors';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import constants from '../../../reduxLoop/constants/constants';
import { settings } from '../../settings';
import { wwwDefaultBuildingBlocks, wwwTitleAndDescriptionComps } from './wwwGenericConfigProps';

export const WWW: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  information: {
    definiteArticle: true,
    single: 'WWW.-inhoud',
    plural: 'WWW.-inhoud',
    icon: require('../../../../img/icons/webpage.svg'),
    category: 'WWW',
  },
  onLoadActions: [loadWebConfigurations()],
  createModal: {
    theme: {
      filter: {
        root: '085bc39d-c7a6-4209-bc7d-22962d23724b',
        typeIn: ContentType.THEME,
      },
    },
    authors: {
      addUser: true,
    },
    importDocx: {},
  },
  buildingBlocks: wwwDefaultBuildingBlocks,
  referenceGroupTypes: [
    constants.referenceGroupTypes.WWW_NEWS_ITEM,
    constants.referenceGroupTypes.WWW_CURRENT_THEME,
  ],
  tocTypes: [NodeType.SECTION],
  edit: [
    ...wwwTitleAndDescriptionComps,
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'shortdescription',
      width: '12',
      label: 'Tegeltekst',
      view: {
        hideIfEmpty: true,
      },
    },
    {
      ...imageComponentBase,
      width: '6',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        crop: {}, // Crop without enforced aspectRatio
        fields: [],
      },
    },
    authorsComponent,
    publishComponent,
    {
      component: 'linkGroup',
      reactComponent: 'AsideLinks',
      asideChangeMessageSelector: selectChangeMessageForLinks,
      width: '12',
      label: 'Gerelateerd thema on PRO',
      options: {
        type: 'group',
        modalTitle: 'Gerelateerd thema on PRO invoegen',
      },
      hiddenChildren: {
        containerType: ContentType.LINK_GROUP,
        items: [
          {
            type: ContentType.REFERENCE,
            label: 'link',
          },
        ],
      },
    },
  ],
  customEditorOptions: {
    hideTermButton: true,
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
};
