import { WebSiteHref, WebTemplateHref } from '@generalTypes/apiTypes';
import { loadWebConfigurations } from '@newStore/documentApi/documentApiState';
import { RootNodeConfig } from '@nodeTypeConfig/configTypes';
import { importanceComponent } from '@nodeTypeConfig/helpers/editComponents/importanceComponent';
import { publishComponent } from '@nodeTypeConfig/helpers/editComponents/publishComponent';
import { authorsComponent } from '@nodeTypeConfig/helpers/editComponents/authorsComponent';
import { selectTitleWithDescriptionNode } from '@newStore/documentUI/transformContent/genericSelectors';
import {
  validateWebConfigurationRequired,
  validateWebTemplateConfig,
} from '@newStore/validation/validationRules/webConfigurationValidationRules';
import * as apiRoutes from '../../../reduxLoop/api/apiRoutes';
import { settings } from '../../settings';
import {
  wwwDefaultBuildingBlocks,
  wwwOmslagAndTegelFotoComps,
  wwwTitleAndDescriptionComps,
} from './wwwGenericConfigProps';

export const PRESS: RootNodeConfig = {
  nodeSelector: selectTitleWithDescriptionNode,
  validationRules: [validateWebConfigurationRequired, validateWebTemplateConfig],
  information: {
    definiteArticle: true,
    single: 'Pers',
    plural: 'Pers',
    icon: require('../../../../img/icons/document.svg'),
  },
  onLoadActions: [loadWebConfigurations()],
  webconfiguration: {
    type: 'WWW_NEWS_DETAIL',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.www}` as WebSiteHref,
    },
    template: {
      href: '/web/templates/514777b4-4657-4590-835e-7ff9d6e99b22' as WebTemplateHref,
    },
  },
  createModal: {
    authors: {
      addUser: false,
    },
  },
  tocTypes: [],
  buildingBlocks: wwwDefaultBuildingBlocks,
  edit: [
    ...wwwTitleAndDescriptionComps,
    ...wwwOmslagAndTegelFotoComps,
    authorsComponent,
    publishComponent,
    importanceComponent,
  ],
  customEditorOptions: {
    hideTermButton: true,
  },
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
};
