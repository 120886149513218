import { ContentHref } from '@generalTypes/apiTypes';
import { createTypedSelector } from '@newStore/genericHelpers';
import { EditAsideVideo, EditComponent, RequiredType } from '@nodeTypeConfig/configTypes';
import { isValidVideoUrl } from '@store/helpers/videoHelpers';
import { selectVideoAttachment } from '@UI/aside/asideVideo/asideVideoSelectors';
import { pick } from 'lodash';
import { createError } from '../createError';
import { AsideValidationRule } from '../validationTypes';

export const selectIsVideoLinkValidRule: AsideValidationRule = createTypedSelector(
  [
    (state, href: ContentHref, _parentHref: ContentHref | undefined, config: EditComponent) =>
      selectVideoAttachment(state, href, config as EditAsideVideo),
    (state, href: ContentHref, _parentHref: ContentHref | undefined, config: EditComponent) =>
      config,
  ],
  (videoAttachment, config) => {
    if (videoAttachment?.playbackUrl && !isValidVideoUrl(videoAttachment.playbackUrl)) {
      return createError(
        `videoUrl.${config.property}.${config.component}`,
        'selectIsVideoLinkValidRule',
        'Foutieve YouTube, Vimeo or Microsoftstream link.',
        pick(config, ['component', 'property']),
        RequiredType.ERROR
      );
    }

    return true;
  }
);

export const validateVideoLink = { rule: selectIsVideoLinkValidRule };
