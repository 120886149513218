import { getAccessRightsToStringFunction } from '../editComponentHelpers';

export const proAccessRightsComponent = {
  component: 'accessRights',
  property: 'descendantsAccessRights',
  label: 'Afscherming',
  labelSingular: 'Afscherming',
  isInheritedProperty: true,
  valueToString: getAccessRightsToStringFunction('descendantsAccessRights'),
} as const;
