import { patchNodeAction } from '@store/actions/documentActions';
import { Editor as ReactEditor } from '@tinymce/tinymce-react';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// TinyMCE so the global var exists
import tinymce, { Editor, EditorEvent, RawEditorOptions } from 'tinymce/tinymce';
// DOM model

import 'tinymce/models/dom/model';
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';

import { RootState } from '@generalTypes/rootStateTypes';

import {
  selectFieldEditConfigForNode,
  selectGenericDocumentRootConfig,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { config as fieldConfig } from '@store/constants/editor';
import { string, bool, func } from 'prop-types';
import { ContentHref } from '@generalTypes/apiTypes';
import { set } from 'lodash';
import { countCharacters } from '../../../reduxLoop/helpers/documentHelpers';
// eslint-disable-next-line import/extensions, import/no-unresolved, import/no-webpack-loader-syntax
import contentCss from '!!raw-loader!./Editor.css';
import './MarkButton';
import './TermButton';
import './CustomLink';
import { pasteHtml } from './editorHelpers';

const AsideEditor: React.FC<{
  initial?: string;
  field?: string;
  skey?: string;
  onTextChange?: (text: string) => void;
  readOnly?: boolean;
  placeholder?: string;
  showCharacterCount?: boolean;
  inline?: boolean;
  plainText?: boolean;
}> = ({
  initial = '',
  field,
  skey,
  onTextChange,
  readOnly = false,
  showCharacterCount = false,
  inline = false,
  plainText = false,
  placeholder = '',
}) => {
  const dispatch = useDispatch();
  const [editor, setEditor] = useState<Editor | null>(null);
  const [plainTextContent, setPlainTextContent] = useState(initial);
  const [numCharacters, setNumCharacters] = useState(countCharacters(initial));

  const maxCharacters = useSelector(
    (state: RootState) =>
      skey &&
      field &&
      (
        selectFieldEditConfigForNode(state, `/content/${skey}` as ContentHref, field) as {
          maxLength?: number;
        }
      )?.maxLength
  );

  const customEditorOptions = useSelector(
    (state: RootState) => selectGenericDocumentRootConfig(state)?.customEditorOptions
  );
  const hasTerm =
    !customEditorOptions?.hideTermButton && fieldConfig[field].customButtons.includes('term');
  const hasMark =
    customEditorOptions?.showMarkerButton && fieldConfig[field].customButtons.includes('marker');

  const config: RawEditorOptions & { selector?: undefined; target?: undefined } = useMemo(() => {
    // eslint-disable-next-line no-unused-expressions
    tinymce; // used to make sure tinymce exists ( bundler )
    let toolbar = 'bold italic subscript superscript | removeformat undo redo | ';
    if (!fieldConfig[field].reducedToolbar) {
      toolbar += 'numlist bullist | customLink unlink | table | ';
    }
    if (hasTerm) {
      toolbar += 'term ';
    }
    if (hasMark) {
      toolbar += 'mark ';
    }

    return {
      height: 235,
      menubar: false,
      plugins: ['anchor', 'link', 'lists', 'table', 'term', 'mark', 'customLink'],
      content_style: `body { font-family: Arial; font-size: 15px; } ${contentCss}`,
      inline,
      skin: false,
      content_css: false,
      contextmenu: false,
      convert_urls: false,
      branding: false,
      elementpath: false,
      license_key: 'gpl',
      table_cell_advtab: true,
      invalid_styles: {
        td: 'white-space position display font-family font-size font mso-ignore mso-font-charset mso-number-format mso-background-source mso-rotate mso-pattern mso-protection',
      },
      toolbar,
      placeholder,
    };
  }, [field, hasMark, hasTerm, inline, placeholder]);

  const saveContent = (content: string, sanitize: boolean) => {
    if (!field || !skey) {
      return;
    }

    const patch = set({}, field, content);
    dispatch(patchNodeAction(skey, patch, true, null, sanitize));
  };

  const onBlur = () => {
    if (!editor || !editor.isDirty()) {
      return;
    }

    editor.setDirty(false);
    saveContent(editor.getContent(), true);
  };

  // disables following links when clicking in editor
  const onClick = (event: EditorEvent<MouseEvent>) => {
    const target = event.target as HTMLElement;
    if (target?.matches('a')) {
      event.preventDefault();
    }
  };

  const onContentChange = () => {
    if (editor) {
      setNumCharacters(countCharacters(editor.getContent()));
      if (onTextChange) {
        onTextChange(editor.getContent());
      }
    }
  };

  return (
    <div className={`inlineEdit ${readOnly ? 'readonly' : ''}`}>
      {readOnly && (
        <div className="plainTextEditor" id={skey}>
          <div dangerouslySetInnerHTML={{ __html: initial }} />
        </div>
      )}
      {plainText && !readOnly && (
        <div className="plainTextEditor" id={skey}>
          <textarea
            value={plainTextContent}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setPlainTextContent(e.target.value);
            }}
            onBlur={() => {
              saveContent(plainTextContent, false);
            }}
          />
        </div>
      )}
      {!plainText && !readOnly && (
        <ReactEditor
          scriptLoading={{ delay: 250 }}
          init={config}
          onInit={(evt, newEditor: Editor) => {
            console.log('tinymce init', skey);
            setEditor(newEditor);
          }}
          onBlur={onBlur}
          onClick={onClick}
          onKeyPress={onContentChange}
          onNodeChange={onContentChange}
          onPaste={(evt) => pasteHtml(evt, editor)}
          initialValue={initial}
        />
      )}
      {showCharacterCount && (
        <div className="characters-count">
          <label
            className={
              maxCharacters && numCharacters >= maxCharacters ? 'text-danger' : 'text-muted'
            }
            style={{ marginRight: 10 }}
          >
            Aantal karakters{maxCharacters && ` (max ${maxCharacters} )`}:{numCharacters}
          </label>
        </div>
      )}
    </div>
  );
};

AsideEditor.propTypes = {
  initial: string,
  field: string,
  skey: string,
  onTextChange: func,
  readOnly: bool,
  showCharacterCount: bool,
  plainText: bool,
  inline: bool,
};

export default AsideEditor;
