import { selectOdetLeergebiedNode } from '@newStore/documentUI/transformContent/odetContentNodeSelectors';
import { BuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { identifierComponent } from '@nodeTypeConfig/helpers/editComponents/identifierComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';

const CURRICULUM_ODET_LEERGEBIED: BuildingBlockNodeConfig = {
  nodeSelector: selectOdetLeergebiedNode,
  information: {
    single: 'Leergebied',
    plural: 'Leergebieden',
  },

  buildingBlocks: [
    { type: NodeType.CURRICULUM_ODET_SUBSECTION },
    { type: NodeType.CURRICULUM_ODET_ENDTERM },
  ],
  edit: [
    {
      ...identifierComponent,
      width: 2,
    },
    {
      ...titleComponent,
      width: 10,
    },
    {
      component: 'description',
      reactComponent: 'AsideDescription',
      asideChangeMessageSelector: selectChangeMessageForHtmlString,
      property: 'description',
      label: 'Omschrijving',
      view: {
        hideIfEmpty: true,
      },
    },
  ],
  isCollapsible: true,
  isCollapsedByDefault: true,
  isDeprecated: true,
  documentViewIdentifier: 'i',
  onNewNodeDropped: {
    focusOnField: 'title',
  },
};

export default CURRICULUM_ODET_LEERGEBIED;
