import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { EditAsideThemes } from '@nodeTypeConfig/configTypes';
import { contentTypesKey } from '@store/constants/constants';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { themeHrefsToString } from '../editComponentHelpers';

export const proContentTypeForDatabasePicker: EditAsideThemes = {
  component: 'themes',
  property: 'themes',
  reactComponent: 'AsideThemes',
  label: 'Databanktype',
  options: {
    referenceFrameHref: `/content/${contentTypesKey}` as ContentHref,
    types: [ContentType.THEME],
  },
  valueToString: themeHrefsToString,
  asideChangeMessageSelector: selectChangeMessageForThemes,
};
