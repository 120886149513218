import { RootEditComponent } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { dienstverleningKovKey } from '@store/constants/constants';
import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
// can also probably be merged with shared db item, probably again slopiness to only do this for db items and not for the other two
export const leerplanSitesThemeComponents: Array<RootEditComponent> = [
  {
    component: 'themes',
    property: 'themes',
    reactComponent: 'AsideThemes',
    label: "Leerplanthema's",
    options: {
      referenceFrameHref: `/content/${dienstverleningKovKey}` as ContentHref,
      types: [ContentType.THEME, ContentType.CURRICULUM_THEME],
      highlightOldCurrItems: true,
    },
    valueToString: themeHrefsToString,
    asideChangeMessageSelector: selectChangeMessageForThemes,
  },
  {
    component: 'curriculumSelector',
    reactComponent: 'AsideCurriculumSelector',
    property: 'themes',
    label: "Leerplanthema's",
  },
];
