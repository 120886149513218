import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { BuildingBlock, EditAsideImage, NodeType } from '@nodeTypeConfig/configTypes';
import { imageComponentBase } from '@nodeTypeConfig/helpers/editComponents/imageComponent';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';

export const wwwDefaultBuildingBlocks: Array<BuildingBlock> = [
  { type: NodeType.SECTION },
  { type: NodeType.PARAGRAPH },
  { type: NodeType.IMAGE },
  { type: NodeType.IMAGE_GROUP },
  { type: NodeType.ATTACHMENTS_GROUP },
  { type: NodeType.VIDEO },
  { type: NodeType.QUOTE },
  { type: NodeType.WORD_IMPORT },
];

export const wwwTitleAndDescriptionComps = [
  titleComponent,
  {
    component: 'description',
    property: 'description',
    reactComponent: 'AsideDescription',
    asideChangeMessageSelector: selectChangeMessageForHtmlString,
    label: 'Inleiding',
  },
] as const;

// Question @Greet/Isabelle WWW and WWW_SECTION has cropOptions, is there meant to be a difference or just sloppiness?
export const wwwOmslagAndTegelFotoComps: EditAsideImage[] = [
  {
    ...imageComponentBase,
    width: '6',
    label: 'Omslagfoto',
    options: {
      type: 'COVER_IMAGE',
      fields: [],
    },
  },
  {
    ...imageComponentBase,
    width: '6',
    label: 'Tegelfoto',
    options: {
      type: 'THUMBNAIL',
      fields: ['alt'],
    },
  },
];
