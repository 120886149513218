import { ContentHref, ContentType } from '@generalTypes/apiTypes';
import { ExtendedBuildingBlockNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { themeHrefsToString } from '@nodeTypeConfig/helpers/editComponentHelpers';
import { dienstverleningKovKey } from '@store/constants/constants';
import { selectTitleNode } from '@newStore/documentUI/transformContent/genericSelectors';
import { selectCurriculumThemesValidation } from '@newStore/validation/validationRules/curriculumThemesConsistencyValidation';
import { selectChangeMessageForThemes } from '@UI/aside/asideThemes/AsideThemesSelectors';
import { titleComponent } from '@nodeTypeConfig/helpers/editComponents/titleComponent';
import { extendsSection } from '../../helpers/genericConfigHelpers';

// Extends SECTION => A SECTION inside a PRONEWSLETTER or a PRONEWSLETTERTEMPLATE becomes a PRO_NEWSLETTER_SECTION
const PRO_NEWSLETTER_SECTION: ExtendedBuildingBlockNodeConfig = {
  nodeSelector: selectTitleNode,
  validationRules: [selectCurriculumThemesValidation],
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../../img/buildingBlocks/SECTION.svg'),
  },
  ...extendsSection,
  isCollapsedByDefault: false,
  buildingBlocks: [
    { type: NodeType.PARAGRAPH },
    { type: NodeType.IMAGE },
    { type: NodeType.REFERENCE_GROUP },
    { type: NodeType.TEASER, showInAncestorTypes: [NodeType.PRONEWSLETTER] },
    { type: NodeType.WORD_IMPORT },
  ], // no SECTION in PRONEWSLETTER SECTION you can only go one level deep
  edit: [
    titleComponent,
    {
      component: 'themes',
      property: 'themes',
      reactComponent: 'AsideThemes',
      label: "Thema's",
      options: {
        referenceFrameHref: `/content/${dienstverleningKovKey}` as ContentHref,
        types: [ContentType.THEME, ContentType.CURRICULUM_THEME, ContentType.CURRICULUM_CLUSTER],
        highlightOldCurrItems: true,
      },
      valueToString: themeHrefsToString,
      asideChangeMessageSelector: selectChangeMessageForThemes,
    },
    {
      component: 'curriculumSelector',
      reactComponent: 'AsideCurriculumSelector',
      property: 'themes',
      label: "Leerplanthema's",
    },
  ],
};
export default PRO_NEWSLETTER_SECTION;
