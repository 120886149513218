import { selectChangeMessageForHtmlString } from '@newStore/documentUI/changeMessageSelectors/selectChangeMessageForHtmlString';
import { EditAsideIdentifier } from '@nodeTypeConfig/configTypes';

export const identifierComponent: EditAsideIdentifier = {
  component: 'identifier',
  property: 'identifiers',
  label: 'Prefix',
  reactComponent: 'AsideIdentifier',
  asideChangeMessageSelector: selectChangeMessageForHtmlString,
};
