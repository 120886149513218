import { IApiResouce } from '@generalTypes/sriTypes';
import {
  AsyncKovResourcePicker,
  useLoadSriOptions,
} from '@kathondvla/react-shared-components/src/components';
import { EditAsideContactsOrCreators } from '@nodeTypeConfig/configTypes';
import { patchNodeAction } from '@store/actions/documentActions';
import { searchApi } from '@store/api/apiConfig';
import { getResourceKey } from '@store/helpers/documentHelpers';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEditHref } from '../../hooks/UseEditHref';
import { useInitialValue } from '../../hooks/UseInitialValue';
import { useReadOnly } from '../../hooks/UseReadonly';
import { PersonOption } from './PersonOption';

const PersonPicker: React.FC<{
  config: EditAsideContactsOrCreators;
}> = ({ config }) => {
  const sriLoadOptionsFn = useLoadSriOptions({
    sriClient: searchApi,
    href: '/search',
    parameters: config.options.queryParams,
  });

  const [defaultContactsLoaded, setDefaultContactsLoaded] = useState(false);
  const [missingContacts, setMissingContacts] = useState<Set<string>>(new Set());

  const dispatch = useDispatch();

  const currentValue: string[] | null = useInitialValue(config.property);

  const [currentValueResults, setCurrentValueResults] = useState<IApiResouce[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function loadContacts(value: string[]) {
      try {
        const hrefsSet = new Set(value);

        const apiSearch = await searchApi.getRaw('/search', {
          ...config.options.queryParams,
          hrefs: Array.from(hrefsSet).join(','),
        });

        let missingApiSearch: any = null;

        if (apiSearch.results.length !== hrefsSet.size) {
          // sometimes the queryParams of the options filter out some of the already selected contacts.
          // in that case we need to do a new search with the missing hrefs, and label them as mismatched in the UI
          const missingHrefs = Array.from(hrefsSet).filter(
            (href) => !apiSearch.results.some((result) => result.$$meta.permalink === href)
          );

          missingApiSearch = await searchApi.getRaw('/search', {
            expand: 'FULL',
            types: 'person,organisational_unit',
            limit: 30,
            highlight: 'false',
            hrefs: missingHrefs.join(','),
          });

          setMissingContacts(new Set(missingHrefs));
        }

        if (isMounted) {
          if (missingApiSearch) {
            setCurrentValueResults([...apiSearch.results, ...missingApiSearch.results]);
          } else {
            setCurrentValueResults(apiSearch.results);
          }
          setDefaultContactsLoaded(true);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error);
        }
      }
    }
    if (currentValue && currentValue.length > 0 && !defaultContactsLoaded) {
      loadContacts(currentValue);
    } else if (!currentValue || currentValue.length === 0) {
      setDefaultContactsLoaded(true);
    }

    return () => {
      isMounted = false;
    };
  }, [config.options.queryParams, currentValue, defaultContactsLoaded]);

  const editHref = useEditHref();

  const readOnly = useReadOnly();

  const change = (contactList: Array<IApiResouce>) => {
    dispatch(
      patchNodeAction(
        getResourceKey(editHref),
        set(
          {},
          config.property,
          contactList.map((c) => c.$$meta.permalink)
        )
      )
    );
  };

  return !defaultContactsLoaded ? (
    <div className="form-group shimmer narrowLine"></div>
  ) : (
    <AsyncKovResourcePicker
      defaultValue={currentValueResults}
      onChange={change}
      loadOptions={sriLoadOptionsFn}
      disabled={readOnly}
      optionValue={(option) => option.key}
      placeholder={`Zoek ${config.label.toLowerCase()}`}
      clearable={false}
      multiple={config.options?.multiple || true}
      optionTemplate={(option) => (
        <PersonOption option={option} isMismatch={missingContacts.has(option.$$meta.permalink)} />
      )}
    />
  );
};

export default PersonPicker;
