import { isEqual } from 'lodash';
import { visualDomDiff } from 'visual-dom-diff';

const diffHtmlContent = (oldHtml: string, newHtml: string): string => {
  const oldDiv = document.createElement('div');
  oldDiv.innerHTML = oldHtml;

  const newDiv = document.createElement('div');
  newDiv.innerHTML = newHtml;

  const htmldiff = visualDomDiff(oldDiv, newDiv, {
    addedClass: 'diff-insert',
    removedClass: 'diff-delete',
    skipModified: true,
  });

  return (htmldiff?.firstChild as Element)?.innerHTML;
};

export const htmlDiffFunction = (newValue: string, oldValue: string): string => {
  return diffHtmlContent(oldValue, newValue);
};

/**
 * This function puts the old and new value side by side.
 * it puts an arrow in between if both values are different and defined.
 * @param newValue
 * @param oldValue
 * @returns
 */
export const stringDiffArrowFunction = (
  newValue: string | null,
  oldValue: string | null
): string | null => {
  if (isEqual(newValue, oldValue)) {
    return null;
  }

  if (!oldValue && !newValue) {
    return null;
  }

  if (!oldValue) {
    return `<ins>${newValue}</ins>`;
  }

  if (!newValue) {
    return `<del>${oldValue}</del>`;
  }

  return `<del>${oldValue}</del> 🡒 <ins>${newValue}</ins>`;
};

/**
 *
 * @param oldValues the array item strings before the change
 * @param newValues the array item strings after the change
 * @param options (optional) the options to compare against. This need to be provided when the order of the options is important.
 * @returns the difftext or null if there is no difference
 */

export const getDiffForArraysOfStrings = (
  oldValues: string[] | undefined,
  newValues: string[] | undefined,
  options?: string[]
): string | null => {
  const beforeSet = new Set(oldValues);
  const afterSet = new Set(newValues);
  const allItems = options || [...new Set([...beforeSet, ...afterSet])];

  const changes: string[] = [];

  allItems.forEach((item) => {
    const newHasOption = afterSet.has(item);
    const oldHasOption = beforeSet.has(item);

    if (newHasOption && !oldHasOption) {
      changes.push(`<ins>${item}</ins>`);
    } else if (!newHasOption && oldHasOption) {
      changes.push(`<del>${item}</del>`);
    }
  });

  if (changes.length === 0) {
    return null;
  }

  const diffHtml = `<ul>${changes.map((change) => `<li>${change}</li>`).join('')}</ul>`;
  return diffHtml;
};
